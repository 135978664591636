import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import imagePlaceholder from '../../assets/images/image-placeholder.svg';
import { API } from '../../api';
import Cookies from 'js-cookie';

const TeamMember = () => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [uploadedImage, setUploadedImage] = useState();
    const params = useParams();
    const [searchParams] = useSearchParams();
    let mode = searchParams.get('mode');
    const navigate = useNavigate();

    const saveChangesHandler = async () => {
        const formData = new FormData();
        formData.append('name', firstName + ' ' + lastName);
        formData.append('email', user.attributes.email);
        // formData.append('role', 'editor');
        if (uploadedImage) {
            formData.append('image', uploadedImage);
        }

        const { data } = await API.post(`users/${params.id}`, formData, {
            headers: {
                Authorization: `Bearer ${Cookies.get("token")}`,
            },
        });
        // console.log(data);
        navigate(`/teams/${params.id}`);
    };

    const deleteUserHandler = async (id) => {
        const { data } = await API.delete(`users/${params.id}`, {
            headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        });
        // console.log(data);
        navigate(`/teams`);
    };

    useEffect(() => {
        const getUser = async () => {
            setLoading(true);
            const { data } = await API.get(`users/${params.id}`, {
                headers: { Authorization: `Bearer ${Cookies.get("token")}` },
            });
            // console.log(data.data);
            setUser(data.data);
            setFirstName(data.data.attributes.name.split(' ')[0])
            setLastName(data.data.attributes.name.split(' ')[1])
            setLoading(false);
        };
        getUser();
    }, []);
    return (
        <section>
            {!loading && (
                <>
                    <div className='border border-[#B70B28] bg-white py-6 px-4 rounded-[5px] mb-4'>
                        <h2 className='text-[#8B6464] font-medium mb-5'>Profile Details</h2>

                        <div className='border-b flex flex-col items-center w-full'>
                            <div className='w-full flex flex-col justify-center items-center sm:flex-row sm:justify-start sm:items-center gap-4 max-sm:mb-2'>
                                <img
                                    src={
                                        uploadedImage
                                            ? URL.createObjectURL(uploadedImage)
                                            : user.attributes.image != null ?
                                                user.attributes.image : imagePlaceholder
                                    }
                                    alt='avatar'
                                    className='w-[140px] max-w-full'
                                />

                                <div>
                                    <label
                                        htmlFor='upload-user-img'
                                        className='block w-fit bg-[#B70B28] text-white font-medium text-[18px] p-2 px-7 rounded-[50px] cursor-pointer'
                                    >
                                        Change Photo
                                    </label>
                                    <input
                                        type='file'
                                        id='upload-user-img'
                                        className='hidden'
                                        disabled={mode === 'edit' ? false : true}
                                        onChange={(e) => setUploadedImage(e.target.files[0])}
                                    />
                                </div>
                            </div>
                        </div>

                        <form className='my-10'>
                            <div className='flex flex-col sm:flex-row gap-6 mb-4 max-w-full'>
                                <div className='flex flex-col flex-1'>
                                    <label className='text-[#8B6464] text-sm'>First Name</label>
                                    <input
                                        type='text'
                                        placeholder='Toar'
                                        value={firstName}
                                        onChange={(e) =>
                                            setFirstName(e.target.value)
                                        }
                                        disabled={mode === 'edit' ? false : true}
                                        className='w-full border border-[#F0E2E2] rounded-md py-[7px] px-[14px]'
                                    />
                                </div>
                                <div className='flex flex-col flex-1'>
                                    <label className='text-[#8B6464] text-sm'>Last Name</label>
                                    <input
                                        type='text'
                                        value={lastName}
                                        onChange={(e) =>
                                            setLastName(e.target.value)
                                        }
                                        disabled={mode === 'edit' ? false : true}
                                        className='w-full border border-[#F0E2E2] rounded-md py-[7px] px-[14px]'
                                    />
                                </div>
                            </div>
                            <div className='w-full'>
                                <div className='flex flex-col'>
                                    <label className='text-[#8B6464] text-sm'>Email</label>
                                    <input
                                        type='email'
                                        placeholder='example@ex.ex'
                                        value={user.attributes.email}
                                        disabled
                                        readOnly
                                        className='border border-[#F0E2E2]  rounded-md py-[7px] px-[14px]'
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className='flex flex-col items-end  max-sm:items-center w-full py-4'>
                        <div className='w-1/4 flex justify-end gap-2 max-sm:flex-col max-sm:items-center max-sm:justify-center'>
                            <button
                                className='text-[#B70B28] bg-[#FFE0D0] py-4 px-8 rounded-lg flex-1'
                                onClick={() => {
                                    if (mode === 'edit') {
                                        navigate(`/teams/${params.id}`);
                                    } else {
                                        deleteUserHandler(params.id)
                                        navigate('/teams');
                                    }
                                }}
                            >
                                {mode === 'edit' ? 'Dismiss' : 'Delete'}
                            </button>
                            <button
                                className='text-white bg-[#FF1716] py-4 px-8 rounded-lg flex-[3]'
                                onClick={() => {
                                    if (mode === 'edit') {
                                        saveChangesHandler();
                                    } else {
                                        navigate('?mode=edit');
                                    }
                                }}
                            >
                                {mode === 'edit' ? 'Save Changes' : 'Edit'}
                            </button>
                        </div>
                    </div>
                </>
            )}
        </section>
    );
};

export default TeamMember;
