import React, { useState, useEffect } from "react";
import imagePlaceholder from "../../assets/images/image-placeholder.png";
import upload from "../../assets/icons/upload.svg";
import arrow from "../../assets/icons/drop-arrow.svg";
import xIcon from "../../assets/icons/x.svg";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { API } from "../../api";
import Cookies from "js-cookie";

const SingleBlog = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [image, setImage] = useState();
  const [uploadedIamge, setUploadedImage] = useState();
  const [categories, setCategories] = useState();
  const [showCategories, setShowCategories] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [searchParams] = useSearchParams();
  const params = useParams();
  let mode = searchParams.get("mode");
  const toggleShowCategoriesHandler = () => {
    setShowCategories((prev) => !prev);
  };

  const addCategoryHandler = (cat) => {
    let index = selectedCategories?.findIndex(
      (category) => category.id === cat.id
    );
    if (index >= 0) {
      return;
    } else {
      setSelectedCategories((prev) => [cat, ...prev]);
    }
  };

  const deleteCategoryHandler = (cat) => {
    let updatedSelectedCategories = selectedCategories.filter(
      (category) => category.id !== cat.id
    );
    setSelectedCategories(updatedSelectedCategories);
  };

  const updateBlogHandler = async () => {
    const blogData = {
      "title": title,
      "content": description,
      "description": description,
      "keywords": title,
    }
    const { data } = await API.put(`posts/${params.id}`, blogData, {
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });
    console.log(data);

    const categoriesData = new FormData();
    selectedCategories.forEach((cat, index) =>
      categoriesData.append(`category_ids[${index}]`, parseInt(cat.id))
    );
    const { data: CategoriesReq } = await API.post(`posts/${params.id}/categories`, categoriesData, {
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });
    console.log(CategoriesReq);

    if (uploadedIamge) {
      const imageData = new FormData();
      imageData.append("image", uploadedIamge);
      const { data: imageReq } = await API.post(`posts/${params.id}/image`, imageData, {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });
      console.log(imageReq);
    }
  };
  const deleteBlogHandler = async () => {
    const { data } = await API.delete(`posts/${params.id}`, {
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });
    console.log(data);
  };

  useEffect(() => {
    const getCategories = async () => {
      const { data } = await API.get("categories");
      setCategories(data.data);
    };
    const getBlog = async () => {
      const { data } = await API.get(`posts/${params.id}`);
      console.log(data.data);
      setTitle(data.data.attributes.title);
      setDescription(data.data.attributes.content);
      setImage(data.data.attributes.image);
      setSelectedCategories(data.data.categories);
    };
    getCategories();
    getBlog();
  }, []);
  return (
    <div className="flex flex-col items-center w-full">
      <section className="max-sm:w-[260px] w-full">
        <div className="px-4 flex max-sm:flex-col justify-center gap-6">
          <div
            className={`blog-image-shadow rounded-xl max-sm:w-[228px] flex flex-col items-center justify-center ${image ? "pb-6" : "p-6"
              }`}
          >
            <img
              src={
                uploadedIamge
                  ? URL.createObjectURL(uploadedIamge)
                  : image
                    ? image
                    : imagePlaceholder
              }
              alt="avatar"
              className={`${image || uploadedIamge
                ? "h-full rounded-t-xl max-w-[228px]"
                : "h-[140px] w-[140px]"
                }`}
            />
            <label htmlFor="image" className="flex flex-col items-center ">
              {!image && !uploadedIamge && (
                <p className="sm:text-2xl font-bold mt-4 cursor-pointer">
                  Upload image here
                </p>
              )}
              <img src={upload} alt="upload" className="cursor-pointer mt-4" />
            </label>
            <input
              type="file"
              name="image"
              id="image"
              hidden
              disabled={mode === "edit" ? false : true}
              onChange={(e) => setUploadedImage(e.target.files[0])}
            />
          </div>
          <div className="rounded-xl blog-shadow p-6 flex-1">
            <h3 className="text-[#8B6464] text-[18px] font-semibold mb-6">
              Add Blog
            </h3>
            <form className="flex flex-col gap-[19px]">
              <div className="flex flex-col">
                <label className="text-[13px] text-[#8B6464] mb-1">
                  Blog name
                </label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  disabled={mode === "edit" ? false : true}
                  className="border border-[#F0E2E2] rounded-md py-2 px-4 text-[20px]"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-[13px] text-[#8B6464] mb-1">
                  Description
                </label>
                <textarea
                  type="text"
                  rows={3}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  disabled={mode === "edit" ? false : true}
                  className="border border-[#F0E2E2] rounded-md py-2 px-4 text-[14px] opacity-70 resize-none"
                />
              </div>
              <div className="flex max-sm:flex-col gap-2">
                <div className="flex flex-col ">
                  <p className="text-[13px] text-[#8B6464] mb-1">Categories</p>
                  <div className="flex flex-col gap-2 rounded-md border border-[#F0E2E2] p-4 h-[160px] w-[200px] max-sm:w-full">
                    <div className="flex justify-between ">
                      <p>Select</p>
                      <img
                        src={arrow}
                        alt="arrow"
                        className={`cursor-pointer ${showCategories ? "" : "rotate-180"
                          }`}
                        onClick={toggleShowCategoriesHandler}
                      />
                    </div>
                    {showCategories && (
                      <div className="flex flex-col gap-2 items-start h-[96px] overflow-y-auto">
                        {categories?.map((category) => (
                          <button
                            key={category.id}
                            type="button"
                            disabled={mode === "edit" ? false : true}
                            onClick={() => {
                              addCategoryHandler(category);
                            }}
                            className="flex items-center gap-1"
                          >
                            <p className="font-bold text-xl">+</p>
                            <p>{category.attributes.title}</p>
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-1">
                  <p className="text-[13px] text-[#8B6464] mb-1">Box</p>
                  <div className="border border-[#F0E2E2] rounded-md flex flex-wrap gap-2 p-4 h-[160px] overflow-y-auto">
                    {selectedCategories?.map((category) => (
                      <div
                        key={category.id}
                        className="flex items-center justify-center gap-[10px] w-fit h-fit category-shadow rounded-[33px] py-1 px-2"
                      >
                        <p className="text-md">{category.attributes.title}</p>
                        <button
                          type="button"
                          disabled={mode === "edit" ? false : true}
                          onClick={() => {
                            deleteCategoryHandler(category);
                          }}
                        >
                          <img src={xIcon} alt="delete" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </form>
            <div className="flex max-sm:flex-col gap-2 mt-4">
              <button
                onClick={() => {
                  if (mode === "edit") {
                    navigate(`/blogs/${params.id}`, { replace: true });
                  } else {
                    deleteBlogHandler();
                  }
                }}
                className="text-[#FF1716] bg-[#FFE0D0] py-4 px-8 rounded-lg"
              >
                {mode === "edit" ? "Cancel" : "Delete"}
              </button>
              <button
                onClick={() => {
                  if (mode === "edit") {
                    updateBlogHandler();
                  } else {
                    navigate("?mode=edit", { replace: true });
                  }
                }}
                className="text-white bg-[#FF1716] py-4 px-8 rounded-lg flex-1"
              >
                {mode === "edit" ? "Save changes" : "Edit"}
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SingleBlog;
