import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { useEffect, useState } from 'react'
import { API } from '../../../../api'
import { useLanguage } from '../../../../translations/LanguageContext'

export default function Categories() {
    const { language } = useLanguage()

    const [categories, setCategories] = useState([])
    const [todayUpdates, setTodayUpdates] = useState()
    useEffect(() => {
        const getCategories = async () => {
            const { data } = await API.get('categories')
            // console.log(data.data);
            setCategories(data.data)
        }
        const getTodayUpdates = async () => {
            const { data } = await API.get('today/updates')
            // console.log(data);
            setTodayUpdates(data)
        }
        getCategories()
        getTodayUpdates()
    }, [])
    return (
        <div className='sm:w-[300px] w-full'>


            <h1 className='text-[20px] font-medium mb-10'>

                <span className='p-1 text-[18px] rounded-[5px] bg-[#ff6050] text-white me-1'>{language === 'ar' ? 'أفضل' : 'Top'}</span> {language === 'ar' ? "الناشرين" : "Authors"}

            </h1>

            <div>

                {
                    [1, 2, 3].map((item, index) => {
                        return <div key={index} className='flex items-center mb-10'>

                            <img src={require('./nabowy.jpg')} className='w-[80px] h-[80px] rounded-full object-fill me-5' alt='nabowy' />

                            <div>
                                <h3 className='text-[14px] mb-2 font-medium'>Jenar Kia</h3>

                                <p className='text-[12px] font-medium text-[#666666] mb-2'>Fashion designer, Blogger, activist</p>

                                <div className='flex items-center'>

                                    <div className='border rounded-[5px] w-[20px] h-[20px] me-4 flex items-center justify-center bg-[#FF6050]'>

                                        <FontAwesomeIcon icon={faFacebookF} className='text-[white] text-[13px] cursor-pointer' />

                                    </div>
                                    <div className='border rounded-[5px] w-[20px] h-[20px] me-4 flex items-center justify-center'>

                                        <FontAwesomeIcon icon={faTwitter} className='text-[#666666] text-[13px] cursor-pointer' />

                                    </div>
                                    <div className='border rounded-[5px] w-[20px] h-[20px] me-4 flex items-center justify-center'>

                                        <FontAwesomeIcon icon={faInstagram} className='text-[#666666] text-[13px] cursor-pointer' />

                                    </div>

                                </div>

                            </div>

                        </div>
                    })
                }

            </div>

            <div className='my-16'>

                <h1 className='text-[20px] bg-[#ff6050] text-white  font-medium mb-10 w-fit p-1 rounded-[5px]'>
                    {language === 'ar' ? 'الأصناف' : 'Categories'}
                </h1>

                {
                    categories.map((category) => {
                        return <div key={category} className='flex items-center justify-between mb-2 p-2' style={{ borderBottom: "1px dashed #D1E7E5" }}>

                            <h3 className='font-medium text-[15px]'>{category.attributes.title}</h3>
                            <h3 className='font-medium text-[15px]'>{category.attributes['posts_count']}</h3>

                        </div>
                    })
                }

            </div>


            <div className='my-16'>

                <h1 className='text-[20px] font-medium mb-10'>

                    <span className='p-1 text-[18px] rounded-[5px] bg-[#ff6050] text-white me-1'>{language === 'ar' ? 'تحديثات' : "Today's"}</span> {language === 'ar' ? 'اليوم' : "Update"}

                </h1>

                <div className='flex items-center justify-between flex-wrap'>
                    {todayUpdates && <>
                        <div className='w-[48%] h-[111px] bg-[#F2F8F7] mb-5 rounded-[10px] flex flex-col items-center justify-center py-5'>
                            <h1 className='font-medium text-[#FF6050] text-[20px] mb-1'>{todayUpdates['new_posts']}</h1>
                            <h6 className='font-meduim text-[12px]'>{language === 'ar' ? "منشورات جديدة" : "New Posts"}</h6>
                        </div>
                        <div className='w-[48%] h-[111px] bg-[#F2F8F7] mb-5 rounded-[10px] flex flex-col items-center justify-center py-5'>
                            <h1 className='font-medium text-[#FF6050] text-[20px] mb-1'>{todayUpdates['visistors_count']}</h1>
                            <h6 className='font-meduim text-[12px]'>{language === 'ar' ? "اجمالي الزوار" : "Total Visitors"}</h6>
                        </div>
                        <div className='w-[48%] h-[111px] bg-[#F2F8F7] mb-5 rounded-[10px] flex flex-col items-center justify-center py-5'>
                            <h1 className='font-medium text-[#FF6050] text-[20px] mb-1'>{todayUpdates['new_subscribers_count']}</h1>
                            <h6 className='font-meduim text-[12px]'>{language === 'ar' ? "مشتركين جدد" : "New subscribers"}</h6>
                        </div>
                        <div className='w-[48%] h-[111px] bg-[#F2F8F7] mb-5 rounded-[10px] flex flex-col items-center justify-center py-5'>
                            <h1 className='font-medium text-[#FF6050] text-[20px] mb-1'>{todayUpdates['blog_views']}</h1>
                            <h6 className='font-meduim text-[12px]'>{language === 'ar' ? "قراءة المنشورات" : "Blog Read"}</h6>
                        </div>
                    </>}


                </div>

            </div>

            <div className='my-16'>

                <h1 className='text-[20px] font-medium mb-10'>

                    <span className='p-1 text-[18px] rounded-[5px] bg-[#ff6050] text-white me-1'>{language === 'ar' ? "منشورات" : "Instagram"}</span> {language === 'ar' ? "انستجرام" : "Posts"}

                </h1>

                <div className='grid grid-cols-12 gap-5'>

                    {
                        [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => {
                            return <div key={index} className='col-span-4 relative bg-red-50'>
                                <img src={require('./post.png')} alt='' className='w-full h-[114px] object-fill' />
                            </div>
                        })
                    }

                </div>

            </div>

            <div className='my-16'>

                <h1 className='text-[20px] font-medium mb-10'>

                    <span className='p-1 text-[18px] rounded-[5px] bg-[#ff6050] text-white me-1'>{language === 'ar' ? "بحث" : "Search"}</span> {language === 'ar' ? "بأصناف" : "With Tags"}

                </h1>

                <div className='flex items-center justify-between flex-wrap'>

                    {
                        categories.map((category) => {
                            return <p key={category.id} className='py-2 px-5 rounded-[5px] capitalize flex-1 text-center me-3 mb-4 border'>{category.attributes.title}</p>
                        })
                    }

                </div>


            </div>

        </div>

    )
}
