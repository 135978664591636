const { SwiperSlide } = require("swiper/react");

const items = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  31,
  32,
  3123,
  ,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  31,
  32,
  3123,
].map((item, index) => {
  return (
    <SwiperSlide key={index} className="!me-2">
      <div className="w-[128px] h-[128px] rounded-full flex items-center justify-center relative">
        <img
          src={require("./girl.png")}
          alt=""
          className="absolute left-0 top-0 w-full h-full rounded-full object-fill"
        />

        <p className="bg-white px-2 font-bold rounded-full text-[#DB1020] text-[12px] relative z-10">
          Value
        </p>
      </div>
    </SwiperSlide>
  );
});

const details = [
  { name: "RC:", value: "125" },
  { name: "AH:", value: "800" },
  { name: "PD:", value: "34" },
  { name: "W/D:", value: "DRY" },
  { name: "V:", value: "12" },
  { name: "TC:", value: "TOP" },
  { name: "EN:", value: "800" },
  { name: "W*h * W* L:", value: "6.6 * 7 13/16 * 6 3/4 * 10 13/16" },
  { name: "CCA:", value: "800" },
];

const renderDetails = details.map((item, index) => {
  return (
    <span className="me-2">
      <span className="text-[#130707]  font-medium">{item.name}</span>
      <span className="text-[#8B6464]  font-medium">{item.value}</span> |
    </span>
  );
});

export { items, renderDetails };
