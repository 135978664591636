import React, { useEffect, useState } from "react";
import { API } from "../../api";
import imagePlaceholder from "../../assets/images/image-placeholder.png";
import upload from "../../assets/icons/upload.svg";
import { RadioButton } from "primereact/radiobutton";
import ListBox from "./ListBox";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../translations/LanguageContext";

const AddProduct = () => {
  const { language } = useLanguage();
  const navigate = useNavigate();

  const [image, setImage] = useState();
  const [selectedVechileType, setSelectedVechileType] = useState("passenger");
  const [brands, setBrands] = useState();
  const [selectedBrand, setSelectedBrand] = useState();

  const [models, setModels] = useState();
  const [selectedModel, setSelectedModel] = useState();

  const [carStyles, setCarStyles] = useState();
  const [selectedStyle, setSelectedStyle] = useState();

  const [productData, setProductData] = useState({
    name: "",
    description: "",
    rc: "",
    ah: "",
    cca: "",
    physical_dimension: "",
    wet_dry: "",
    voltage: "",
    terminal_code: "",
    en: "",
    price: "",
    dimensions: "",
    compaar: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const addProduct = async () => {
    const formData = new FormData();
    Object.entries(productData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("image", image);
    formData.append("type", selectedVechileType);
    if (selectedBrand) {
      formData.append("brand_id", selectedBrand.id);
      formData.append("brand_name", selectedBrand.attributes.name);
      formData.append("brand_image", selectedBrand.attributes.image);
    }
    if (selectedBrand) {
      formData.append("car_type_id", selectedStyle.id);
      formData.append("car_type_name", selectedStyle.attributes.name);
      formData.append("car_type_image", selectedStyle.attributes.image);
    }
    if (selectedModel) {
      formData.append("car_id", selectedModel.id);
      formData.append("car_name", selectedModel.attributes.name);
      formData.append("car_image", selectedModel.attributes.image);
    }
    const { data } = await API.post("products", formData, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    console.log(data);
    navigate("/products");
  };
  useEffect(() => {
    const getBrands = async () => {
      const { data } = await API.get("products/fetch/brands");
      setBrands(data.data);
    };
    const getCarStyles = async () => {
      const { data } = await API.get("products/fetch/car-types");
      setCarStyles(data.data);
    };
    const getModels = async () => {
      const { data } = await API.post("products/data/filter", {
        vehicle_type: selectedVechileType,
        brand_id: selectedBrand.id,
      });
      setModels(data.data);
      console.log(data);
    };
    if (!brands) {
      getBrands();
    }
    if (!carStyles) {
      getCarStyles();
    }
    if (selectedBrand) {
      getModels();
    }
    // getModels()
  }, [selectedBrand]);
  return (
    <div className="flex flex-col items-center w-full">
      <section className="max-sm:w-[260px] px-8 pb-[50px]">
        <div className="px-4 flex max-md:flex-col max-md:items-center justify-center gap-6">
          <div
            className={`border border-[#FF0303] rounded-xl max-sm:w-[228px] w-[326px] max-md:w-full flex flex-col items-center justify-center ${image ? "pb-6" : "p-6"
              }`}
          >
            <img
              src={image ? URL.createObjectURL(image) : imagePlaceholder}
              alt="avatar"
              className={`${image
                  ? "h-full rounded-t-xl max-w-[326px] max-md:w-full max-md:max-w-full"
                  : "h-[140px] w-[140px]"
                }`}
            />
            <label htmlFor="image" className="flex flex-col items-center ">
              <img src={upload} alt="upload" className="cursor-pointer mt-4" />
            </label>
            <input
              type="file"
              name="image"
              id="image"
              hidden
              onChange={(e) => setImage(e.target.files[0])}
            />
          </div>
          <form className="flex-1 max-sm:max-w-[228px]">
            <div className="border border-[#FF0303] mb-[25px] rounded-xl p-5">
              <h2 className="text-[#8B6464] text-[18px] font-semibold mb-[17px]">
                {language === "ar"
                  ? "عرض تفاصيل البطارية"
                  : "View Battery Details"}
              </h2>
              <div className="flex flex-col gap-[20px]">
                <div>
                  <p className="text-[#8B6464] text-[13px]">
                    {language === "ar" ? "الاسم" : "Name"}
                  </p>
                  <input
                    type="text"
                    name="name"
                    onChange={handleInputChange}
                    className="text-[#130707] text-[20px] font-bold border border-[#F0E2E2] rounded-[5px] mb-[20px] py-[6px] px-[12px] w-full"
                  />
                </div>
                <div>
                  <p className="text-[#8B6464] text-[13px]">
                    {language === "ar" ? "الوصف" : "Description"}
                  </p>
                  <textarea
                    rows={3}
                    name="description"
                    onChange={handleInputChange}
                    className="resize-none text-[#130707] opacity-60 border border-[#F0E2E2] rounded-[5px] py-[6px] px-[12px] w-full"
                  />
                </div>
                <div className="flex flex-col gap-[20px]">
                  <div className="flex gap-4">
                    <div className="flex flex-1 gap-4 max-sm:flex-col">
                      <div className="flex-1">
                        <p className="text-[#8B6464] text-[13px]">
                          {language === "ar"
                            ? "القدرة الاحتياطية (RC)"
                            : "Reserve Capacity ( RC )"}
                        </p>
                        <input
                          type="text"
                          name="rc"
                          onChange={handleInputChange}
                          className="text-[#130707] text-[12px] border border-[#F0E2E2] rounded-[5px] mb-[20px] py-[6px] px-[12px] w-full"
                        />
                      </div>
                      <div className="flex-1">
                        <p className="text-[#8B6464] text-[13px]">
                          {language === "ar"
                            ? "ساعات أمبير ( AH )"
                            : "Ampere Hours ( AH )"}
                        </p>
                        <input
                          type="text"
                          name="ah"
                          onChange={handleInputChange}
                          className="text-[#130707] text-[12px] border border-[#F0E2E2] rounded-[5px] mb-[20px] py-[6px] px-[12px] w-full"
                        />
                      </div>
                    </div>
                    <div className="flex flex-1 gap-4 max-sm:flex-col">
                      <div className="flex-1">
                        <p className="text-[#8B6464] text-[13px]">
                          {language === "ar"
                            ? "أمبير التدوير البارد (CCA)"
                            : "Cold Cranking Amps ( CCA )"}
                        </p>
                        <input
                          type="text"
                          name="cca"
                          onChange={handleInputChange}
                          className="text-[#130707] text-[12px] border border-[#F0E2E2] rounded-[5px] mb-[20px] py-[6px] px-[12px] w-full"
                        />
                      </div>
                      <div className="flex-1">
                        <p className="text-[#8B6464] text-[13px]">
                          {language === "ar"
                            ? "البعد المادي"
                            : "Physical Dimension"}
                        </p>
                        <input
                          type="text"
                          name="physical_dimension"
                          onChange={handleInputChange}
                          className="text-[#130707] text-[12px] border border-[#F0E2E2] rounded-[5px] mb-[20px] py-[6px] px-[12px] w-full"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="flex flex-1 gap-4 max-sm:flex-col">
                      <div className="flex-1">
                        <p className="text-[#8B6464] text-[13px]">
                          {language === "ar" ? "رطب/جاف" : "Wet/Dry"}
                        </p>
                        <input
                          type="text"
                          name="wet_dry"
                          onChange={handleInputChange}
                          className="text-[#130707] text-[12px] border border-[#F0E2E2] rounded-[5px] mb-[20px] py-[6px] px-[12px] w-full"
                        />
                      </div>
                      <div className="flex-1">
                        <p className="text-[#8B6464] text-[13px]">
                          {" "}
                          {language === "ar" ? "الجهد االكهربى" : "Voltage"}
                        </p>
                        <input
                          type="text"
                          name="voltage"
                          onChange={handleInputChange}
                          className="text-[#130707] text-[12px] border border-[#F0E2E2] rounded-[5px] mb-[20px] py-[6px] px-[12px] w-full"
                        />
                      </div>
                    </div>
                    <div className="flex flex-1 gap-4 max-sm:flex-col">
                      <div className="flex-1">
                        <p className="text-[#8B6464] text-[13px]">
                          {language === "ar" ? "الرمز الطرفي" : "Terminal Code"}
                        </p>
                        <input
                          type="text"
                          name="terminal_code"
                          onChange={handleInputChange}
                          className="text-[#130707] text-[12px] border border-[#F0E2E2] rounded-[5px] mb-[20px] py-[6px] px-[12px] w-full"
                        />
                      </div>
                      <div className="flex-1">
                        <p className="text-[#8B6464] text-[13px]">
                          {language === "ar"
                            ? "القاعدة الأوروبية ( EN )"
                            : "European Norm ( EN )"}
                        </p>
                        <input
                          type="text"
                          name="en"
                          onChange={handleInputChange}
                          className="text-[#130707] text-[12px] border border-[#F0E2E2] rounded-[5px] mb-[20px] py-[6px] px-[12px] w-full"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-4 max-sm:flex-col">
                    <div className="flex-1">
                      <p className="text-[#8B6464] text-[13px]">
                        {language === "ar" ? "السعر" : "Price"}
                      </p>
                      <div className="flex justify-between items-center text-[#130707] font-bold text-[12px] border border-[#F0E2E2] rounded-[5px] py-[6px] px-[12px] ">
                        <input
                          type="number"
                          name="price"
                          onChange={handleInputChange}
                          className="flex-1"
                        />
                        <p>{language === "ar" ? "ج.م" : "EGP"}</p>
                      </div>
                    </div>
                    <div className="flex-1">
                      <p className="text-[#8B6464] text-[13px]">
                        {language === "ar"
                          ? "الوزن * الارتفاع * العرض * الطول"
                          : "Weight * Height * Width * Length"}
                      </p>
                      <input
                        type="text"
                        name="dimensions"
                        onChange={handleInputChange}
                        className="text-[#130707] text-[12px] border border-[#F0E2E2] rounded-[5px] mb-[20px] py-[6px] px-[12px] w-full"
                      />
                    </div>
                    <div className="flex-1">
                      <p className="text-[#8B6464] text-[13px]">
                        {language === "ar" ? "الشركة" : "Compaar"}
                      </p>
                      <input
                        type="text"
                        name="compaar"
                        onChange={handleInputChange}
                        className="text-[#130707] text-[12px] border border-[#F0E2E2] rounded-[5px] mb-[20px] py-[6px] px-[12px] w-full"
                      />
                    </div>
                  </div>
                  <div className="flex gap-4 max-sm:flex-col">
                    <div className="flex-1">
                      <div className="border border-[#FF0303] rounded-lg p-4  flex gap-2">
                        <RadioButton
                          inputId="passenger"
                          name="passenger"
                          value="passenger"
                          onChange={(e) => setSelectedVechileType("passenger")}
                          checked={selectedVechileType === "passenger"}
                        />
                        <label
                          htmlFor="passenger"
                          className="text-base max-sm:text-sm font-semibold text-[#130707]"
                        >
                          {language === "ar" ? "سيارة ركاب" : "Passenger Car"}
                        </label>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="border border-[#FF0303] rounded-lg p-4  flex gap-2">
                        <RadioButton
                          inputId="heavy"
                          name="heavy"
                          value="heavy"
                          onChange={(e) => setSelectedVechileType("heavy")}
                          checked={selectedVechileType === "heavy"}
                        />
                        <label
                          htmlFor="heavy"
                          className="text-base max-sm:text-sm font-semibold text-[#130707]"
                        >
                          {language === "ar" ? "الشاحنات" : "Heavy Car"}
                        </label>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="border border-[#FF0303] rounded-lg p-4  flex gap-2">
                        <RadioButton
                          inputId="motor"
                          name="motor"
                          value="motor"
                          onChange={(e) => setSelectedVechileType("motor")}
                          checked={selectedVechileType === "motor"}
                        />
                        <label
                          htmlFor="motor"
                          className="text-base max-sm:text-sm font-semibold text-[#130707]"
                        >
                          {language === "ar" ? "دراجات" : "Motor"}
                        </label>
                      </div>
                    </div>
                  </div>
                  {selectedVechileType === "passenger" && (
                    <div className="flex gap-4 max-sm:flex-col">
                      <div className="flex-1">
                        <p className="text-[#8B6464] text-[13px]">
                          {language === "ar" ? "نوع السيارة" : "Style of Car"}
                        </p>
                        {carStyles && (
                          <ListBox
                            options={carStyles}
                            selected={selectedStyle}
                            setSelected={setSelectedStyle}
                            type="brands"
                          />
                        )}
                      </div>
                      <div className="flex-1">
                        <p className="text-[#8B6464] text-[13px]">
                          {language === "ar" ? "ماركات" : "Brands"}
                        </p>
                        {brands && (
                          <ListBox
                            options={brands}
                            selected={selectedBrand}
                            setSelected={setSelectedBrand}
                            type="brands"
                          />
                        )}
                      </div>
                      <div className="flex-1">
                        <p className="text-[#8B6464] text-[13px]">
                          {language === "ar" ? "نموذج" : "Model"}
                        </p>
                        {models && (
                          <ListBox
                            options={models}
                            selected={selectedModel}
                            setSelected={setSelectedModel}
                            type="brands"
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex gap-4">
              <button
                type="button"
                className="bg-[#FFE0D0] text-[#FF0303] rounded-lg py-4 px-8 flex-1"
              >
                {language === 'ar' ? 'الغاء' : 'Cancel'}
              </button>
              <button
                type="button"
                onClick={addProduct}
                className="text-white bg-[#FF0303] rounded-lg py-4 px-8 flex-[3]"
              >
                {language === 'ar' ? 'ارسال' : 'Submit'}
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default AddProduct;
