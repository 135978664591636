import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import loginImage from "../../assets/images/login.svg"
import logo from "../../assets/images/logo.png"
import "./login.css"
import { API } from '../../api';

const ForgotPassword = () => {
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();
    const navigate = useNavigate()
    const submit = async (e) => {
        e.preventDefault()
        const { data } = await API.post('forget-password', { email: email })
        setMessage(data.message)
        window.localStorage.setItem('email', `${email}`)
        navigate('/reset-password')
    }
    return (
        <div className="login-page">
            <div className="box">
                <div className="login-form">
                    <div className="container box-from">
                        <div className="logo">
                            <img src={logo} alt="Logo Image" />
                        </div>
                        <div className="form-head">
                            <h1>Forgot Password</h1>
                            <p>Please enter your E-mail Address</p>
                        </div>
                        <div className="form-fileds">
                            <form onSubmit={submit}>
                                <input type="email" name="email" id="email" placeholder="example@mail.com" />
                                <input type="submit" value="submit" />
                            </form>
                        </div>
                    </div>
                </div>
                <div className="logo-image">
                    <img src={loginImage} alt="Login" />
                </div>
            </div>
        </div >
    );
};

export default ForgotPassword;
