import React from "react";
import { useLanguage } from "../../../../translations/LanguageContext";

export default function ServicesCard({
  classNames,
  title,
  description,
  image,
}) {
  const { language } = useLanguage();

  return (
    <div className={`shadow-lg rounded-[5px] overflow-hidden ${classNames}`}>
      <img
        src={image}
        alt=""
        className="w-full h-[220px] cursor-pointer object-cover"
      />

      <div className="mt-3 p-3">
        <h2 className="text-[24px] mb-2 font-medium text-[#FF1716]">{title}</h2>

        <p className="text-[#8B6464] text-[14px] mb-4">{description}</p>

        <button className="block text-center w-full p-3 text-white font-medium capitalize rounded-[5px] bg-[var(--primary-color)]">
          {language === "ar" ? "احجز الان" : "Book now"}
        </button>
      </div>
    </div>
  );
}
