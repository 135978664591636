import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { API } from "../../api";
import upload from "../../assets/icons/upload.svg";
import { useLanguage } from "../../translations/LanguageContext";
const Banners = () => {
  const { language } = useLanguage();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let mode = searchParams.get("mode");
  const [active, setActive] = useState(language === 'ar' ? "RIGHT" : "LEFT");
  const [banner, setBanner] = useState();
  const [uploadedImage, setUploadedImage] = useState();
  const [errorImage, setErrorImage] = useState()
  const activeStyle =
    "flex-1 flex gap-2 justify-center items-center bg-[#FFE0D0] text-red rounded-lg px-6 py-4 cursor-pointer";
  const defaultStyle =
    "flex-1 flex gap-2 justify-center items-center border-[1.5px] border-[#E1CBCB] rounded-lg px-6 py-4 cursor-pointer";

  const updateBanner = async () => {
    const { data } = await API.put(
      `banners/${banner.id}`,
      {
        title: banner.attributes.title,
        subtitle: banner.attributes.subtitle,
        description: banner.attributes.description,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );
    console.log(data);
    if (uploadedImage) {
      const formData = new FormData();
      formData.append("image", uploadedImage);
      const { data: imageReq } = await API.post(
        `banners/${banner.id}/background`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      console.log(imageReq);
    }
    navigate(-1)
  };
  useEffect(() => {
    const getBanners = async () => {
      const { data } = await API.get("banners");
      let banner = data.data.filter(
        (banner) => banner.attributes.position === active
      );
      setBanner(banner[0]);
    };
    getBanners();
  }, [active]);
  return (
    <div className="pb-4">
      <div className={`flex justify-between gap-4 mb-4 max-md:flex-col ${language === 'ar' && 'flex-row-reverse max-md:flex-col-reverse'}`}>
        <div
          onClick={() => {
            setActive("LEFT");
            setUploadedImage(null);
          }}
          className={active === "LEFT" ? activeStyle : defaultStyle}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="20"
            viewBox="0 0 22 20"
            fill="none"
          >
            <path
              d="M1.7334 18.4L1.14775 17.9315C0.967645 18.1566 0.932531 18.465 1.05742 18.7249C1.18231 18.9847 1.44509 19.15 1.7334 19.15V18.4ZM7.0134 11.8L7.59905 11.3315C7.45672 11.1536 7.24124 11.05 7.0134 11.05C6.78556 11.05 6.57008 11.1536 6.42775 11.3315L7.0134 11.8ZM8.9334 14.2L8.34775 14.6685C8.49007 14.8464 8.70556 14.95 8.9334 14.95C9.16123 14.95 9.37672 14.8464 9.51905 14.6685L8.9334 14.2ZM13.2534 8.80001L13.839 8.33149C13.6967 8.15357 13.4812 8.05001 13.2534 8.05001C13.0256 8.05001 12.8101 8.15357 12.6677 8.33149L13.2534 8.80001ZM20.9334 18.4V19.15C21.2217 19.15 21.4845 18.9847 21.6094 18.7249C21.7343 18.465 21.6991 18.1566 21.519 17.9315L20.9334 18.4ZM6.9834 3.40001C6.9834 3.97991 6.5133 4.45001 5.9334 4.45001V5.95001C7.34172 5.95001 8.4834 4.80833 8.4834 3.40001H6.9834ZM5.9334 4.45001C5.3535 4.45001 4.8834 3.97991 4.8834 3.40001H3.3834C3.3834 4.80833 4.52507 5.95001 5.9334 5.95001V4.45001ZM4.8834 3.40001C4.8834 2.82011 5.3535 2.35001 5.9334 2.35001V0.850006C4.52507 0.850006 3.3834 1.99168 3.3834 3.40001H4.8834ZM5.9334 2.35001C6.5133 2.35001 6.9834 2.82011 6.9834 3.40001H8.4834C8.4834 1.99168 7.34172 0.850006 5.9334 0.850006V2.35001ZM2.31905 18.8685L7.59905 12.2685L6.42775 11.3315L1.14775 17.9315L2.31905 18.8685ZM6.42775 12.2685L8.34775 14.6685L9.51905 13.7315L7.59905 11.3315L6.42775 12.2685ZM9.51905 14.6685L13.839 9.26853L12.6677 8.33149L8.34775 13.7315L9.51905 14.6685ZM12.6677 9.26853L20.3477 18.8685L21.519 17.9315L13.839 8.33149L12.6677 9.26853ZM20.9334 17.65H1.7334V19.15H20.9334V17.65Z"
              fill={active === "LEFT" ? "#B70B28" : "#000000"}
            />
          </svg>
          <p>{language === "ar" ? "لافتة اليسار" : "Left Banner"}</p>
        </div>
        <div
          onClick={() => {
            setActive("CENTER");
            setUploadedImage(null);
          }}
          className={active === "CENTER" ? activeStyle : defaultStyle}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="20"
            viewBox="0 0 22 20"
            fill="none"
          >
            <path
              d="M1.7334 18.4L1.14775 17.9315C0.967645 18.1566 0.932531 18.465 1.05742 18.7249C1.18231 18.9847 1.44509 19.15 1.7334 19.15V18.4ZM7.0134 11.8L7.59905 11.3315C7.45672 11.1536 7.24124 11.05 7.0134 11.05C6.78556 11.05 6.57008 11.1536 6.42775 11.3315L7.0134 11.8ZM8.9334 14.2L8.34775 14.6685C8.49007 14.8464 8.70556 14.95 8.9334 14.95C9.16123 14.95 9.37672 14.8464 9.51905 14.6685L8.9334 14.2ZM13.2534 8.80001L13.839 8.33149C13.6967 8.15357 13.4812 8.05001 13.2534 8.05001C13.0256 8.05001 12.8101 8.15357 12.6677 8.33149L13.2534 8.80001ZM20.9334 18.4V19.15C21.2217 19.15 21.4845 18.9847 21.6094 18.7249C21.7343 18.465 21.6991 18.1566 21.519 17.9315L20.9334 18.4ZM6.9834 3.40001C6.9834 3.97991 6.5133 4.45001 5.9334 4.45001V5.95001C7.34172 5.95001 8.4834 4.80833 8.4834 3.40001H6.9834ZM5.9334 4.45001C5.3535 4.45001 4.8834 3.97991 4.8834 3.40001H3.3834C3.3834 4.80833 4.52507 5.95001 5.9334 5.95001V4.45001ZM4.8834 3.40001C4.8834 2.82011 5.3535 2.35001 5.9334 2.35001V0.850006C4.52507 0.850006 3.3834 1.99168 3.3834 3.40001H4.8834ZM5.9334 2.35001C6.5133 2.35001 6.9834 2.82011 6.9834 3.40001H8.4834C8.4834 1.99168 7.34172 0.850006 5.9334 0.850006V2.35001ZM2.31905 18.8685L7.59905 12.2685L6.42775 11.3315L1.14775 17.9315L2.31905 18.8685ZM6.42775 12.2685L8.34775 14.6685L9.51905 13.7315L7.59905 11.3315L6.42775 12.2685ZM9.51905 14.6685L13.839 9.26853L12.6677 8.33149L8.34775 13.7315L9.51905 14.6685ZM12.6677 9.26853L20.3477 18.8685L21.519 17.9315L13.839 8.33149L12.6677 9.26853ZM20.9334 17.65H1.7334V19.15H20.9334V17.65Z"
              fill={active === "CENTER" ? "#B70B28" : "#000000"}
            />
          </svg>
          <p>{language === "ar" ? "لافتة المركز" : "Center Banner"}</p>
        </div>
        <div
          onClick={() => {
            setActive("RIGHT");
            setUploadedImage(null);
          }}
          className={active === "RIGHT" ? activeStyle : defaultStyle}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="20"
            viewBox="0 0 22 20"
            fill="none"
          >
            <path
              d="M1.7334 18.4L1.14775 17.9315C0.967645 18.1566 0.932531 18.465 1.05742 18.7249C1.18231 18.9847 1.44509 19.15 1.7334 19.15V18.4ZM7.0134 11.8L7.59905 11.3315C7.45672 11.1536 7.24124 11.05 7.0134 11.05C6.78556 11.05 6.57008 11.1536 6.42775 11.3315L7.0134 11.8ZM8.9334 14.2L8.34775 14.6685C8.49007 14.8464 8.70556 14.95 8.9334 14.95C9.16123 14.95 9.37672 14.8464 9.51905 14.6685L8.9334 14.2ZM13.2534 8.80001L13.839 8.33149C13.6967 8.15357 13.4812 8.05001 13.2534 8.05001C13.0256 8.05001 12.8101 8.15357 12.6677 8.33149L13.2534 8.80001ZM20.9334 18.4V19.15C21.2217 19.15 21.4845 18.9847 21.6094 18.7249C21.7343 18.465 21.6991 18.1566 21.519 17.9315L20.9334 18.4ZM6.9834 3.40001C6.9834 3.97991 6.5133 4.45001 5.9334 4.45001V5.95001C7.34172 5.95001 8.4834 4.80833 8.4834 3.40001H6.9834ZM5.9334 4.45001C5.3535 4.45001 4.8834 3.97991 4.8834 3.40001H3.3834C3.3834 4.80833 4.52507 5.95001 5.9334 5.95001V4.45001ZM4.8834 3.40001C4.8834 2.82011 5.3535 2.35001 5.9334 2.35001V0.850006C4.52507 0.850006 3.3834 1.99168 3.3834 3.40001H4.8834ZM5.9334 2.35001C6.5133 2.35001 6.9834 2.82011 6.9834 3.40001H8.4834C8.4834 1.99168 7.34172 0.850006 5.9334 0.850006V2.35001ZM2.31905 18.8685L7.59905 12.2685L6.42775 11.3315L1.14775 17.9315L2.31905 18.8685ZM6.42775 12.2685L8.34775 14.6685L9.51905 13.7315L7.59905 11.3315L6.42775 12.2685ZM9.51905 14.6685L13.839 9.26853L12.6677 8.33149L8.34775 13.7315L9.51905 14.6685ZM12.6677 9.26853L20.3477 18.8685L21.519 17.9315L13.839 8.33149L12.6677 9.26853ZM20.9334 17.65H1.7334V19.15H20.9334V17.65Z"
              fill={active === "RIGHT" ? "#B70B28" : "#000000"}
            />
          </svg>
          <p>{language === "ar" ? "لافتة اليمين" : "Right Banner"}</p>
        </div>
      </div>
      <div className="flex gap-6 max-md:flex-col max-md:items-center">
        <div className="max-w-[300px] flex flex-col items-center">
          <img
            src={
              uploadedImage
                ? URL.createObjectURL(uploadedImage)
                : banner?.attributes.background
            }
            alt=""
            className="w-full rounded-xl mb-4"
          />
          {mode === "edit" && (
            <div className="flex flex-col items-center">
              <label htmlFor="imageInput" className="cursor-pointer mb-2">
                <img src={upload} alt="upload" />
              </label>
              <input
                type="file"
                accept="image/*"
                id="imageInput"
                name="imageInput"
                hidden
                onChange={(e) => {
                  const img = new Image()
                  const selectedImage = URL.createObjectURL(e.target.files[0])
                  img.src = selectedImage
                  img.onload = function () {
                    const height = img.height;
                    const width = img.width;
                    console.log(height, width);
                    if (height >= 700 && width >= 1200) {
                      setUploadedImage(e.target.files[0]);
                      setErrorImage(null)
                    } else {
                      setErrorImage('Image diminsions does not match requierments')
                    }
                  }
                }}
              />
              <p className="text-center text-gray-400">Diminsion must be atleast 1200*700</p>
              <p className="text-center text-red-500">{errorImage}</p>
            </div>
          )}
        </div>
        <div className="flex-1">
          <form className="p-6 box-shadow mb-[20px]">
            <h2 className="text-[#8B6464] text-[18px] font-semibold mb-6">
              {language === "ar"
                ? `مظهر لافتة ${active} `
                : `View ${active.toLowerCase()} banner`}
            </h2>
            <div className="mb-6">
              <p className="text-[#8B6464] text-sm mb-1">
                {language === "ar" ? "العنوان" : "Title"}
              </p>
              <input
                type="text"
                value={banner?.attributes.title}
                disabled={mode === "edit" ? false : true}
                onChange={(e) => {
                  setBanner((prev) => {
                    return {
                      ...prev,
                      attributes: { ...prev.attributes, title: e.target.value },
                    };
                  });
                }}
                className="w-full border border-[#F0E2E2] rounded-md px-[14px] py-[7px] text-2xl leading-6 font-semibold"
              />
            </div>
            <div className="mb-6">
              <p className="text-[#8B6464] text-sm mb-1">
                {language === "ar" ? "العنوان الفرعي" : "Subtitle"}
              </p>
              <input
                type="text"
                value={banner?.attributes.subtitle}
                disabled={mode === "edit" ? false : true}
                onChange={(e) => {
                  setBanner((prev) => {
                    return {
                      ...prev,
                      attributes: {
                        ...prev.attributes,
                        subtitle: e.target.value,
                      },
                    };
                  });
                }}
                className="w-full border border-[#F0E2E2] rounded-md px-[14px] py-[7px] text-2xl leading-6] text-opacity-70"
              />
            </div>
            <div className="mb-6">
              <p className="text-[#8B6464] text-sm mb-1">
                {language === "ar" ? "الوصف" : "Description"}
              </p>
              <textarea
                type="text"
                rows={5}
                value={banner?.attributes.description}
                disabled={mode === "edit" ? false : true}
                onChange={(e) => {
                  setBanner((prev) => {
                    return {
                      ...prev,
                      attributes: {
                        ...prev.attributes,
                        description: e.target.value,
                      },
                    };
                  });
                }}
                className="resize-none w-full border border-[#F0E2E2] rounded-md px-[14px] py-[7px] text-sm leading-6] text-opacity-70"
              />
            </div>
          </form>
          <div className="flex gap-3">
            <button
              type="button"
              className="bg-[#FFE0D0] text-[#FF1716] py-4 px-8 rounded-lg"
              onClick={() => {
                if (mode === "edit") {
                  navigate(-1);
                }
              }}
            >
              {mode === "edit"
                ? language === "ar"
                  ? "إلغاء"
                  : "Cancel"
                : language === "ar"
                  ? "حذف"
                  : "Delete"}
            </button>
            <button
              type="button"
              className="bg-[#FF1716] text-white py-4 px-8 rounded-lg flex-1"
              onClick={() => {
                if (mode === "edit") {
                  updateBanner();
                } else {
                  navigate("?mode=edit");
                }
              }}
            >
              {mode === "edit"
                ? language === "ar"
                  ? "حفظ التغيرات"
                  : "Save Changes"
                : language === "ar"
                  ? "تعديل"
                  : "Edit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banners;
