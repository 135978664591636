import { faCalendar, faClock } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export default function Card({ post }) {

    return (
        <div className='flex flex-col sm:flex-row sm:items-center justify-between mb-5'>

            <div className='w-full sm:w-[40%] rounded-[5px] overflow-hidden'>

                <img src={post.attributes.image || ''} alt={post.attributes.image || ''} className='w-full h-[254px] object-fill' />

            </div>

            <div className='w-full sm:w-[58%] p-3'>


                <div className='flex items-center mb-3'>

                    {
                        post?.categories.map((category) => {
                            return <div key={category.id} className='bg-[#8B6464] text-[#E1CBCB] p-1 px-2 rounded-[4px] me-2 text-[11px]'>{category.attributes.title}</div>
                        })
                    }

                </div>

                <h1 className='font-medium text-[30px] mb-3'>{post.attributes.title}</h1>

                <div className='flex items-center mb-3'>

                    <div className='flex items-center me-2'>

                        <img src={post.user.attributes.image ? post.user.attributes.image : require('../../../../assets/images/image-placeholder.png')} alt='' className='w-[20px] h-[20px] rounded-full me-2 object-fill' />

                        <p className='text-[11px] text-[#777]'>{post.user.attributes.name}</p>

                    </div>

                    <div className='flex items-center me-2'>

                        <FontAwesomeIcon icon={faCalendar} className='text-[11px] me-2 text-[#777]' />

                        <p className='text-[11px] text-[#777]'>{post.user.attributes.name}</p>

                    </div>

                    <div className='flex items-center me-2'>

                        <FontAwesomeIcon icon={faClock} className='text-[11px] me-2 text-[#777]' />

                        <p className='text-[11px] text-[#777]'>{post.user.attributes.name}</p>

                    </div>

                </div>

                <p className='my-3 text-[#555] font-medium'>

                    {post.attributes.content}

                </p>

                <button className="p-2 px-5 rounded-[5px] flex items-center bg-[#DB1020]" >

                    <span className='text-white me-2'>Share</span>


                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M14.0001 7.63937L7.60013 3.40002L7.60012 5.80002C2 7.00002 2 12.6 2 12.6C2 12.6 4.4 9.40002 7.60013 9.80002L7.60012 12.28L14.0001 7.63937Z" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
                    </svg>


                </button>

            </div>

        </div>
    )
}
