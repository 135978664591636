import { Dialog } from "@headlessui/react";
import { useState } from "react";
import { RelatedLinks } from "../../components";
import DesktopHeader from "./DesktopHeader/DesktopHeader";
import MobileMenuActions from "./MobileMenuActions/MobileMenuActions";
import { desktopLinks, mobileLinkes } from "./headerData";
import { Dropdown } from "primereact/dropdown";
import ar from "../../assets/icons/ar-icon.svg";
import en from "../../assets/images/en-logo.png";
import { useLanguage } from "../../translations/LanguageContext";

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { language, changeLanguage } = useLanguage();
  const [selectedCity, setSelectedCity] = useState({
    name: "English",
    code: "en",
  });
  const cities = [
    { name: "English", code: "en" },
    { name: "Arabic", code: "ar" },
  ];

  const handleLanguageChange = (event) => {
    setSelectedCity(event.value);
    changeLanguage(event.value.code);
    localStorage.setItem("lang", event.value.code);
    // window.location.reload();
    console.log(event.value);
  };

  const languageItemTemplate = (option) => {
    return (
      <div className="flex items-center justify-center text-lg">
        <span className="mr-2 w-[20px] h-[20px] text-center">
          {option.name === "Arabic" ? (
            <img src={ar} alt="arabic" />
          ) : (
            <img className="w-full h-full" src={en} alt="english" />
          )}
        </span>
        {option.name}
      </div>
    );
  };

  return (
    <header className="bg-white drop-shadow sticky top-0 left-0 z-[50] px-[32px] h-[72px] border-bottom">
      <DesktopHeader
        setMobileMenuOpen={setMobileMenuOpen}
        desktopLinks={desktopLinks}
      />

      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />

        <Dialog.Panel className="fixed inset-y-0 right-0 z-[553] w-[250px] transition sm:w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <MobileMenuActions setMobileMenuOpen={setMobileMenuOpen} />

          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6 ">
                <RelatedLinks list={mobileLinkes} />
              </div>
            </div>

            <div className="relative w-full z-[555] lg:flex lg:gap-x-6 xl:gap-x-12 lg:justify-end lg:items-center">
              <div className="relative w-full">
                <Dropdown
                  value={language}
                  onChange={handleLanguageChange}
                  options={cities}
                  optionLabel="name"
                  placeholder={language === "ar" ? "العربية" : "English"}
                  className="!bg-[#FFE0D0] !text-black !p-0 w-full mt-5 text-center"
                  itemTemplate={languageItemTemplate}
                />
              </div>
            </div>
          </div>
        </Dialog.Panel>f
      </Dialog>
    </header>
  );
}
