import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useLanguage } from '../../../translations/LanguageContext';

export default function FooterContent() {
    const { language } = useLanguage();

    return (

        <div className="flex-1" style={{ textTransform: "initial" }}>

            <img src={require('../../../assets/images/logo.png')} className="h-[55px] w-[185px] mb-5" />

            <p className='text-[#8B6464] text-[17px] mb-5'>
                {language === 'ar' ? 'نص تجريبي نص تجريبي نص تجريبي نص تجريبي نص تجريبي نص تجريبي نص تجريبي نص تجريبي نص تجريبي نص تجريبي نص تجريبي نص تجريبي نص تجريبي نص تجريبي نص تجريبي نص تجريبي نص تجريبي نص تجريبي نص تجريبي نص تجريبي نص تجريبي ' :
                    'We ara a lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat'}
            </p>

            <p className='text-[17px] text-[#8B6464] flex items-center mb-5'>

                <FontAwesomeIcon icon={faPhone} className='me-3' />

                <span>{
                    language === 'ar' ?
                        '٠١٢٧١٦٦٨١١٠' : '(+20) 127 166 8110'}</span>

            </p>

            <p className='text-[17px] text-[#8B6464] flex items-center mb-5'>

                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
                    <path d="M9 20.6C9 20.6 16.513 13.9218 16.513 8.91307C16.513 4.76373 13.1493 1.40002 9 1.40002C4.85066 1.40002 1.48695 4.76373 1.48695 8.91307C1.48695 13.9218 9 20.6 9 20.6Z" stroke="#8B6464" strokeWidth="1.5" />
                    <path d="M11.4003 8.60018C11.4003 9.92566 10.3258 11.0002 9.0003 11.0002C7.67482 11.0002 6.6003 9.92566 6.6003 8.60018C6.6003 7.27469 7.67482 6.20018 9.0003 6.20018C10.3258 6.20018 11.4003 7.27469 11.4003 8.60018Z" stroke="#8B6464" strokeWidth="1.5" />
                </svg>

                <span className='ms-3'>{language === "ar"
                    ? "9 شارع حفص شارع شركة بطاريات النسر الإبراهيمية الإسكندرية"
                    : "9 Hafs street, Al-Nesr Battery Company, Al-Ibrahimia, Alexandria"}</span>

            </p>
        </div>
    )
}
