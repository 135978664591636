import React, { useState, useEffect } from 'react'
import Card from './Card'
import { SwiperWrapper } from '../../../../components';
import { SwiperSlide } from 'swiper/react';
import reel from '../../../../assets/images/reel.png'
import play from '../../../../assets/icons/play.svg'
import { API } from '../../../../api';

const items = (data) => data.map((item) => {
    return <SwiperSlide key={item.id} className='relative rounded-3xl overflow-hidden'>
        <a href={item.attributes.url} key={item.id} >
            <div>
                <img src={reel} alt='cover' className=' z-10' />
                <div className='z-20 absolute top-3 left-3 bg-[#130707b9] p-3 rounded-[60px]'>
                    <p className='text-[#FF1716] text-xs font-bold'>{item.attributes.views} Views</p>
                </div>
                <div className='z-[5000] absolute bottom-[80px] sm:bottom-[100px] left-[50%] translate-x-[-50%]'>
                    <img src={play} alt='play' className='max-sm:w-8 max-sm:h-8' />
                </div>
                <div className='w-[120px] sm:w-[248px] flex flex-col justify-center items-center z-20 absolute bottom-3 left-[50%] translate-x-[-50%] bg-[#130707b9] py-3 rounded-[60px]'>
                    <p className='text-white tracking-widest text-[10px] sm:text-sm'>{item.attributes.title}</p>
                    <p className='text-white tracking-tight text-[6px] sm:text-xs'>{item.attributes.date} {item.attributes.time}</p>
                </div>
            </div>
        </a>
    </SwiperSlide>
})

export default function Videos() {
    const [reels, setReels] = useState()

    useEffect(() => {
        const getReels = async () => {
            const { data } = await API.get('reels?page=1');
            setReels(data.data)
            console.log(data.data);
        }
        getReels()
    }, [])
    return (
        <section className='my-10 relative'>

            <div className='px-3'>

                <SwiperWrapper
                    items={reels && items(reels)}
                    slidesPerViewCount={[2, 3, 3, 4]}
                    autoplayDelay={50000}
                    isLooped={true}
                    includeNavigation={true}
                />

            </div>

        </section>
    )
}
