import React, { useEffect, useState } from 'react'
import { SwiperWrapper } from '../../../../components'
import { SwiperSlide } from 'swiper/react'
import { API } from '../../../../api';

const items = (data) => data.map((item, index) => {
    return <SwiperSlide className='h-full' key={index}>
        <img src={item.attributes.image} alt='' className='absolute left-0 top-0 w-full h-full object-fill z-[-1]' />

        <div className='absolute left-0 top-0 w-full h-full bg-[#ff000021] z-[-1]'>  </div>

        <div className='h-full flex items-center justify-center flex-col'>

            <h1 className='bg-white py-3 px-5 sm:px-10 rounded-full text-[#FF1716] text-[24px] sm:text-[30px] font-medium mb-[150px]'>We can help you in</h1>

            <h5 className='text-[40px] text-center sm:text-left font-medium mb-5 text-white'>{item.attributes.title}</h5>

            <p className='text-[#FFF] opacity-80 text-[25px] font-medium sm:w-[50%] text-center'>
                {item.attributes.description ? item.attributes.description : "Dolorum culpa voluptate accusantium placeat. Error inventore quo in odit eos. Deserunt consectetur ut sed et. In illum sequi modi ipsum sed dicta."}            </p>

        </div>
    </SwiperSlide>
});


export default function Help() {
    const [services, setServices] = useState()
    useEffect(() => {
        const getServices = async () => {
            const { data } = await API.get('services')
            console.log(data.data);
            setServices(data.data);
        };
        getServices();
    }, [])
    return (
        <section className='my-10 relative min-h-[720px]'>
            {services && <SwiperWrapper
                classNames="!h-[720px]"
                items={items(services)}
                slidesPerViewCount={[1, 1, 1, 1]}
                autoplayDelay={50000}
                isLooped={true}
                includeNavigation={true}
            />}
        </section>
    )
}
