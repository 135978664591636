import React, { useState } from "react";

const ProductsCategory = () => {
  const [styles, setStyles] = useState([
    { title: "Scooter" },
    { title: "Sport Bike" },
    { title: "Motocross" },
    { title: "Cruiser" },
  ]);
  const [marks, setMarks] = useState([
    { title: "BMW" },
    { title: "Kawasaki" },
    { title: "Yamaha" },
    { title: "Suzuki" },
  ]);
  const [models, setModels] = useState([
    { title: "540i/2024" },
    { title: "540i/2023" },
    { title: "540i/2022" },
    { title: "340i/2024" },
    { title: "340i/2023" },
    { title: "340i/2022" },
    { title: "M8/2024" },
    { title: "M8/2023" },
  ]);

  const carTypes = ["Passenger car", "Heavy Vehicles", "Motorcycle"];

  const [selectedOptions, setSelectedOptions] = useState({
    "Type of car": "Passenger car",
  });
  const [newOptions, setNewOptions] = useState({});

  const handleOptionClick = (option, title) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [title]: option,
    }));
  };

  const handleNewOptionSubmit = (e, title) => {
    e.preventDefault();

    const newOption = newOptions[title];
    const isDuplicate =
      optionList
        .find((option) => option.title === title)
        ?.options.includes(newOption) ||
      (title === "Style" &&
        styles.findIndex((style) => style.title === newOption) !== -1) ||
      (title === "Marka" &&
        marks.findIndex((mark) => mark.title === newOption) !== -1) ||
      (title === "Model" &&
        models.findIndex((model) => model.title === newOption) !== -1);

    if (!isDuplicate) {
      if (title === "Style") {
        setStyles((prevOptions) => [...prevOptions, { title: newOption }]);
      } else if (title === "Marka") {
        setMarks((prevOptions) => [...prevOptions, { title: newOption }]);
      } else if (title === "Model") {
        setModels((prevOptions) => [...prevOptions, { title: newOption }]);
      }

      setNewOptions((prevNewOptions) => ({
        ...prevNewOptions,
        [title]: "",
      }));
    }
  };

  const handleInputChange = (e, title) => {
    const value = e.target.value;
    setNewOptions((prevNewOptions) => ({
      ...prevNewOptions,
      [title]: value,
    }));
  };

  const optionList = [
    { title: "Type of car", options: carTypes, isCarType: true },
    { title: "Style", options: styles },
    { title: "Marka", options: marks },
    { title: "Model", options: models },
  ];

  return (
    <div className="flex items-start justify-center gap-[34px] p-5 max-md:flex-col max-md:justify-center">
      {optionList.map((option, index) => (
        <div
          key={index}
          className="flex items-start justify-center flex-col gap-[10px] flex-1 max-md:w-full"
        >
          <h3 className="text-black text-xl font-medium font-['Tajawal'] capitalize leading-[28.85px] ">
            {option.title}
          </h3>
          <div className="w-full flex flex-col gap-[10px]">
            {option.isCarType
              ? option.options.map((carType, carIndex) => (
                  <button
                    key={carIndex}
                    className={`w-full h-[88px] rounded-[8px] border-2 justify-center items-center flex p-[9px 23px] text-black font-['Tajawal'] font-medium text-[14px] capitalize leading-[20px] ${
                      carType === selectedOptions[option.title]
                        ? "bg-[#DB1020] border-none text-white"
                        : "bg-white border-[#E1CBCB]"
                    }`}
                    onClick={() => handleOptionClick(carType, option.title)}
                  >
                    {carType}
                  </button>
                ))
              : option.options.map((item, itemIndex) => (
                  <button
                    key={itemIndex}
                    className={`w-full h-[48px] rounded-[8px] border-2 justify-center items-center flex p-[9px 23px] text-[#130707] font-['Tajawal'] font-medium text-[14px] capitalize leading-[20px] ${
                      item.title === selectedOptions[option.title]
                        ? "bg-[#FFE0D0] text-[#B70B28] border-none"
                        : "bg-white border-[#E1CBCB]"
                    }`}
                    onClick={() => handleOptionClick(item.title, option.title)}
                  >
                    {item.title}
                  </button>
                ))}
          </div>
          {!option.isCarType && (
            <div className="w-full">
              <form
                className="w-full"
                onSubmit={(e) => handleNewOptionSubmit(e, option.title)}
              >
                <input
                  type="text"
                  placeholder="Other..."
                  value={newOptions[option.title] || ""}
                  onChange={(e) => handleInputChange(e, option.title)}
                  className="h-[40px] w-full rounded-lg border-2 border-[#E1CBCB] justify-center items-center flex px-[14px] py-[17px] text-[#130707] font-['Tajawal'] font-medium text-[14px] capitalize leading-[20px]"
                />
              </form>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ProductsCategory;
