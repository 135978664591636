import React from 'react'
import { Link } from 'react-router-dom'
import BreadcrumbNav from '../../layouts/BreadcrumbNav/BreadcrumbNav'
export default function LandingPage({ title, pText, btnText }) {
    return (
        <section className='relative h-[377px] flex flex-col  justify-center'>

            <img src={require('../../assets/images/landing.png')} alt='' className='w-full h-full absolute left-0 top-0 z-[-1]' />

            <div className='px-8'>

                <h1 className='mb-2 sm:mb-5 text-[#FF8B73] text-[36px] sm:text-[48px] font-medium capitalize'>{title}</h1>

                <p className='text-white font-medium text-[12px] sm:text-[20px] mb-2 sm:mb-8 lg:w-[60%] leading-6 sm:leading-9'> {pText} </p>

                <Link to={''} className='p-2 px-4 capitalize rounded-[5px] bg-[#FF1716] text-white'>{btnText}</Link>

                <BreadcrumbNav />
            </div>

        </section>
    )
}
