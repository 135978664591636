import { useEffect, useState } from "react";
import "./App.css";
import Dashboard from "./layouts/Dashboard/Dashboard";
import Cookies from "js-cookie";
import { useLanguage } from "./translations/LanguageContext";
import CustomerFlow from "./pages/CustomerFlow/CustomerFlow";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    // const token = window.localStorage.getItem('token')
    const token = Cookies.get("token");
    if (token) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, []);

  const { language } = useLanguage();

  return (
    <>
      <div dir={language === "ar" ? "rtl" : "ltr"}>
        {loggedIn ? (
          <Dashboard />
        ) : (
          <CustomerFlow />
        )}
      </div>
    </>
  );
}

export default App;
