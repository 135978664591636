import React from "react";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const WhatsAppButton = () => {
  return (
    <a
      href="https://wa.me/01271668110"
      className="bg-[var(--primary-color)] flex items-center justify-center text-white rounded-full w-12 h-12 sm:w-[64px] sm:h-[64px] cursor-pointer fixed right-5 bottom-5 z-[10]"
      target="_blank"
    >
      <FontAwesomeIcon icon={faWhatsapp} className="w-full h-full" />
    </a>
  );
};

export default WhatsAppButton;
