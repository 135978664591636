import React, { useState } from 'react'
import { LandingPage } from '../../components'
import { Card, Categories } from './compoents'
import { useEffect } from 'react'
import { Paginator } from 'primereact/paginator'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { API } from '../../api'
import { useLanguage } from '../../translations/LanguageContext'
export default function OurNews() {
    const { language } = useLanguage()
    const [posts, setPosts] = useState([])
    const [totalRecords, setTotalRecords] = useState()
    const [searchParams] = useSearchParams()
    let page = searchParams.get('page') || 1
    const [first, setFirst] = useState(page)
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0);
        const getPosts = async () => {
            const { data } = await API.get(`posts?page=${page}`)
            setPosts(data.data)
            setTotalRecords(data.meta.total)
            // console.log(data.data);
        }
        getPosts()
    }, [page])
    return (
        <div>
            {/* title, pText, btnText  */}
            <LandingPage
                title={language === 'ar' ? 'أخبارنا' : 'our news'}
                pText={language === 'ar' ? 'ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي نص تجريبي ' : 'Omnis in consectetur harum. Reiciendis architecto quia eos. Dolorem dolorum repellat. Molestiae exercitationem amet molestiae minus. Natus qui occaecati dolores perferendis.'}
                btnText={language === 'ar' ? 'شاهد الأخبار' : 'see news'}
            />

            <div className='p-8 flex flex-col sm:flex-row gap-5'>

                <div className='flex-1'>

                    <h1 className='text-[20px] font-medium mb-10'>

                        <span className='p-1 text-[18px] rounded-[5px] bg-[#ff6050] text-white me-1'>{language === 'ar' ? 'أفضل' : 'Top'}</span> {language === 'ar' ? 'الأخبار' : "News"}

                    </h1>

                    {posts.map((post) => <Card key={post.id} post={post} />)}


                    <Paginator rows={10} totalRecords={totalRecords} first={first + 1} onPageChange={(e) => {
                        navigate(`?page=${e.page + 1}`)
                        setFirst(e.first)
                    }} />
                </div>

                <Categories />

            </div>

        </div>
    )
}
