import React, { useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { API } from "../../api";
import Cookies from "js-cookie";
import { useLanguage } from "../../translations/LanguageContext";

export default function Profile() {
  const { language } = useLanguage();
  const toast = useRef(null);
  let fullName = localStorage.getItem("name");
  const [firstName, setFirstName] = useState(fullName.split(" ")[0]);
  const [lastName, setLastName] = useState(fullName.split(" ")[1]);
  const [uploadedImage, setUploadedImage] = useState();
  const [, setToggleShowBtn] = useState(false);

  const [searchParams] = useSearchParams();
  let mode = searchParams.get("mode");
  const navigate = useNavigate();

  const logoutHandler = () => {
    window.location.reload();
    Cookies.remove("token");
    window.localStorage.removeItem("id");
    window.localStorage.removeItem("name");
    window.localStorage.removeItem("email");
    window.localStorage.removeItem("image");
  };
  const saveChangesHandler = async () => {
    const formData = new FormData();
    formData.append("name", firstName + " " + lastName);
    formData.append("email", localStorage.getItem("email"));
    // formData.append('role', 'editor');
    if (uploadedImage) {
      formData.append("image", uploadedImage);
    }

    const { data } = await API.post(
      `users/${localStorage.getItem("id")}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );
    window.localStorage.setItem("name", `${data.data.attributes.name}`);
    window.localStorage.setItem("image", `${data.data.attributes.image}`);

    console.log(data);
    navigate("/profile");
  };
  const accept = () => {
    toast.current.show({
      severity: "success",
      summary: "Confirmed",
      detail: "You have accepted",
      life: 3000,
    });
    setToggleShowBtn(false);
  };

  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
    setToggleShowBtn(false);
  };

  const confirm = () => {
    confirmDialog({
      message: "Are you sure you want to delete this item",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };

  return (
    <section>
      <div className="border border-[#B70B28] bg-white py-6 px-4 rounded-[5px] mb-4">
        <h2 className="text-[#8B6464] font-medium mb-5">
          {language === "ar" ? "تفاصيل الملف الشخصي" : "Profile Details"}
        </h2>

        <div className="border-b flex flex-col items-center w-full">
          <div className="w-full flex flex-col justify-center items-center sm:flex-row sm:justify-start sm:items-center gap-4 max-sm:mb-2">
            <img
              src={
                uploadedImage
                  ? URL.createObjectURL(uploadedImage)
                  : localStorage.getItem("image") != "null"
                    ? localStorage.getItem("image")
                    : require("../../assets/images/image-placeholder.png")
              }
              alt="avatar"
              className="w-[140px] max-w-full"
            />

            <div>
              <label
                htmlFor="upload-user-img"
                className="block w-fit bg-[#B70B28] text-white font-medium text-[18px] p-2 px-7 rounded-[50px] cursor-pointer"
              >
                {language === "ar" ? "تغير الصورة" : "Change Photo"}
              </label>
              <input
                type="file"
                id="upload-user-img"
                className="hidden"
                disabled={mode === "edit" ? false : true}
                onChange={(e) => setUploadedImage(e.target.files[0])}
              />
            </div>
          </div>
        </div>

        <form className="my-10">
          <div className="flex flex-col sm:flex-row gap-6 mb-4 max-w-full">
            <div className="flex flex-col flex-1">
              <label className="text-[#8B6464] text-sm">
                {language === "ar" ? "الاسم الاول" : "First Name"}
              </label>
              <input
                type="text"
                placeholder="Toar"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                disabled={mode === "edit" ? false : true}
                className="w-full border border-[#F0E2E2] rounded-md py-[7px] px-[14px]"
              />
            </div>
            <div className="flex flex-col flex-1">
              <label className="text-[#8B6464] text-sm">
                {language === "ar" ? "الاسم العائلة" : "Last Name"}
              </label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                disabled={mode === "edit" ? false : true}
                className="w-full border border-[#F0E2E2] rounded-md py-[7px] px-[14px]"
              />
            </div>
          </div>
          <div className="w-full">
            <div className="flex flex-col">
              <label className="text-[#8B6464] text-sm">
                {language === "ar" ? "البريد الالكتورني" : "Email"}
              </label>
              <input
                type="email"
                placeholder="example@ex.ex"
                value={localStorage.getItem("email")}
                disabled
                readOnly
                className="border border-[#F0E2E2]  rounded-md py-[7px] px-[14px]"
              />
            </div>
          </div>
        </form>
      </div>
      <div className="flex flex-col items-end  max-sm:items-center w-full py-4">
        <div className="w-1/4 flex justify-end gap-2 max-sm:flex-col max-sm:items-center max-sm:justify-center">
          <button
            className="text-[#B70B28] bg-[#FFE0D0] py-4 px-8 rounded-lg flex-1"
            onClick={() => {
              if (mode === "edit") {
                navigate("/profile");
              } else {
                navigate("/");
                logoutHandler();
              }
            }}
          >
            {mode === "edit"
              ? language === "ar"
                ? "رفض"
                : "Dismiss"
              : language === "ar"
                ? "تسجيل خروج"
                : "Logout"}
          </button>
          <button
            className="text-white bg-[#FF1716] py-4 px-8 rounded-lg flex-[3]"
            onClick={() => {
              if (mode === "edit") {
                saveChangesHandler();
              } else {
                navigate("?mode=edit");
              }
            }}
          >
            {mode === "edit"
              ? language === "ar"
                ? "حفظ التغييرات"
                : "Save Changes"
              : language === "ar"
                ? "تعديل"
                : "Edit"}
          </button>
        </div>
      </div>

      <Toast ref={toast} />

      <ConfirmDialog />
    </section>
  );
}
