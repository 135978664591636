import React, { useEffect, useState } from "react";
import xIcon from "../../assets/icons/x.svg";
import { API } from "../../api";
import Cookies from "js-cookie";
import { useLanguage } from "../../translations/LanguageContext";

const BlogsCategories = () => {
  const { language } = useLanguage();
  const [categories, setCategories] = useState();
  const [category, setCategory] = useState("");
  useEffect(() => {
    const getCategories = async () => {
      const { data } = await API.get("categories");
      setCategories(data.data);
      console.log(data.data);
    };
    getCategories();
  }, []);
  const createCategory = async () => {
    setCategories((prev) => [{ attributes: { title: category } }, ...prev]);
    const { data } = await API.post(
      "categories",
      { title: category },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );
    console.log(data.data);
  };
  const deleteCategory = async (id) => {
    setCategories((prev) => {
      return prev.filter((category) => category.id !== id);
    });
    const { data } = await API.delete(`categories/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    console.log(data.data);
  };
  return (
    <div className="p-5">
      <h2 className="text-[#8B6464] text-[20px] mb-[6px]">
        {language === "ar" ? "إنشاء فئة المدونة" : "Create Blog Category"}
      </h2>
      <div className="flex flex-col sm:flex-row gap-6">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            createCategory();
          }}
        >
          <input
            type="text"
            placeholder="..."
            onChange={(e) => setCategory(e.target.value)}
            className="block border border-[#F0E2E2] rounded-md p-3 h-[60px] w-[160px] sm:w-[260px] max-w-full mb-[17px]"
          />
          <button
            type="submit"
            className="bg-[#DB1020] text-white py-[10px] px-[20px] rounded-lg"
          >
            {language === "ar" ? "انشاء" : "Create"}
          </button>
        </form>
        <div className="flex-1 border border-[#F0E2E2] flex flex-wrap gap-[20px] rounded-[10px] p-[20px]">
          {categories?.map((category) => (
            <div
              key={category.id}
              className="flex items-center justify-center gap-[10px] h-fit category-shadow rounded-[33px] py-1 px-2"
            >
              <p className="text-[10px] sm:text-[20px]">
                {category.attributes.title}
              </p>
              <button
                onClick={() => {
                  deleteCategory(category.id);
                }}
              >
                <img src={xIcon} alt="delete" />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogsCategories;
