import React from "react";
import { useLanguage } from "../../../../translations/LanguageContext";

const data = [
  { imageSrc: "one" },
  { imageSrc: "two" },
  { imageSrc: "three" },
  { imageSrc: "four" },
];

const renderData = (language) =>
  data.map((item, index) => {
    return (
      <div
        key={index}
        className="sm:w-[48%] w-full relative p-3 bg-[#FCF8F8] rounded-[5px] h-[300px]"
      >
        <h2 className="text-[#FF1716] text-[24px] capitalize mb-5 font-medium">
          {language === "ar" ? "دراجات نارية" : "Motorcycles"}
        </h2>

        <p className="lg:max-w-[50%] text-[#707070] font-medium">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in
          et, lectus sit lorem id integer.
        </p>

        <img
          src={require(`./images/${item.imageSrc}.png`)}
          alt=""
          className="absolute bottom-0 right-0 w-[150px] h-[150px]"
        />
      </div>
    );
  });

export default function Categories() {
  const { language } = useLanguage();

  return (
    <section className="flex flex-wrap gap-5 my-20">
      {renderData(language)}
    </section>
  );
}
