import React, { useState } from "react";
import imagePlaceholder from "../../assets/images/image-placeholder.svg";
import { useNavigate } from "react-router-dom";
import { API } from "../../api";
import { useLanguage } from "../../translations/LanguageContext";

const AddTeamMember = () => {
  const { language } = useLanguage();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [image, setImage] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();
  const addUserHandler = async () => {
    const formData = new FormData();
    formData.append("name", firstName + " " + lastName);
    formData.append("email", email);
    formData.append("role", "editor");
    formData.append("password", password);
    if (image) {
      formData.append("image", image);
    }
    const { data } = await API.post("users", formData, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    console.log(data);
    navigate("/teams");
  };
  return (
    <section>
      <div className="border border-[#B70B28] bg-white py-6 px-4 rounded-[5px] mb-4">
        <h2 className="text-[#8B6464] font-medium mb-5">
          {language === "ar" ? "تفاصيل الملف الشخصي" : "Profile Details"}
        </h2>

        <div className="border-b flex flex-col items-center w-full">
          <div className="w-full flex flex-col justify-center items-center sm:flex-row sm:justify-start sm:items-center gap-4 max-sm:mb-2">
            <img
              src={image ? URL.createObjectURL(image) : imagePlaceholder}
              alt="avatar"
              className="w-[140px] max-w-full"
            />

            <div>
              <label
                htmlFor="upload-user-img"
                className="block w-fit bg-[#B70B28] text-white font-medium text-[18px] p-2 px-7 rounded-[50px] cursor-pointer"
              >
                {language === "ar" ? "تغير الصورة" : "Change Photo"}
              </label>
              <input
                type="file"
                id="upload-user-img"
                className="hidden"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </div>
          </div>
        </div>

        <form className="my-10">
          <div className="flex flex-col sm:flex-row gap-6 mb-4 max-w-full">
            <div className="flex flex-col flex-1">
              <label className="text-[#8B6464] text-sm">
                {language === "ar" ? "الاسم الاول" : "First Name"}
              </label>
              <input
                type="text"
                placeholder="Toar"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="w-full border border-[#F0E2E2] rounded-md py-[7px] px-[14px]"
              />
            </div>
            <div className="flex flex-col flex-1">
              <label className="text-[#8B6464] text-sm">
                {language === "ar" ? "اسم العائلة" : "Last Name"}
              </label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="w-full border border-[#F0E2E2] rounded-md py-[7px] px-[14px]"
              />
            </div>
          </div>
          <div className="w-full">
            <div className="flex flex-col">
              <label className="text-[#8B6464] text-sm">
                {language === "ar" ? "البريد إلكتروني" : "Email"}
              </label>
              <input
                type="email"
                placeholder="example@ex.ex"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border border-[#F0E2E2]  rounded-md py-[7px] px-[14px]"
              />
            </div>
          </div>
          <div className="w-full">
            <div className="flex flex-col">
              <label className="text-[#8B6464] text-sm">
                {language === "ar" ? "كلمة السر" : "Password"}
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="border border-[#F0E2E2]  rounded-md py-[7px] px-[14px]"
              />
            </div>
          </div>
        </form>
      </div>
      <div className="flex flex-col items-end  max-sm:items-center w-full py-4">
        <div className="w-1/4 flex justify-end gap-2 max-sm:flex-col max-sm:items-center max-sm:justify-center">
          <button
            className="text-[#B70B28] bg-[#FFE0D0] py-4 px-8 rounded-lg flex-1"
            onClick={() => {
              navigate("/teams");
            }}
          >
            {language === "ar" ? "الغاء" : "Cancel"}
          </button>
          <button
            className="text-white bg-[#FF1716] py-4 px-8 rounded-lg flex-[3]"
            onClick={addUserHandler}
          >
            {language === 'ar' ? 'ارسال' : 'Submit'}
          </button>
        </div>
      </div>
    </section>
  );
};

export default AddTeamMember;
