import React, { useEffect, useState } from 'react';
import LandingPage from '../../components/LandingPage/LandingPage';
import { ServicesCard } from './components';
import { API } from '../../api';
import { useLanguage } from '../../translations/LanguageContext';

const data = [
    'col-span-12 sm:col-span-8',
    'col-span-12 sm:col-span-4',
    'col-span-12 sm:col-span-4',
    'col-span-12 sm:col-span-4',
    'col-span-12 sm:col-span-4',
    'col-span-12 sm:col-span-12',
];

export default function Services() {
    const { language } = useLanguage();
    const [services, setServices] = useState();
    useEffect(() => {
        const getServices = async () => {
            const { data } = await API.get(
                'services'
            );
            console.log(data.data);
            setServices(data.data);
        };
        getServices();
    }, []);
    const renderData = services?.map((service, index) => (
        <ServicesCard
            classNames={data[index]}
            key={service.id}
            title={service.attributes.title}
            description={service.attributes.description}
            image={service.attributes.image}
        />
    ));
    return (
        <div>
            <LandingPage
                title={language === 'ar' ? 'خدماتنا' : 'Our Services'}
                pText={language === 'ar' ? 'ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي نص تجريبي' : 'Omnis in consectetur harum. Reiciendis architecto quia eos. Dolorem dolorum repellat. Molestiae exercitationem amet molestiae minus. Natus qui occaecati dolores perferendis.'}
                btnText={language === 'ar' ? 'شاهد الأخبار' : 'see news'}
            />

            <section className='px-8 !my-10'>
                <h1 className='text-[20px] font-medium mb-10'>
                    <span className='p-1 text-[18px] rounded-[5px] bg-[#ff6050] text-white me-1'>
                        {language === 'ar' ? 'كل' : 'All'}
                    </span>{' '}
                    {language === 'ar' ? 'خدمات' : 'Services'}
                </h1>

                <div className='grid grid-cols-12 gap-5'>{renderData}</div>
            </section>
        </div>
    );
}
