import React, { useState } from 'react';
import { useLanguage } from '../../../../translations/LanguageContext';

const items = [
    { name: 'device : FOXWELL' },
    { name: 'device : BT-705' },
    { name: 'device : NT-644 Elite series' },
    { name: 'device : Gt-75 TS full system functions ' },
    { name: 'device : FERVE ' },
    { name: 'device : F.814E' },
];
const text = {
    ar: 'نص تجريبي نص تجريبي نص تجريبي نص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص نص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبيتجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبينص تجريبي',
    en: "Certainly! The FOXWELL device accurately diagnoses potentialfaults in your car, covering a wide range of systems such as th engine, brakes, suspension, electrical, and more.With advancedcapabilities, it reads diagnostic codes, displays live data, andperforms component tests, helping pinpoint issues quickly and accurately.Its user erface makes it suitable for personal use or professional mechanics.By using the FOXWELL device, you'll receive precise information about your car's condition and faults, aiding you in making informed decisions."

}

const renderItems = (activeIndex, setActiveIndex) => {
    return items.map((item, index) => {
        return (
            <button
                key={index}
                onClick={(_) => setActiveIndex(index)}
                className={` cursor-pointer h-[63px] py-[6px] px-[16px] rounded-md flex items-center justify-center transition text-center border border-[#E1CBCB] duration-300 capitalize ${activeIndex === index
                    ? 'bg-[var(--primary-color)] text-white'
                    : 'hover:bg-[#f6f6f6] '
                    }`}
            >
                {item.name}
            </button>
        );
    });
};

export default function ProductDetails() {
    const { language } = useLanguage();

    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <section className='my-10'>
            <div className='px-[32px] flex flex-col lg:flex-row lg:items-start'>
                <div className='flex flex-col gap-3 max-sm:flex-row max-sm:flex-wrap max-sm:justify-center lg:me-[64px]'>
                    {renderItems(activeIndex, setActiveIndex)}
                </div>

                {activeIndex === 0 && (
                    <div className='flex-1 p-3 flex flex-col items-center justify-center lg:flex-row  gap-8'>
                        <div className='flex-1]'>
                            <img
                                src={require('../../../../assets/images/device - image.png')}
                                className='w-full h-[400px]'
                            />
                        </div>

                        <div className='flex-1 relative'>
                            <h1 className='font-medium text-[var(--primary-color)] mb-5'>
                                FOXWELL
                            </h1>

                            <p className='font-medium mb-5'>
                                1{language === 'ar' ? text.ar : text.en}
                            </p>

                            <button className='p-3 rounded-[5px] bg-[#FFE0D0] text-[var(--primary-color)] hover:bg-[#FFBAA1] duration-300'>
                                {language === 'ar' ? 'احجز الان' : 'book now !'}
                            </button>
                        </div>
                    </div>
                )}
                {activeIndex === 1 && (
                    <div className='flex-1 p-3 flex flex-col md:flex-row items-center justify-between gap-2'>
                        <div className='w-full md:w-1/2'>
                            <img
                                src={require('../../../../assets/images/device - image.png')}
                                className='w-full h-[400px]'
                            />
                        </div>

                        <div className='w-full md:w-1/2 relative'>
                            <h1 className='font-medium text-[var(--primary-color)] mb-5'>
                                FOXWELL
                            </h1>

                            <p className='font-medium mb-5'>
                                2 {language === 'ar' ? text.ar : text.en}
                            </p>

                            <button className='p-3 rounded-[5px] bg-[#FFE0D0] text-[var(--primary-color)] hover:bg-[#FFBAA1] duration-300'>
                                {language === 'ar' ? 'احجز الان' : 'book now !'}
                            </button>
                        </div>
                    </div>
                )}
                {activeIndex === 2 && (
                    <div className='flex-1 p-3 flex flex-col md:flex-row items-center justify-between gap-2'>
                        <div className='w-full md:w-1/2'>
                            <img
                                src={require('../../../../assets/images/device - image.png')}
                                className='w-full h-[400px]'
                            />
                        </div>

                        <div className='w-full md:w-1/2 relative'>
                            <h1 className='font-medium text-[var(--primary-color)] mb-5'>
                                FOXWELL
                            </h1>

                            <p className='font-medium mb-5'>
                                3 {language === 'ar' ? text.ar : text.en}
                            </p>

                            <button className='p-3 rounded-[5px] bg-[#FFE0D0] text-[var(--primary-color)] hover:bg-[#FFBAA1] duration-300'>
                                {language === 'ar' ? 'احجز الان' : 'book now !'}
                            </button>
                        </div>
                    </div>
                )}
                {activeIndex === 3 && (
                    <div className='flex-1 p-3 flex flex-col md:flex-row items-center justify-between gap-2'>
                        <div className='w-full md:w-1/2'>
                            <img
                                src={require('../../../../assets/images/device - image.png')}
                                className='w-full h-[400px]'
                            />
                        </div>

                        <div className='w-full md:w-1/2 relative'>
                            <h1 className='font-medium text-[var(--primary-color)] mb-5'>
                                FOXWELL
                            </h1>

                            <p className='font-medium mb-5'>
                                4 {language === 'ar' ? text.ar : text.en}
                            </p>

                            <button className='p-3 rounded-[5px] bg-[#FFE0D0] text-[var(--primary-color)] hover:bg-[#FFBAA1] duration-300'>
                                {language === 'ar' ? 'احجز الان' : 'book now !'}
                            </button>
                        </div>
                    </div>
                )}
                {activeIndex === 4 && (
                    <div className='flex-1 p-3 flex flex-col md:flex-row items-center justify-between gap-2'>
                        <div className='w-full md:w-1/2'>
                            <img
                                src={require('../../../../assets/images/device - image.png')}
                                className='w-full h-[400px]'
                            />
                        </div>

                        <div className='w-full md:w-1/2 relative'>
                            <h1 className='font-medium text-[var(--primary-color)] mb-5'>
                                FOXWELL
                            </h1>

                            <p className='font-medium mb-5'>
                                5 {language === 'ar' ? text.ar : text.en}
                            </p>

                            <button className='p-3 rounded-[5px] bg-[#FFE0D0] text-[var(--primary-color)] hover:bg-[#FFBAA1] duration-300'>
                                {language === 'ar' ? 'احجز الان' : 'book now !'}
                            </button>
                        </div>
                    </div>
                )}
                {activeIndex === 5 && (
                    <div className='flex-1 p-3 flex flex-col md:flex-row items-center justify-between gap-2'>
                        <div className='w-full md:w-1/2'>
                            <img
                                src={require('../../../../assets/images/device - image.png')}
                                className='w-full h-[400px]'
                            />
                        </div>

                        <div className='w-full md:w-1/2 relative'>
                            <h1 className='font-medium text-[var(--primary-color)] mb-5'>
                                FOXWELL
                            </h1>

                            <p className='font-medium mb-5'>
                                6 {language === 'ar' ? text.ar : text.en}
                            </p>

                            <button className='p-3 rounded-[5px] bg-[#FFE0D0] text-[var(--primary-color)] hover:bg-[#FFBAA1] duration-300'>
                                {language === 'ar' ? 'احجز الان' : 'book now !'}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
}
