import React from 'react'

export default function Card({ content, categories, date, image, username, userImage }) {
    const formattedDate = new Date(date)
    return (
        <div>
            <img src={image} alt='' className='w-full h-[200px] mb-5 rounded-[20px] object-fill' />

            <h3 className='text-[#8B6464] mb-3 font-medium'>{content}</h3>

            <div className='flex items-center mb-5'>

                <img src={userImage != null ? userImage : require('../../../../../assets/images/image-placeholder.png')} alt='' className='w-[40px] h-[40px] rounded-full me-4 object-fill' />

                <div>
                    <h3 className='font-medium mb-1 capitalize'>{username}</h3>
                    <p className='flex items-center text-[12px]'>

                        {formattedDate.toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric'
                        })}
                        <span className='w-[4px] h-[4px] rounded-full mx-3 bg-black inline-block'></span>
                        3 min read

                    </p>
                </div>

            </div>

            <div className='flex items-center'>

                {
                    categories.map((category) => {
                        return <p key={category.id} className='bg-[#93072A] me-3 text-white p-2 px-4 rounded-full'>{category.attributes.title}</p>
                    })
                }

            </div>

        </div>
    )
}
