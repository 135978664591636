import React, { useEffect, useState } from 'react'
import { Paginator } from 'primereact/paginator'
import { useNavigate, useSearchParams } from 'react-router-dom'
import reel from '../../assets/images/reel.png'
import play from '../../assets/icons/play.svg'
import { API } from '../../api'
const Reels = () => {
    const [reels, setReels] = useState()
    const [totalRecords, setTotalRecords] = useState()
    const [searchParams] = useSearchParams()
    let page = searchParams.get('page') || 1
    const [first, setFirst] = useState(page)
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0);
        const getReels = async () => {
            const { data } = await API.get(`reels?page=${page}`);
            setReels(data.data)
            setTotalRecords(data.meta.total)
            console.log(data.data);
        }
        getReels()
    }, [page])

    return (
        <div className='flex flex-col items-center'>
            <div className='p-6 flex gap-8 flex-wrap items-center justify-center'>
                {
                    reels?.map((item) => <a href={item.attributes.url} key={item.id} className='reel relative w-[140px] h-[260px] sm:w-[280px] sm:h-[470px] rounded-3xl overflow-hidden'>
                        <div>
                            <img src={reel} alt='cover' className='w-[140px] h-[260px] sm:w-[280px] sm:h-[470px] z-10' />
                            <div className='z-20 absolute top-3 left-3 bg-[#130707b9] p-3 rounded-[60px]'>
                                <p className='text-[#FF1716] text-xs font-bold'>{item.attributes.views} Views</p>
                            </div>
                            <div className='z-[5000] absolute bottom-[80px] sm:bottom-[100px] left-[50%] translate-x-[-50%]'>
                                <img src={play} alt='play' className='max-sm:w-8 max-sm:h-8' />
                            </div>
                            <div className='w-[120px] sm:w-[248px] flex flex-col justify-center items-center z-20 absolute bottom-3 left-[50%] translate-x-[-50%] bg-[#130707b9] py-3 rounded-[60px]'>
                                <p className='text-white tracking-widest text-[10px] sm:text-sm'>{item.attributes.title}</p>
                                <p className='text-white tracking-tight text-[6px] sm:text-xs'>{item.attributes.date} {item.attributes.time}</p>
                            </div>
                        </div>
                    </a>
                    )
                }
            </div>
            <Paginator rows={15} totalRecords={totalRecords} first={first + 1} onPageChange={(e) => {
                navigate(`?page=${e.page + 1}`)
                setFirst(e.first)
            }} />
        </div>
    )
}

export default Reels