import React from 'react'
import { RouterProvider, createBrowserRouter, Outlet } from 'react-router-dom';
import { Footer, Header, Navbar } from '../../layouts';
import WhatsAppButton from '../../components/WhatsAppButton';
import Home from "../Home"
import OurNews from "../OurNews"
import Services from "../Services"
import Products from "../Products"
import ProductDetails from "../ProductDetails"
import OurScoop from "../OurScoop"
import Login from "../../components/Login"
import ForgotPassword from "../../components/Login/ForgotPassword"
import ResetPassword from "../../components/Login/ResetPassword"
import ProductView from "../ProductView"
import ServicesView from "../ServicesView"
import Reels from "../Reels"
const CustomerFlow = () => {
    const router = createBrowserRouter([
        {
            path: '/',
            element: (
                <>
                    <Navbar />
                    <Header />
                    <Outlet />
                    <WhatsAppButton />
                    <Footer />
                </>
            ),
            children: [
                { path: '', element: <Home /> },
                { path: 'admin/login', element: <Login /> },
                { path: 'forgot-password', element: <ForgotPassword /> },
                { path: 'reset-password', element: <ResetPassword /> },
                { path: 'our-news', element: <OurNews /> },
                { path: 'services', element: <Services /> },
                { path: 'reels', element: <Reels /> },
                {
                    path: 'services/services-view',
                    element: (
                        <ServicesView
                            isDisabled={true}
                            data={{ btns: { btn1: 'Delete', btn2: 'Edit' }, type: 'view' }}
                        />
                    ),
                },
                {
                    path: 'services/edit-service',
                    element: (
                        <ServicesView
                            isDisabled={false}
                            data={{
                                btns: { btn1: 'dismiss', btn2: 'save changes' },
                                type: 'edit',
                            }}
                        />
                    ),
                },
                {
                    path: 'services/add-service',
                    element: (
                        <ServicesView
                            isDisabled={false}
                            data={{ btns: { btn1: 'Cancel', btn2: 'submit' }, type: 'add' }}
                        />
                    ),
                },
                { path: 'products', element: <Products /> },
                { path: 'products/:id', element: <ProductDetails /> },
                { path: 'products/view-product', element: <ProductView /> },
                { path: 'products/product-details', element: <ProductDetails /> },
                { path: 'about', element: <OurScoop /> },
            ]
        },
    ]);

    return (
        <RouterProvider router={router} />
    );
}

export default CustomerFlow