import React, { useState } from "react";
import loginImage from "../../assets/images/login.svg";
import logo from "../../assets/images/logo.png";
import "./login.css";
import { Link } from "react-router-dom";
import { API } from "../../api";
import Cookies from "js-cookie";
import { useLanguage } from "../../translations/LanguageContext";

const Login = () => {
  const { language } = useLanguage();
  const [status, setStatus] = useState("hide");
  const [passwordType, setPasswordType] = useState("password");

  const handleSubmit = (e) => {
    e.preventDefault();
    const emailField = document.getElementById("email");
    const passwordField = document.getElementById("password");

    API.post("auth/login", {
      email: emailField.value,
      password: passwordField.value,
    })
      .then((res) => {
        Cookies.set("token", `${res.data.authentication.token}`, {
          expires: 1 / 24,
        });
        window.localStorage.setItem("id", `${res.data.user.id}`);
        window.localStorage.setItem("name", `${res.data.user.name}`);
        window.localStorage.setItem("email", `${res.data.user.email}`);
        window.localStorage.setItem("image", `${res.data.user.image}`);
        setStatus("hide");
        window.location.replace("/admin/dashboard");
      })
      .catch(() => {
        setStatus("show");
      });
  };

  const handleShowPassword = () => {
    setPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  return (
    <React.Fragment>
      <div
        className={
          status === "hide"
            ? "alert-error-login-hide"
            : "alert-error-login-show"
        }
      >
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">
            {language === "ar" ? "خطأ!" : "Invalid Data!"}
          </strong>
        </div>
      </div>
      <div className="login-page">
        <div className="box">
          <div className="login-form">
            <div className="container box-from">
              <div className="logo">
                <img src={logo} alt="Logo Image" />
              </div>
              <div className="form-head">
                <h1>
                  {language === "ar" ? "مرحبًا مرة أخرى" : "Welcome Back"}
                </h1>
                <p>{language === "ar" ? "التحق بالفعل!" : "Hop back in!"}</p>
              </div>
              <div className="form-fileds">
                <form
                  onSubmit={handleSubmit}
                  className={`${language === "ar" ? "text-right" : "text-left"
                    }`}
                >
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="example@mail.com"
                    style={{
                      backgroundPosition: language === "ar" ? "8px" : "",
                    }}
                  />
                  <input
                    type={passwordType}
                    id="password"
                    className="password-filed"
                    name="password"
                    placeholder={
                      language === "ar"
                        ? "ادخل كلمة السر"
                        : "Enter your password"
                    }
                  />
                  <div className="show-password-box">
                    <div
                      className="item"
                      style={{
                        left: language === "ar" ? "5px" : "",
                        right: language === "ar" ? "auto" : "",
                      }}
                    >
                      <div
                        className={`icon-box ${passwordType}`}
                        id="icon-box"
                        onClick={handleShowPassword}
                      ></div>
                    </div>
                  </div>
                  <div className="w-full flex justify-end">
                    <Link to="/forgot-password" className="text-sm">
                      {language === "ar"
                        ? "هل نسيت كلمة المرور؟"
                        : "Forgot Password?"}
                    </Link>
                  </div>
                  <input
                    type="submit"
                    value={language === "ar" ? "تسجيل الدخول" : "Login"}
                  />
                </form>
              </div>
            </div>
          </div>
          <div className="logo-image">
            <img src={loginImage} alt="Login" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
