import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import expand from '../../../../assets/icons/expand.svg'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper'
import { API } from '../../../../api'
export default function Hero() {
    const [active, setActive] = useState(0)
    const [banners, setBanners] = useState()

    useEffect(() => {
        const getBanners = async () => {
            const { data } = await API.get('banners')
            console.log(data.data);
            setBanners(data.data)
        }
        getBanners()
    }, [])

    return (
        <>
            <section className='min-h-[80vh] relative flex justify-between items-center max-sm:hidden'>
                <div className='absolute left-0 top-0 w-full h-full bg-[#0000001a]'></div>
                {banners && <>
                    {active === 0 && <img src={active === 0 && banners[1].attributes.background} alt='' className='absolute left-0 top-0 w-full h-full object-fill' />}
                    {active === 1 && <img src={active === 1 && banners[2].attributes.background} alt='' className='absolute left-0 top-0 w-full h-full object-fill' />}
                    {active === 2 && <img src={active === 2 && banners[0].attributes.background} alt='' className='absolute left-0 top-0 w-full h-full object-fill' />}

                </>}

                <div className='flex-1 group' >
                    {banners && active === 0 ? <div className='container relative z-20 slide-right min-h-[80vh] flex flex-col justify-center'>

                        <h6 className='text-[var(--primary-color)] font-medium mb-5 capitalize'>{banners[1].attributes.title}</h6>

                        <h1 className='text-[50px] text-white font-bold leading-[3.5rem] mb-5'>
                            {banners[1].attributes.subtitle}
                        </h1>

                        <p className='font-medium text-[#ffffff89] mb-5'>
                            {banners[1].attributes.description}
                        </p>

                        <button className='p-3 px-8 w-fit text-[10px] text-white font-medium bg-[var(--primary-color)] rounded-full duration-300 hover:text-base hover:text-black hover:bg-white' style={{ letterSpacing: "3px" }}>
                            CONTACT NOW
                        </button>

                    </div> : <div className='container relative z-20 cursor-pointer min-h-[80vh] flex flex-col justify-center' onClick={() => { setActive(0) }}>
                        <div className=' w-full h-full flex flex-col justify-normal items-center gap-4'>
                            <img src={expand} alt='expand' className='hidden group-hover:block duration-300' />
                            <h1 className='text-white group-hover:text-2xl duration-300 tracking-[0.5rem]'>{banners && banners[1].attributes.title}</h1>
                        </div>
                    </div>}
                </div>
                <div className='flex-1 group'>
                    {banners && active === 1 ? <div className='container relative z-20 slide-right min-h-[80vh] flex flex-col justify-center'>

                        <h6 className='text-[var(--primary-color)] font-medium mb-5 capitalize'>{banners[2].attributes.title}</h6>

                        <h1 className='text-[50px] text-white font-bold leading-[3.5rem] mb-5'>
                            {banners[2].attributes.subtitle}
                        </h1>

                        <p className='font-medium text-[#ffffff89] mb-5'>
                            {banners[2].attributes.description}
                        </p>

                        <button className='p-3 px-8 w-fit text-[10px] text-white font-medium bg-[var(--primary-color)] rounded-full duration-300 hover:text-base hover:text-black hover:bg-white' style={{ letterSpacing: "3px" }}>
                            CONTACT NOW
                        </button>

                    </div> : <div className='container relative z-20 cursor-pointer min-h-[80vh] flex flex-col justify-center' onClick={() => { setActive(1) }}>
                        <div className=' w-full h-full flex flex-col justify-normal items-center gap-4'>
                            <img src={expand} alt='expand' className='hidden group-hover:block duration-300' />
                            <h1 className='text-white group-hover:text-2xl duration-300 tracking-[0.5rem]'>{banners && banners[2].attributes.title}</h1>
                        </div>
                    </div>}


                </div>
                <div className='flex-1 group'>

                    {banners && active === 2 ? <div className='container relative z-20 slide-left min-h-[80vh] flex flex-col justify-center'>

                        <h6 className='text-[var(--primary-color)] font-medium mb-5 capitalize'>{banners[0].attributes.title}</h6>

                        <h1 className='text-[50px] text-white font-bold leading-[3.5rem] mb-5'>
                            {banners[0].attributes.subtitle}
                        </h1>

                        <p className='font-medium text-[#ffffff89] mb-5'>
                            {banners[0].attributes.description}
                        </p>

                        <button className='p-3 px-8 w-fit text-[10px] text-white font-medium bg-[var(--primary-color)] rounded-full duration-300 hover:text-base hover:text-black hover:bg-white' style={{ letterSpacing: "3px" }}>
                            CONTACT NOW
                        </button>

                    </div> : <div className='container relative z-20 cursor-pointer min-h-[80vh] flex flex-col justify-center' onClick={() => { setActive(2) }}>
                        <div className='w-full h-full flex flex-col justify-normal items-center gap-4'>
                            <img src={expand} alt='expand' className='hidden group-hover:block duration-300' />
                            <h1 className='text-white group-hover:text-2xl duration-300 tracking-[0.5rem]'>{banners && banners[0].attributes.title}</h1>
                        </div>
                    </div>
                    }
                </div>
            </section>
            <section className='block sm:hidden'>
                <Swiper
                    centeredSlides={true}
                    slidesPerView={'1.7'}
                    spaceBetween={10}
                    pagination={false}
                    modules={[Pagination, Autoplay]}
                    className="h-[80vh]"
                    // autoplay={{ delay: 5000 }}
                    slideToClickedSlide={true}
                >
                    {banners && <>
                        {active === 0 && <img src={active === 0 && banners[1].attributes.background} alt='' className='absolute left-0 top-0 w-full h-full object-fill' />}
                        {active === 1 && <img src={active === 1 && banners[2].attributes.background} alt='' className='absolute left-0 top-0 w-full h-full object-fill' />}
                        {active === 2 && <img src={active === 2 && banners[0].attributes.background} alt='' className='absolute left-0 top-0 w-full h-full object-fill' />}

                    </>}
                    <SwiperSlide className='group w-full'>
                        {banners && active === 0 ? <div className='flex flex-col items-start justify-center h-full'>

                            <h6 className='text-[var(--primary-color)] font-medium mb-5 capitalize'>{banners[1].attributes.title}</h6>

                            <h1 className='text-[24px] text-white font-bold mb-5'>
                                {banners[1].attributes.subtitle}
                            </h1>

                            <p className='font-medium text-[#ffffff89] mb-5'>
                                {banners[1].attributes.description}
                            </p>

                            <button className='p-3 px-8 text-[10px] text-white font-medium bg-[var(--primary-color)] rounded-full duration-300 hover:text-base hover:text-black hover:bg-white' style={{ letterSpacing: "3px" }}>
                                CONTACT NOW
                            </button>

                        </div> : <div className=' flex flex-col items-start justify-center h-full cursor-pointer' onClick={() => { setActive(0) }}>
                            <div className=' w-full h-full flex flex-col justify-center items-start gap-4'>
                                <img src={expand} alt='expand' className='hidden group-hover:block duration-300' />
                                <h1 className='text-white group-hover:text-2xl duration-300 tracking-[0.5rem]'>{banners && banners[1].attributes.title}</h1>
                            </div>
                        </div>
                        }
                    </SwiperSlide>
                    <SwiperSlide className='group'>
                        {banners && active === 1 ? <div className='flex flex-col items-start justify-center h-full'>

                            <h6 className='text-[var(--primary-color)] font-medium mb-5 capitalize'>{banners[2].attributes.title}</h6>

                            <h1 className='text-[24px] text-white font-bold mb-5'>
                                {banners[2].attributes.subtitle}
                            </h1>

                            <p className='font-medium text-[#ffffff89] mb-5'>
                                {banners[2].attributes.description}
                            </p>

                            <button className='p-3 px-8 text-[10px] text-white font-medium bg-[var(--primary-color)] rounded-full duration-300 hover:text-base hover:text-black hover:bg-white' style={{ letterSpacing: "3px" }}>
                                CONTACT NOW
                            </button>

                        </div> : <div className=' flex flex-col items-start justify-center h-full cursor-pointer' onClick={() => { setActive(1) }}>
                            <div className=' w-full h-full flex flex-col justify-center items-start gap-4'>
                                <img src={expand} alt='expand' className='hidden group-hover:block duration-300' />
                                <h1 className='text-white group-hover:text-2xl duration-300 tracking-[0.5rem]'>{banners && banners[2].attributes.title}</h1>
                            </div>
                        </div>}

                    </SwiperSlide>

                    <SwiperSlide className='group'>
                        {banners && active === 2 ? <div className='flex flex-col items-start justify-center h-full'>

                            <h6 className='text-[var(--primary-color)] font-medium mb-5 capitalize'>{banners[0].attributes.title}</h6>

                            <h1 className='text-[24px] text-white font-bold mb-5'>
                                {banners[0].attributes.subtitle}
                            </h1>

                            <p className='font-medium text-[#ffffff89] mb-5'>
                                {banners[0].attributes.description}
                            </p>

                            <button className='p-3 px-8 text-[10px] text-white font-medium bg-[var(--primary-color)] rounded-full duration-300 hover:text-base hover:text-black hover:bg-white' style={{ letterSpacing: "3px" }}>
                                CONTACT NOW
                            </button>

                        </div> : <div className=' flex flex-col items-start justify-center h-full cursor-pointer' onClick={() => { setActive(2) }}>
                            <div className=' w-full h-full flex flex-col justify-center items-start gap-4'>
                                <img src={expand} alt='expand' className='hidden group-hover:block duration-300' />
                                <h1 className='text-white group-hover:text-2xl duration-300 tracking-[0.5rem]'>{banners && banners[0].attributes.title}</h1>
                            </div>
                        </div>}

                    </SwiperSlide>

                </Swiper>
            </section>

            <a href='https://wa.me/01111111111' className='bg-[var(--primary-color)] flex items-center justify-center text-white rounded-full w-12 h-12 sm:w-[64px] sm:h-[64px] cursor-pointer fixed right-5 bottom-5 z-[100]' target='_blank'>
                <FontAwesomeIcon icon={faWhatsapp} className='w-full h-full' />
            </a>
        </>
    )
}