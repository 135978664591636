import facebook from "../../assets/icons/facebook-icon.svg";
import insta from "../../assets/icons/instagram-icon.svg";
import whats from "../../assets/icons/whatsapp-icon.svg";

export const socialLinks = [
  {
    href: "https://www.facebook.com/Aladelbatteries?mibextid=ZbWKwL",
    classes:
      "w-[20px] h-[20px] rounded-full bg-white me-3 flex items-center justify-center",
    icon: facebook,
  },
  {
    href: "https://wa.me/01271668110",
    classes:
      "w-[20px] h-[20px] rounded-full bg-white me-3 flex items-center justify-center",
    icon: whats,
  },
  {
    href: "https://instagram.com/al_adel_automotive_batteries?utm_source=qr",
    classes:
      "w-[20px] h-[20px] rounded-full bg-white me-3 flex items-center justify-center",
    icon: insta,
  },
];
