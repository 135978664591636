import React, { useState, useEffect } from 'react'
import eye from '../../assets/icons/eye.svg'
import edit from '../../assets/icons/edit.svg'
import trash from '../../assets/icons/trash.svg'
import { Link } from 'react-router-dom'
import { API } from '../../api'
const Services = () => {
    const [services, setServices] = useState([])
    useEffect(() => {
        const getServices = async () => {
            const { data } = await API.get('services')
            setServices(data.data)
        }
        getServices()
    }, [])
    const deleteService = async (id) => {
        const { data } = await API.delete(`services/${id}`)
        console.log(data);
    }
    let content = services?.map((service) => <div key={service.id} className='rounded-xl overflow-hidden'>
        <img src={service.attributes.image} alt={service.attributes.title} className='w-[200px] h-[200px]' />
        <h3 className='text-md text-[#050406]'>{service.attributes.title}</h3>
        <p className='text-sm text-[#4B465C] my-2'>{service.attributes.description}</p>
        <div className='flex justify-between'>
            <div className='flex gap-1'>
                <Link to={`services-view/${service.id}`} className='w-6 h-6 rounded-full bg-[#8B6464] flex justify-center items-center cursor-pointer'><img src={eye} alt='show' /></Link>
                <Link to={`services-edit/${service.id}`} className='w-6 h-6 rounded-full bg-[#8B6464]  flex justify-center items-center cursor-pointer'><img src={edit} alt='edit' /></Link>
            </div>
            <div>
                <div className='w-6 h-6 rounded-full bg-[#FF6050]  flex justify-center items-center cursor-pointer' onClick={() => {
                    deleteService(service.id)
                }}><img src={trash} alt='delete' /></div>
            </div>
        </div>
    </div>)
    return (
        <div className='flex flex-wrap gap-4'>
            {content}
        </div>
    )
}

export default Services