import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useLanguage } from "../../../../translations/LanguageContext";

const CategoryCard = ({ item, index, language }) => (
  <div
    key={index}
    className="flex-1 relative rounded-[30px] overflow-hidden hover:drop-shadow-xl hover:scale-105 hover:ease-in-out hover:duration-300"
  >
    <img
      src={require(`../../../../assets/images/categ-${item.img}.png`)}
      alt=""
      className="w-full h-[334px] object-fill"
    />

    <div className="bg-[#f9f1f1] rounded-bl-[20px] rounded-br-[20px] p-8">
      <h5
        className=" mb-5 uppercase font-bold"
        style={{ letterSpacing: "3px" }}
      >
        {language === "ar" ? item.ar : item.en}
      </h5>

      <p className="mb-5">
        {language === "ar"
          ? "كلام تجريبي"
          : "Quo quod amet error molestiae sed neque et."}
      </p>

      <p className="flex items-center justify-between text-[13px] font-medium text-[var(--primary-color)]">
        <span style={{ letterSpacing: "3px" }}>
          {language === "ar" ? "مشاهدة البطاريات" : "Show Batteries"}
        </span>

        <FontAwesomeIcon
          icon={language === "ar" ? faArrowLeft : faArrowRight}
          className="cursor-pointer"
        />
      </p>
    </div>
  </div>
);

const CategoryCardList = () => {
  const categories = [
    { en: "passengers cars", ar: "سيارات الركاب", img: "one" },
    { en: "heavy vehicles", ar: "الشاحنات كبيرة", img: "two" },
    { en: "motorcycles", ar: "الدراجات", img: "three" },
  ];
  const { language } = useLanguage();

  return (
    <div className="flex gap-8 w-full max-md:flex-col">
      {categories.map((item, index) => (
        <CategoryCard
          key={index}
          item={item}
          index={index}
          language={language}
        />
      ))}
    </div>
  );
};

export default function Categories() {
  const { language } = useLanguage();

  return (
    <div className="my-10 flex flex-col items-center">
      <div className="flex gap-8 px-8 max-[930px]:flex-col ">
        <div className="w-[280px] flex items-center justify-center flex-col relative rounded-[30px] overflow-hidden p-8 py-20 max-lg:w-full">
          <img
            src={require("../../../../assets/images/categ-banner.png")}
            alt=""
            className="absolute left-0 top-0 w-full h-full object-fill"
          />

          <div className="relative z-20">
            <h6
              className="text-[var(--primary-color)] font-medium mb-5"
              style={{ letterSpacing: "3px" }}
            >
              {language === "ar" ? "رائد" : "Leader"}
              <br />
              {language === "ar" ? "في التصنيع" : "in manufacturing"}
            </h6>

            <h1 className="text-[40px] mb-5 font-medium text-white">
              {language === "ar"
                ? "البطاريات الصناعية و السيارات"
                : "Industrial and Automotive Batteries"}
            </h1>

            <p className="flex items-center justify-between text-[10px] text-[#FFE0D0] cursor-pointer hover:text-[#FF1716]">
              <span
                className="hover:text-[#FF1716] hover:text-xl hover:drop-shadow-lg hover:ease-in-out hover:duration-300"
                style={{ letterSpacing: "3px" }}
              >
                {language === "ar" ? "البطاريات لدينا في" : "OUR BATTERIES IN"}
              </span>

              <FontAwesomeIcon
                icon={language === "ar" ? faArrowLeft : faArrowRight}
                className="cursor-pointer hover:text-[#FF1716]"
              />
            </p>
          </div>
        </div>
        <div className="flex-1 flex gap-2">{CategoryCardList()}</div>
      </div>
    </div>
  );
}
