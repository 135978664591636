import React from "react";
import { useLanguage } from "../../../../translations/LanguageContext";

const brands = [
  { name: "", imageSrc: "one" },
  { name: "", imageSrc: "two" },
  { name: "", imageSrc: "three" },
  { name: "", imageSrc: "four" },
  { name: "", imageSrc: "five" },
  { name: "", imageSrc: "six" },
  { name: "", imageSrc: "seven" },
  { name: "", imageSrc: "eight" },
  { name: "", imageSrc: "nine" },
];

const renderBrands = brands.map((item, index) => {
  return (
    <div
      key={index}
      className={`flex items-center justify-center overflow-hidden col-span-4 h-[164px] relative rounded-[50px]`}
    >
      <img
        src={require(`./images/${item.imageSrc}.png`)}
        alt=""
        className=" w-full h-full object-fill"
      />
    </div>
  );
});

export default function OurReels() {
  const { language } = useLanguage();

  return (
    <section className="flex flex-col-reverse sm:flex-row items-center gap-5 my-10">
      <div className="flex-1">
        <h1 className="capitalize font-medium text-[60px] text-[#FF1716]">
          {language === "ar" ? "مشاهداتنا" : "Our Reels"}
        </h1>

        <p className="mb-3 font-medium">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio
          cing elit. Nunc o cing elit. Nunc ocing elit. Nunc o in et, lectus sit
          lorem id integer.
        </p>
      </div>

      <div className="flex-1 grid grid-cols-12 gap-3">{renderBrands}</div>
    </section>
  );
}
