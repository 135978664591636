import React from "react";
import { LandingPage } from "../../components";
import { Categories, OurClients, OurPartners, OurReels } from "./components";
import { useLanguage } from "../../translations/LanguageContext";

export default function OurScoop() {
  const { language } = useLanguage();

  return (
    <div>
      <LandingPage
        title={language === "ar" ? "من نحن؟" : "who we are ?"}
        pText={
          language === "ar"
            ? "ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي نص تجريبي"
            : "Omnis in consectetur harum. Reiciendis architecto quia eos. Dolorem dolorum repellat. Molestiae exercitationem amet molestiae minus. Natus qui occaecati dolores perferendis."
        }
        btnText={language === "ar" ? "مشاهدة الاخبار" : "See News"}
      />

      <div className="px-8">
        <h1 className="text-center font-bold capitalize mb-3 text-[#FF1716] text-[60px]">
          {language === "ar" ? "اهتمامتنا" : "Our Scope"}
        </h1>

        <p className="text-[#707070] font-medium mb-6 text-center">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio in
          et, lectus sit lorem id integer.
        </p>

        <Categories />

        <OurPartners />

        <OurReels />

        <OurClients />
      </div>
    </div>
  );
}
