import { useEffect, useState } from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import cars from '../../../../assets/images/cars.png';
import heavy from '../../../../assets/images/heavy.png';
import motor from '../../../../assets/images/motor.png';
import { API } from '../../../../api';
import { useLanguage } from '../../../../translations/LanguageContext';
import { useSearchParams } from 'react-router-dom';
export default function FilterProductPanel({ classNames, setProducts }) {
  const { language } = useLanguage();
  const [searchParams] = useSearchParams()
  const vechile = searchParams.get('filter')
  const [vechileType, setVechileType] = useState(vechile);

  const [carTypes, setCarTypes] = useState();
  const [selectedCarType, setSelectedCarType] = useState();

  const [carBrands, setCarBrands] = useState();
  const [selectedCarBrand, setSelectedCarBrand] = useState();

  const [carModels, setCarModels] = useState();
  const [selectedCarModel, setSelectedCarModel] = useState();

  const [minValue, setMinValue] = useState();
  const [maxValue, setMaxValue] = useState();
  const [search, setSearch] = useState();

  const getCarsTypes = async () => {
    const { data } = await API.get('products/fetch/car-types');
    setCarTypes(data.data);
  };
  const getBrands = async (selectedCarType) => {
    const { data } = await API.post('products/data/filter', {
      vehicle_type: vechileType,
      car_type_id: selectedCarType,
    });
    console.log('brand', data);
    setCarBrands(data.data);
  };

  const getCarModels = async (selectedCarBrand) => {
    const { data } = await API.post('products/data/filter', {
      vehicle_type: vechileType,
      car_type_id: selectedCarType,
      brand_id: selectedCarBrand,
    });
    console.log('model', data);
    setCarModels(data.data);
  };
  const getProducts = async () => {
    if (vechileType && selectedCarBrand && selectedCarModel) {
      const { data } = await API.post('products/data/filter', {
        vehicle_type: vechileType,
        car_type_id: selectedCarType,
        brand_id: selectedCarBrand,
        car_id: selectedCarModel,
      });
      console.log(data);
      setProducts(data.data);
    }
  };
  useEffect(() => {
    getCarsTypes();
  }, [vechileType]);
  return (
    <aside className={`${classNames}`}>
      <div className='p-3 shadow-md rounded-[15px]'>
        <div className='flex items-center justify-between mb-3'>
          <h2 className='font-medium text-[25px]'>
            {language === 'ar' ? 'تصفية' : 'Filter'}
          </h2>
          <button
            onClick={() => {
              setVechileType(null);
            }}
          >
            {language === 'ar' ? 'حذف الجميع' : 'Clear All'}
          </button>
        </div>

        <div className='relative mb-5'>
          <FontAwesomeIcon
            icon={faSearch}
            className='absolute left-3 top-[50%] translate-y-[-50%] text-black z-10'
          />

          <input
            className='bg-[#F9F1F1] text-black w-full ps-[15px] pe-3 h-[45px] rounded-[5px] indent-[25px] relative'
            type='search'
            placeholder={language === 'ar' ? 'بحث' : 'Search'}
            aria-label='Search'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div>
          <div>
            <h2 className='font-medium text-[#130707] text-[16px]'>
              {language === 'ar' ? 'نوع المركبة' : 'TYPE OF RIDE'}
            </h2>

            <div className='flex justify-between gap-2 my-4 '>
              {(vechileType === 'passenger' || vechileType === null) && <div
                className='relative cursor-pointer'
                onClick={() => {
                  setVechileType('passenger');
                }}
              >
                <img src={cars} alt='cars' />
                <div className='flex justify-center items-center font-bold text-center text-white bg-[#FF1716] rounded-[18px] px-[6px] py-[5px] absolute bottom-2 left-[50%] translate-x-[-50%]'>
                  {language === 'ar' ? 'سيارة' : 'Car'}
                </div>
              </div>}
              {(vechileType === 'heavy' || vechileType === null) && <div
                className='relative cursor-pointer'
                onClick={() => {
                  setVechileType('heavy');
                }}
              >
                <img src={heavy} alt='heavy' />
                <div className='flex justify-center items-center font-bold text-center text-white bg-[#FF1716] rounded-[18px] px-[6px] py-[5px] absolute bottom-2 left-[50%] translate-x-[-50%]'>
                  {language === 'ar' ? 'شاحنة' : 'Heavy'}
                </div>
              </div>}
              {(vechileType === 'motor' || vechileType === null) && <div
                className='relative cursor-pointer'
                onClick={() => {
                  setVechileType('motor');
                }}
              >
                <img src={motor} alt='motor' />
                <div className='flex justify-center items-center font-bold text-center text-white bg-[#FF1716] rounded-[18px] px-[6px] py-[5px] absolute bottom-2 left-[50%] translate-x-[-50%]'>
                  {language === 'ar' ? 'دراجة' : 'Motor'}
                </div>
              </div>}
            </div>
          </div>

          <div className='flex items-center justify-between mb-3'>
            <h2 className='text-black font-medium text-[16px]'>
              {language === 'ar' ? 'نوع السيارة' : 'Style of car'}
            </h2>

            {/* <h2 className='text-[#AA2355] font-medium cursor-pointer underline'>Clear ALL</h2> */}
          </div>
          <div className='max-h-[140px] overflow-y-auto'>
            {carTypes?.map((carStyle) => {
              return (
                <div
                  key={carStyle.id}
                  className='flex items-center mb-1 py-1 px-2 cursor-pointer'
                >
                  <RadioButton
                    inputId={carStyle.id}
                    name='carStyle'
                    value={carStyle.id}
                    onChange={(e) => {
                      setSelectedCarType(e.value);
                      setCarBrands(null);
                      setSelectedCarBrand(null);
                      setCarModels(null);
                      setSelectedCarModel(null);
                      getBrands(e.value);
                    }}
                    checked={selectedCarType === carStyle.id}
                  />

                  <label
                    htmlFor={carStyle.id}
                    className='ml-2 cursor-pointer text-[13px] w-full text-black flex items-center justify-between'
                  >
                    <p>{carStyle.attributes.name}</p>
                    <img
                      src={
                        carStyle.attributes.image || require('./iamges/car.png')
                      }
                      alt='car_thumbnail'
                      className='w-[60px]'
                    />
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div className='my-5'>
          <div className='flex items-center justify-between mb-3'>
            <h2 className='text-black capitalize font-medium text-[16px]'>
              {language === 'ar' ? 'ماركة' : 'Brand'}
            </h2>

            {/* <h2 className='text-[#AA2355] font-medium cursor-pointer underline'>Clear ALL</h2> */}
          </div>
          <div className='max-h-[140px] overflow-y-auto'>
            {(!carBrands || carBrands.length === 0) && (
              <p>
                {language === 'ar'
                  ? 'لا توجد ماركات سيارات مطابقة لهذا النوع'
                  : 'There is no matching car brands with this type'}
              </p>
            )}
            {carBrands?.map((brand) => {
              return (
                <div
                  key={brand.id}
                  className='flex items-center mb-1 py-1 px-2 cursor-pointer'
                >
                  <RadioButton
                    inputId={brand.id}
                    value={brand.id}
                    onChange={(e) => {
                      setSelectedCarBrand(e.value);
                      setCarModels(null);
                      setSelectedCarModel(null);
                      getCarModels(e.value);
                    }}
                    checked={selectedCarBrand === brand.id}
                  />

                  <label
                    htmlFor={brand.id}
                    className='ml-2 cursor-pointer w-full text-[13px] text-black flex items-center justify-between'
                  >
                    <p>{brand.attributes.name}</p>
                    <img
                      src={
                        brand.attributes.image || require('./iamges/car.png')
                      }
                      alt='car_thumbnail'
                      className='w-[60px]'
                    />
                  </label>
                </div>
              );
            })}
          </div>
          <div className='my-5'>
            <div className='flex items-center justify-between mb-3'>
              <h2 className='text-black capitalize font-medium text-[16px]'>
                {language === 'ar' ? 'نموذج' : 'Model'}
              </h2>
            </div>
            <div className='max-h-[140px] overflow-y-auto'>
              {!carModels || carModels.length === 0 ? (
                <p>
                  {language === 'ar'
                    ? 'لا توجد موديلات سيارات مطابقة'
                    : 'There is no matching car models'}
                </p>
              ) : (
                carModels?.map((model) => {
                  return (
                    <div
                      key={model.attributes.name + new Date()}
                      className='flex items-center mb-1 py-1 px-2 cursor-pointer'
                    >
                      <RadioButton
                        inputId={model.id}
                        name='model'
                        value={model.id}
                        onChange={(e) => {
                          setSelectedCarModel(e.value);
                        }}
                        checked={selectedCarModel === model.id}
                      />

                      <label
                        htmlFor={model.id}
                        className='ml-2 cursor-pointer w-full text-[13px] text-black flex items-center justify-between'
                      >
                        <p>{model.attributes.name}</p>
                        <img
                          src={
                            model.attributes.image ||
                            require('./iamges/car.png')
                          }
                          alt='car_thumbnail'
                          className='w-[60px]'
                        />
                      </label>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>

        <div className='my-5'>
          <div className='flex items-center justify-between'>
            <h2 className='text-black capitalize font-medium text-[16px]'>
              {language === 'ar' ? 'سعر' : 'Price'}
            </h2>
          </div>

          <div className='flex items-center justify-between my-3'>
            <input
              type='number'
              placeholder={language === 'ar' ? '$ اقل.' : '$ Min.'}
              value={minValue}
              onChange={(e) => setMinValue(e.target.value)}
              className='bg-[#F9F1F1] w-[48%] p-2 rounded-[5px] text-black '
            />
            <input
              type='number'
              placeholder={language === 'ar' ? '$ اكثر.' : '$ Max.'}
              value={maxValue}
              onChange={(e) => setMaxValue(e.target.value)}
              className='bg-[#F9F1F1] w-[48%] p-2 rounded-[5px] text-black '
            />
          </div>

          <div className='flex items-center justify-between !mt-5'>
            <button className='min-btn text-[11px] sm:text-[16px] lg:text-[14px] !py-2 !px-0 w-[40%] !rounded-[5px] !bg-transparent border border-[#26303B] me-2'>
              {language === 'ar' ? ' حذف الجميع' : 'Clear All'}
            </button>
            <button
              onClick={getProducts}
              className='min-btn text-[11px] sm:text-[16px] lg:text-[14px] !py-2 !px-0 w-[48%] !rounded-[5px] bg-[var(--primary-color)] text-white border border-[#AA2355]'
            >
              {language === 'ar' ? 'تطبيق' : 'Apply Filters'}
            </button>
          </div>
        </div>
      </div>
    </aside >
  );
}
