import { Link } from "react-router-dom";
import { useLanguage } from "../../translations/LanguageContext";

// this component render and darw group of  links to set it to ui
export default function RelatedLinks({ list, target }) {
  const { language } = useLanguage();
  console.log(language);
  console.log(list);

  return list.map((link, index) => {
    return link.type === "anchor" ? (
      <a
        key={index + 1}
        href={link.href}
        className={link.classes}
      // target={target}
      >
        {language === "ar" ? link.text.ar : link.text.en}
      </a>
    ) : (
      <Link
        key={index + 1}
        to={link.href}
        className={link.classes}
      // target={target}
      >
        {language === "ar" ? link.text.ar : link.text.en}
      </Link>
    );
  });
}
