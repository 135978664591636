import React, { useState } from "react";
import { API } from "../../api";
import { useLanguage } from "../../translations/LanguageContext";

export default function Newsletter() {
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);
  const { language } = useLanguage();
  const submitEmailHandler = async () => {
    const { data } = await API.post("be/subscribers", { email: email });
    console.log(data);
    setShow(data.message);
    setTimeout(() => {
      setShow(false);
    }, 3000);
    setEmail("");
  };
  return (
    <div className="bg-[#160e0e] p-5 py-20 my-20 relative">
      <div className="flex items-center justify-center flex-col mb-8">
        <h2 className="text-white font-medium text-[30px] mb-5">
          {language === "ar" ? "النشرة الإخبارية" : "Newsletter"}
        </h2>
        <p className="text-white lg:w-[40%] text-center mb-5">
          {language === "ar"
            ? "كن أول من يعلم بالخصومات والعروض والفعاليات الأسبوعية في صندوق بريدك. قم بإلغاء الاشتراك وقتما تشاء بنقرة واحدة."
            : "Be the first one to know about discounts, offers and events weekly in your mailbox. Unsubscribe whenever you like with one click."}
        </p>
      </div>

      <div className="flex flex-col sm:flex-row items-center justify-center gap-3">
        <input
          type="text"
          placeholder={
            language === "ar" ? "ادخل البريد الإلكتروني" : "Enter your email"
          }
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="rounded-[5px] p-2 px-5 w-full sm:w-[320px] bg-[#4d3232] text-white placeholder:text-[#CFCFCF]"
        />
        <button
          className="bg-[#db1020] p-2 px-8 w-full sm:w-fit text-white rounded-[5px]"
          onClick={submitEmailHandler}
        >
          {language === "ar" ? "اشترك" : "Subscribe"}
        </button>
      </div>
      {show && (
        <p className="bg-green text-white py-2 px-4 absolute top-0 right-0">
          {show}
        </p>
      )}
    </div>
  );
}
