// Import the necessary FontAwesome icons and components
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import the socialLinks data and the  and getLanguages functions from the APIs file
import { socialLinks } from "./data";
import { useLanguage } from "../../translations/LanguageContext";

// Define a React component called Navbar
export default function Navbar() {
  const { language } = useLanguage();

  const SocialLink = ({ href, classes, icon }) => (
    <a
      href={href}
      className={classes}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={icon} alt="Social Icon" className="w-full h-full" />
    </a>
  );

  // Render the navbar
  return (
    // Render a nav element with class 'flex bg-[var(--primary-color)] h-[48px] px-[10px] md:px-[40px] lg:px-[60px]'
    <nav className="flex bg-[var(--primary-color)] h-[48px] px-[10px] md:px-[40px] lg:px-[32px] relative">
      {/* Render a div with class 'w-full flex justify-between items-center' */}
      <div className="w-full flex justify-between items-center ">
        {/* Render a div with class 'flex items-center' */}
        <div className="flex items-center">
          {/* Render the RelatedLinks component */}
          {socialLinks.map((link, index) => (
            <SocialLink key={index} {...link} />
          ))}
        </div>

        {/* Render a div with class 'hidden md:flex items-center text-white font-[400]' */}
        <div className="hidden md:block text-white font-[400]">
          {/* Render a paragraph with class 'me-4 text-[13px] lg:text-[16px]' */}
          <p className="me-4 text-[13px] lg:text-[16px]">
            {/* Render the faLocationDot icon */}
            <FontAwesomeIcon
              icon={faLocationDot}
              className="me-2"
            ></FontAwesomeIcon>
            {/* Render the address */}
            {language === "ar"
              ? "9 شارع حفص شارع شركة بطاريات النسر الإبراهيمية الإسكندرية"
              : "9 Hafs street, Al-Nesr Battery Company, Al-Ibrahimia, Alexandria"}
          </p>
        </div>

        {/* Render a paragraph with class 'me-4 text-[13px] lg:text-[16px]' */}
        <p className="text-[13px] text-white lg:text-[16px]">
          {/* Render the faLocationDot icon */}
          <FontAwesomeIcon icon={faPhone} className="me-4"></FontAwesomeIcon>
          {/* Render the Phone */}
          605-646-9241
        </p>
      </div>
    </nav>
  );
}
