import React from "react";
import { Link } from "react-router-dom";
import { ProductsCard, SwiperWrapper } from "../../../../components";
import { SwiperSlide } from "swiper/react";

const items = (relatedProducts) =>
  relatedProducts.map((item, index) => {
    return (
      <SwiperSlide key={index}>
        <ProductsCard product={item} />
      </SwiperSlide>
    );
  });

export default function RelatedProducts({ product }) {
    console.log(product)
  return (
    <>
      {product && (
        <section className="my-20">
          <div className="flex items-center justify-between mb-5">
            <h1 className="bg-[#FF6050] font-medium mb-5 text-white p-2 w-fit rounded-[5px] capitalize">
              related products
            </h1>

            {/* <Link to={""} className="font-medium text-[#8B6464] text-[20px]">
              See All Article
            </Link> */}
          </div>

          <SwiperWrapper
            classNames={"my-8"}
            items={items(product?.related)}
            isLooped={true}
            slidesPerViewCount={[1.3, 2, 3, 4.3]}
            autoplayDelay={3000}
          />
        </section>
      )}
    </>
  );
}
