import React from "react";
import { SwiperWrapper } from "../../../../components";
import { items, renderDetails } from "./data";

export default function ProductFeatures({ product }) {
  console.log(product);
  return (
    <>
      {product && (
        <div className="grid grid-cols-12 lg:gap-10 mb-20">
          <div className="col-span-9 max-lg:col-span-12">
            <div className="flex items-center">
              <div className="w-[78px] h-[78px] rounded-full flex items-center justify-center me-5">
                <img
                  src={
                    product?.attributes?.brand_image
                      ? product.attributes.brand_image
                      : ""
                  }
                  alt=""
                  className="w-full h-full object-fill"
                />
              </div>

              <div>
                <h3 className="font-medium text-[16px]">
                  {product.attributes.name}
                </h3>

                <h1 className="font-medium text-[#8B6464] text-[20px]">
                  {product.attributes.brand_name}
                </h1>
              </div>
            </div>

            <p className="font-medium my-5 opacity-50 text-[#130707] text-[18px]">
              {product.attributes.description}
            </p>

            <button className="bg-[#DB1020] rounded-[5px] capitalize text-white font-medium p-2 px-10">
              get it now{" "}
            </button>

            <div className="flex gap-3 my-8">
              <div className="bg-[#FF1716] font-bold flex items-center justify-center text-white w-[128px] h-[128px] min-w-[128px] min-h-[128px] rounded-full capitalize">
                <span className="text-lg">Premium with</span>
              </div>
              <SwiperWrapper
                items={items}
                isLooped={true}
                slidesPerViewCount={[3, 4, 5, 7.5]}
                autoplayDelay={3000}
              />
            </div>
          </div>

          <div className="col-span-3 bg-[#FFEBD9] rounded-[40px] px-[39px] py-[38px] h-[377px] flex flex-col justify-center items-start gap-3 relative z-0 max-lg:col-span-12 max-lg:justify-start max-lg:h-max">
            <div className="flex items-center justify-between w-full ">
              <button className="bg-[#FF1716] rounded-[5px] text-white font-medium p-1 px-2">
                {product.attributes.compaar}
              </button>

              <p className="after:bg-white after:h-1 after:rounded-md after:w-full after:absolute after:left-0 after:bottom-[50%] after:z-[-1]   rounded-[5px] text-sm font-medium absolute right-6 top-3">
                {product.attributes.price}
                <span> EGP</span>
              </p>
            </div>

            <img
              src={
                product?.attributes?.car_image
                  ? product.attributes.car_image
                  : ""
              }
              alt=""
              className="h-[55px]"
            />

            <div className="flex items-center flex-wrap gap-1">
              {renderDetails}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
