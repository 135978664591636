import React, { useState, useEffect } from 'react';
import imagePlaceholder from '../../assets/images/image-placeholder.png';
import upload from '../../assets/icons/upload.svg';
import arrow from '../../assets/icons/drop-arrow.svg';
import xIcon from '../../assets/icons/x.svg';
import { useNavigate } from 'react-router-dom';
import { API } from '../../api';

const AddBlog = () => {
    const navigate = useNavigate();
    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [image, setImage] = useState();
    const [categories, setCategories] = useState();
    const [showCategories, setShowCategories] = useState(true);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const toggleShowCategoriesHandler = () => {
        setShowCategories((prev) => !prev);
    };

    const addCategoryHandler = (cat) => {
        let index = selectedCategories?.findIndex(
            (category) => category.id === cat.id
        );
        if (index >= 0) {
            return;
        } else {
            setSelectedCategories((prev) => [cat, ...prev]);
        }
    };

    const deleteCategoryHandler = (cat) => {
        let updatedSelectedCategories = selectedCategories.filter(
            (category) => category.id !== cat.id
        );
        setSelectedCategories(updatedSelectedCategories);
    };

    const addBlogHandler = async () => {
        const formData = new FormData();
        selectedCategories.forEach((cat, index) => formData.append(`category_ids[${index}]`, parseInt(cat.id)));
        formData.append('title', title);
        formData.append('content', description);
        formData.append('description', description);
        formData.append('keywords', title);
        formData.append('image', image);
        const { data } = await API.post(
            'posts',
            formData,
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        console.log(data);
    };

    useEffect(() => {
        const getCategories = async () => {
            const { data } = await API.get(
                'categories'
            );
            setCategories(data.data);
        };
        getCategories();
    }, []);

    return (
        <section className='max-sm:w-[260px]'>
            <div className='px-4 flex max-sm:flex-col justify-center gap-6'>
                <div
                    className={`blog-image-shadow rounded-xl max-sm:w-[228px] flex flex-col items-center justify-center ${image ? 'pb-6' : 'p-6'
                        }`}
                >
                    <img
                        src={image ? URL.createObjectURL(image) : imagePlaceholder}
                        alt='avatar'
                        className={`${image
                            ? 'h-full rounded-t-xl max-w-[228px]'
                            : 'h-[140px] w-[140px]'
                            }`}
                    />
                    <label htmlFor='image' className='flex flex-col items-center '>
                        {!image && (
                            <p className='sm:text-2xl font-bold mt-4 cursor-pointer'>
                                Upload image here
                            </p>
                        )}
                        <img src={upload} alt='upload' className='cursor-pointer mt-4' />
                    </label>
                    <input
                        type='file'
                        name='image'
                        id='image'
                        hidden
                        onChange={(e) => setImage(e.target.files[0])}
                    />
                </div>
                <div className='rounded-xl blog-shadow p-6 flex-1'>
                    <h3 className='text-[#8B6464] text-[18px] font-semibold mb-6'>
                        Add Blog
                    </h3>
                    <form className='flex flex-col gap-[19px]'>
                        <div className='flex flex-col'>
                            <label className='text-[13px] text-[#8B6464] mb-1'>
                                Blog name
                            </label>
                            <input
                                type='text'
                                onChange={(e) => setTitle(e.target.value)}
                                className='border border-[#F0E2E2] rounded-md py-2 px-4 text-[20px]'
                            />
                        </div>
                        <div className='flex flex-col'>
                            <label className='text-[13px] text-[#8B6464] mb-1'>
                                Description
                            </label>
                            <textarea
                                type='text'
                                rows={3}
                                onChange={(e) => setDescription(e.target.value)}
                                className='border border-[#F0E2E2] rounded-md py-2 px-4 text-[14px] opacity-70 resize-none'
                            />
                        </div>
                        <div className='flex max-sm:flex-col gap-2'>
                            <div className='flex flex-col '>
                                <p className='text-[13px] text-[#8B6464] mb-1'>Categories</p>
                                <div className='flex flex-col gap-2 rounded-md border border-[#F0E2E2] p-4 max-h-[160px] w-[200px] max-sm:w-full'>
                                    <div className='flex justify-between '>
                                        <p>Select</p>
                                        <img
                                            src={arrow}
                                            alt='arrow'
                                            className={`cursor-pointer ${showCategories ? '' : 'rotate-180'
                                                }`}
                                            onClick={toggleShowCategoriesHandler}
                                        />
                                    </div>
                                    {showCategories && (
                                        <div className='flex flex-col gap-2 items-start h-[96px] overflow-y-auto'>
                                            {categories?.map((category) => (
                                                <button
                                                    key={category.id}
                                                    type='button'
                                                    onClick={() => {
                                                        addCategoryHandler(category);
                                                    }}
                                                    className='flex items-center gap-1'
                                                >
                                                    <p className='font-bold text-xl'>+</p>
                                                    <p>{category.attributes.title}</p>
                                                </button>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='flex-1'>
                                <p className='text-[13px] text-[#8B6464] mb-1'>Box</p>
                                <div className='border border-[#F0E2E2] rounded-md flex flex-wrap gap-2 p-4 h-[160px] overflow-y-auto'>
                                    {selectedCategories?.map((category) => (
                                        <div
                                            key={category.id}
                                            className='flex items-center justify-center gap-[10px] w-fit h-fit category-shadow rounded-[33px] py-1 px-2'
                                        >
                                            <p className='text-md'>{category.attributes.title}</p>
                                            <button
                                                type='button'
                                                onClick={() => {
                                                    deleteCategoryHandler(category);
                                                }}
                                            >
                                                <img src={xIcon} alt='delete' />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className='flex max-sm:flex-col gap-2 mt-4'>
                        <button
                            onClick={() => navigate('/blogs')}
                            className='text-[#FF1716] bg-[#FFE0D0] py-4 px-8 rounded-lg'
                        >
                            Cancel
                        </button>
                        <button
                            onClick={addBlogHandler}
                            className='text-white bg-[#FF1716] py-4 px-8 rounded-lg flex-1'
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AddBlog;
