import React, { Fragment, useEffect } from 'react'
import "./index.css"
import MenuHeader from './MnueHeader/MenuHeader'
import MenuMainContent from './MnueMainContent/MenuMainContent';


export default function MainPanel({ isExpanded, setIsExpanded }) {

    // ** Function to handle window resize
    const hendlResize = () => {

        if (window.innerWidth <= 1024) {

            setIsExpanded(false);

        } else {

            setIsExpanded(true);

        }
    }


    useEffect(() => {

        window.addEventListener('resize', hendlResize);

        hendlResize();

        return () => window.removeEventListener('resize', hendlResize);

    }, []);

    return (
        <Fragment>

            <div className={`main-menu bg-white menu-fixed menu-accordion ${isExpanded ? "expanded" : ""}`} >

                <MenuHeader isExpanded={isExpanded} setIsExpanded={setIsExpanded}></MenuHeader>

                <MenuMainContent isExpanded={isExpanded}></MenuMainContent>

            </div>

        </Fragment>
    )
}
