import { Link } from 'react-router-dom'

export default function HeaderLogo() {
    return (

        <div className="col-span-1 lg:flex lg:gap-x-12 lg:justify-center lg:items-center">

            <Link to="/">

                <span className="sr-only">Your Compaar</span>

                <img src={require('../../../assets/images/logo.png')} />

            </Link>

        </div>

    )
}
