import FooterContent from './FooterContent/FooterContent';
import FooterLinks from './FooterLinks/FooterLinks';
import { useLanguage } from '../../translations/LanguageContext';

const images = [
    'Mastercard',
    'PayPal',
    'Samsung-Pay',
    'Square',
    'Stripe',
    'Venmo',
    'Visa',
];
const groups = [
    {
        headingText: {
            ar: 'روابط مهمة',
            en: 'Imoprtant link',
        },
    },
    {
        headingText: {
            ar: 'الدعم',
            en: 'Support',
        },
    },
    {
        headingText: {
            ar: 'الأسئلة الأكثر تكرارا',
            en: 'FAQ',
        },
    },
];
const links = [
    { text: { en: 'Blog', ar: 'مدونات' }, href: '' },
    { text: { en: 'About us', ar: 'حول' }, href: '' },
    { text: { en: 'Careers', ar: 'وظائف' }, href: '' },
    { text: { en: 'Jobs', ar: 'وظائف' }, href: '' },
    { text: { en: 'In Press', ar: 'في الصحافة' }, href: '' },
    { text: { en: 'Affiliate', ar: 'التابعة' }, href: '' },
];
export default function Footer() {
    const { language } = useLanguage();

    return (
        <footer className='footer-img p-8 bg-[#ececec]'>
            <div className='flex gap-5 max-md:flex-col'>
                <FooterContent />
                <div className='flex-1 flex max-md:flex-col'>
                    {groups.map((group, index) => (
                        <div
                            className='flex-1 mt-5'
                            key={index}
                        >
                            <h6 className='text-[#2E2E2F] text-[20px] font-[600] mb-3'>
                                {language === 'ar'
                                    ? group.headingText.ar
                                    : group.headingText.en}
                            </h6>
                            <ul>
                                {links.map((link) => (
                                    <li key={link.text}>
                                        <a
                                            href={link.href}
                                            className='text-[#8B6464] text-[16px] block'
                                        >
                                            {language === 'ar' ? link.text.ar : link.text.en}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
            <div className='w-full flex items-center justify-center gap-1 flex-wrap text-center mt-5'>
                {images.map((item, index) => {
                    return (
                        <img
                            key={index}
                            src={require(`./images/${item}.png`)}
                            className=''
                            alt=''
                        />
                    );
                })}
            </div>
            <div className='col-span-12 text-center mt-5'>
                <h6 className='text-[#5D5D5F]'> {language === 'ar' ? '© جميع الحقوق محفوظة 2023 ' : 'All Rights Reserved, 2023 ©'}</h6>
            </div>
        </footer>
    );
}
