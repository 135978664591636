export const mobileLinkes = [
  {
    text: {
      en: "Home",
      ar: "الرئيسية",
    },
    href: "/",
    classes:
      "-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#8B6464] hover:bg-gray-50",
  },
  {
    text: {
      en: "Cars Batteries",
      ar: "بطاريات السيارات",
    },
    href: "/products?filter=passenger",
    classes:
      "-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#8B6464] hover:bg-gray-50",
  },
  {
    text: {
      en: "Heavy Vehicles Batteries",
      ar: "بطاريات الشاحنات الثقيلة",
    },
    href: "/products?filter=passenger",
    classes:
      "-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#8B6464] hover:bg-gray-50",
  },
  {
    text: {
      en: "Motorcycles Batteries",
      ar: "بطاريات الدراجات",
    },
    href: "/products?filter=passenger",
    classes:
      "-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#8B6464] hover:bg-gray-50",
  },

  {
    text: {
      en: "Services",
      ar: "الخدمات",
    },
    href: "/services",
    classes:
      "-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#8B6464] hover:bg-gray-50",
  },
  {
    text: {
      en: "Reels",
      ar: "الفيديوهات",
    },
    href: "/reels",
    classes:
      "-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#8B6464] hover:bg-gray-50",
  },

  {
    text: {
      en: "About us",
      ar: "حولنا",
    },
    href: "/about",
    classes:
      "-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#8B6464] hover:bg-gray-50",
  },
  {
    text: {
      en: "Blogs",
      ar: "المدونات",
    },
    href: "/our-news",
    classes:
      "-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#8B6464] hover:bg-gray-50",
  },
];
export const desktopLinks = [
  {
    text: {
      en: "Home",
      ar: "الرئيسية",
    },
    href: "/",
    classes:
      "text-sm font-semibold xl:leading-6 text-[#8B6464] hover:text-[#DB1020]",
  },
  {
    text: {
      en: "Products",
      ar: "المنتجات",
    },
    href: "#",
    classes:
      "text-sm font-semibold xl:leading-6 text-[#8B6464] hover:text-[#DB1020]",
  },
  {
    text: {
      en: "Services",
      ar: "الخدمات",
    },
    href: "/services",
    classes:
      "text-sm font-semibold xl:leading-6 text-[#8B6464] hover:text-[#DB1020]",
  },
  {
    text: {
      en: "Reels",
      ar: "الفيديوهات",
    },
    href: "/reels",
    classes:
      "text-sm font-semibold xl:leading-6 text-[#8B6464] hover:text-[#DB1020]",
  },
  {
    text: {
      en: "About pages",
      ar: "حول",
    },
    classes:
      "text-sm font-semibold xl:leading-6 text-[#8B6464] hover:text-[#DB1020]",
    submenu: [
      {
        text: {
          en: "About us",
          ar: "حولنا",
        },
        href: "/about",
        classes:
          "text-sm font-semibold xl:leading-6 text-[#8B6464] hover:text-[#DB1020]",
      },
      {
        text: {
          en: "Blogs",
          ar: "المدونات",
        },
        href: "/our-news",
        classes:
          "text-sm font-semibold xl:leading-6 text-[#8B6464] hover:text-[#DB1020]",
      },
    ],
  },
];
