import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { API } from '../../api';
import Cookies from 'js-cookie';
import ListBox from './ListBox';
import { RadioButton } from 'primereact/radiobutton';
import upload from '../../assets/icons/upload.svg';
import imagePlaceholder from '../../assets/images/image-placeholder.png';

const Product = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams] = useSearchParams();
    let mode = searchParams.get('mode');
    const [product, setProduct] = useState();

    const [uploadedImage, setUploadedImage] = useState();
    const [selectedVechileType, setSelectedVechileType] = useState();
    const [brands, setBrands] = useState();
    const [selectedBrand, setSelectedBrand] = useState();

    const [models, setModels] = useState();
    const [selectedModel, setSelectedModel] = useState();

    const [carStyles, setCarStyles] = useState();
    const [selectedStyle, setSelectedStyle] = useState();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProduct((prevFormData) => ({
            ...prevFormData,
            attributes: {
                ...prevFormData.attributes,
                [name]: value,
            },
        }));
    };
    const deleteProduct = async () => {
        const { data } = await API.delete(`products/${params.id}`, {
            headers: {
                Authorization: `Bearer ${Cookies.get('token')}`,
            },
        });
        console.log(data);
        navigate('/products', { replace: true });
    };
    const updateProduct = async () => {
        const formData = {};
        formData['id'] = product.id;
        Object.entries(product.attributes).forEach(([key, value]) => {
            formData[key] = value;
        });
        formData['type'] = selectedVechileType;
        if (selectedBrand) {
            formData['brand_id'] = selectedBrand.id;
            formData['brand_name'] = selectedBrand.attributes.name;
            formData['brand_image'] = selectedBrand.attributes.image;
        }
        if (selectedBrand) {
            formData['car_type_id'] = selectedStyle.id;
            formData['car_type_name'] = selectedStyle.attributes.name;
            formData['car_type_image'] = selectedStyle.attributes.image;

            if (selectedModel) {
                formData['car_id'] = selectedModel.id;
                formData['car_name'] = selectedModel.attributes.name;
                formData['car_image'] = selectedModel.attributes.image;
            }
            const { data } = await API.put(`products/${params.id}`, formData, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`,
                },
            });
            console.log(data);
            if (uploadedImage) {
                const imageDate = new FormData();
                imageDate.append('image', uploadedImage);
                const { data: imageReq } = await API.put(
                    `products/${params.id}/image`,
                    imageDate,
                    {
                        headers: {
                            Authorization: `Bearer ${Cookies.get('token')}`,
                        },
                    }
                );
                console.log(imageReq);
            }
            navigate(`/products/${params.id}`, { replace: true });
        }
    };
    useEffect(() => {
        const getBrands = async () => {
            const { data } = await API.get('products/fetch/brands');
            setBrands(data.data);
        };
        const getCarStyles = async () => {
            const { data } = await API.get('products/fetch/car-types');
            setCarStyles(data.data);
        };
        const getModels = async () => {
            const { data } = await API.post('products/data/filter', {
                vehicle_type: selectedVechileType,
                brand_id: selectedBrand.id,
            });
            setModels(data.data);
            console.log(data);
        };
        const getProduct = async () => {
            const { data } = await API.get(`products/${params.id}`);
            console.log(data.data);
            setProduct(data.data);
            setSelectedVechileType(data.data.attributes.type);
            setSelectedBrand({
                id: data.data.attributes['brand_id'],
                attributes: {
                    name: data.data.attributes['brand_name'],
                    brand_image: selectedBrand?.attributes?.image,
                },
            });
            setSelectedStyle({
                id: data.data.attributes['car_type_id'],
                attributes: {
                    name: data.data.attributes['car_type_name'],
                    car_type_image: selectedStyle?.attributes?.image,
                },
            });
            setSelectedModel({
                id: data.data.attributes['car_id'],
                attributes: {
                    name: data.data.attributes['car_name'],
                    car_image: selectedModel?.attributes?.image,
                },
            });
        };
        if (!product) {
            getProduct();
        }
        getBrands();
        getCarStyles();
        if (product) {
            getModels();
        }
    }, [selectedVechileType]);
    return (
        <div className='flex flex-col items-center w-full'>
            <section className='max-sm:w-[260px] px-8 pb-[50px]'>
                <div className='px-4 flex max-md:flex-col max-md:items-center justify-center gap-6'>
                    <div
                        className={`border border-[#FF0303] rounded-xl max-sm:w-[228px] w-[326px] max-md:w-full flex flex-col items-center justify-center pb-6`}
                    >
                        <img
                            src={
                                uploadedImage
                                    ? URL.createObjectURL(uploadedImage)
                                    : product?.attributes?.image
                                        ? product.attributes.image
                                        : imagePlaceholder
                            }
                            alt='avatar'
                            className={
                                'h-full rounded-t-xl max-w-[324px] max-md:w-full max-md:max-w-full'
                            }
                        />
                        <label htmlFor='image' className='flex flex-col items-center '>
                            <img src={upload} alt='upload' className='cursor-pointer mt-4' />
                        </label>
                        <input
                            type='file'
                            name='image'
                            id='image'
                            hidden
                            disabled={mode === 'edit' ? false : true}
                            onChange={(e) => setUploadedImage(e.target.files[0])}
                        />
                    </div>
                    <form className='flex-1 max-sm:max-w-[228px]'>
                        <div className='border border-[#FF0303] mb-[25px] rounded-xl p-5'>
                            <h2 className='text-[#8B6464] text-[18px] font-semibold mb-[17px]'>
                                View Battery Details
                            </h2>
                            {product && (
                                <div className='flex flex-col gap-[20px]'>
                                    <div>
                                        <p className='text-[#8B6464] text-[13px]'>Name</p>
                                        <input
                                            type='text'
                                            name='name'
                                            value={product.attributes.name}
                                            disabled={mode === 'edit' ? false : true}
                                            onChange={handleInputChange}
                                            className='text-[#130707] text-[20px] font-bold border border-[#F0E2E2] rounded-[5px] mb-[20px] py-[6px] px-[12px] w-full'
                                        />
                                    </div>
                                    <div>
                                        <p className='text-[#8B6464] text-[13px]'>Description</p>
                                        <textarea
                                            rows={3}
                                            name='description'
                                            value={product.attributes.description}
                                            disabled={mode === 'edit' ? false : true}
                                            onChange={handleInputChange}
                                            className='resize-none text-[#130707] opacity-60 border border-[#F0E2E2] rounded-[5px] py-[6px] px-[12px] w-full'
                                        />
                                    </div>
                                    <div className='flex flex-col gap-[20px]'>
                                        <div className='flex gap-4'>
                                            <div className='flex flex-1 gap-4 max-sm:flex-col'>
                                                <div className='flex-1'>
                                                    <p className='text-[#8B6464] text-[13px]'>
                                                        Reserve Capacity ( RC )
                                                    </p>
                                                    <input
                                                        type='text'
                                                        name='rc'
                                                        value={product.attributes.rc}
                                                        disabled={mode === 'edit' ? false : true}
                                                        onChange={handleInputChange}
                                                        className='text-[#130707] text-[12px] border border-[#F0E2E2] rounded-[5px] mb-[20px] py-[6px] px-[12px] w-full'
                                                    />
                                                </div>
                                                <div className='flex-1'>
                                                    <p className='text-[#8B6464] text-[13px]'>
                                                        Ampere Hours ( AH )
                                                    </p>
                                                    <input
                                                        type='text'
                                                        name='ah'
                                                        value={product.attributes.ah}
                                                        disabled={mode === 'edit' ? false : true}
                                                        onChange={handleInputChange}
                                                        className='text-[#130707] text-[12px] border border-[#F0E2E2] rounded-[5px] mb-[20px] py-[6px] px-[12px] w-full'
                                                    />
                                                </div>
                                            </div>
                                            <div className='flex flex-1 gap-4 max-sm:flex-col'>
                                                <div className='flex-1'>
                                                    <p className='text-[#8B6464] text-[13px]'>
                                                        Cold Cranking Ambs ( CCA )
                                                    </p>
                                                    <input
                                                        type='text'
                                                        name='cca'
                                                        value={product.attributes.cca}
                                                        disabled={mode === 'edit' ? false : true}
                                                        onChange={handleInputChange}
                                                        className='text-[#130707] text-[12px] border border-[#F0E2E2] rounded-[5px] mb-[20px] py-[6px] px-[12px] w-full'
                                                    />
                                                </div>
                                                <div className='flex-1'>
                                                    <p className='text-[#8B6464] text-[13px]'>
                                                        Physical Dimension
                                                    </p>
                                                    <input
                                                        type='text'
                                                        name='physical_dimension'
                                                        value={product.attributes['physical_dimension']}
                                                        disabled={mode === 'edit' ? false : true}
                                                        onChange={handleInputChange}
                                                        className='text-[#130707] text-[12px] border border-[#F0E2E2] rounded-[5px] mb-[20px] py-[6px] px-[12px] w-full'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex gap-4'>
                                            <div className='flex flex-1 gap-4 max-sm:flex-col'>
                                                <div className='flex-1'>
                                                    <p className='text-[#8B6464] text-[13px]'>Wet/Dry</p>
                                                    <input
                                                        type='text'
                                                        name='wet_dry'
                                                        value={product.attributes['wet_dry']}
                                                        disabled={mode === 'edit' ? false : true}
                                                        onChange={handleInputChange}
                                                        className='text-[#130707] text-[12px] border border-[#F0E2E2] rounded-[5px] mb-[20px] py-[6px] px-[12px] w-full'
                                                    />
                                                </div>
                                                <div className='flex-1'>
                                                    <p className='text-[#8B6464] text-[13px]'> Voltage</p>
                                                    <input
                                                        type='text'
                                                        name='voltage'
                                                        value={product.attributes.voltage}
                                                        disabled={mode === 'edit' ? false : true}
                                                        onChange={handleInputChange}
                                                        className='text-[#130707] text-[12px] border border-[#F0E2E2] rounded-[5px] mb-[20px] py-[6px] px-[12px] w-full'
                                                    />
                                                </div>
                                            </div>
                                            <div className='flex flex-1 gap-4 max-sm:flex-col'>
                                                <div className='flex-1'>
                                                    <p className='text-[#8B6464] text-[13px]'>
                                                        Terminal Code
                                                    </p>
                                                    <input
                                                        type='text'
                                                        name='terminal_code'
                                                        value={product.attributes['terminal_code']}
                                                        disabled={mode === 'edit' ? false : true}
                                                        onChange={handleInputChange}
                                                        className='text-[#130707] text-[12px] border border-[#F0E2E2] rounded-[5px] mb-[20px] py-[6px] px-[12px] w-full'
                                                    />
                                                </div>
                                                <div className='flex-1'>
                                                    <p className='text-[#8B6464] text-[13px]'>
                                                        European Norm ( EN )
                                                    </p>
                                                    <input
                                                        type='text'
                                                        name='en'
                                                        value={product.attributes.en}
                                                        disabled={mode === 'edit' ? false : true}
                                                        onChange={handleInputChange}
                                                        className='text-[#130707] text-[12px] border border-[#F0E2E2] rounded-[5px] mb-[20px] py-[6px] px-[12px] w-full'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex gap-4 max-sm:flex-col'>
                                            <div className='flex-1'>
                                                <p className='text-[#8B6464] text-[13px]'>Price</p>
                                                <div className='flex justify-between items-center text-[#130707] font-bold text-[12px] border border-[#F0E2E2] rounded-[5px] py-[6px] px-[12px] '>
                                                    <input
                                                        type='number'
                                                        name='price'
                                                        value={product.attributes.price}
                                                        disabled={mode === 'edit' ? false : true}
                                                        onChange={handleInputChange}
                                                        className='flex-1'
                                                    />
                                                    <p>EGP</p>
                                                </div>
                                            </div>
                                            <div className='flex-1'>
                                                <p className='text-[#8B6464] text-[13px]'>
                                                    Weight * Height * Width * Length
                                                </p>
                                                <input
                                                    type='text'
                                                    name='dimensions'
                                                    value={product.attributes.dimensions}
                                                    disabled={mode === 'edit' ? false : true}
                                                    onChange={handleInputChange}
                                                    className='text-[#130707] text-[12px] border border-[#F0E2E2] rounded-[5px] mb-[20px] py-[6px] px-[12px] w-full'
                                                />
                                            </div>
                                            <div className='flex-1'>
                                                <p className='text-[#8B6464] text-[13px]'>Compaar</p>
                                                <input
                                                    type='text'
                                                    name='compaar'
                                                    value={product.attributes.compaar}
                                                    disabled={mode === 'edit' ? false : true}
                                                    onChange={handleInputChange}
                                                    className='text-[#130707] text-[12px] border border-[#F0E2E2] rounded-[5px] mb-[20px] py-[6px] px-[12px] w-full'
                                                />
                                            </div>
                                        </div>
                                        <div className='flex gap-4 max-sm:flex-col'>
                                            <div className='flex-1'>
                                                <div className='border border-[#FF0303] rounded-lg p-4  flex gap-2'>
                                                    <RadioButton
                                                        inputId='passenger'
                                                        name='passenger'
                                                        value='passenger'
                                                        disabled={mode === 'edit' ? false : true}
                                                        onChange={(e) =>
                                                            setSelectedVechileType('passenger')
                                                        }
                                                        checked={selectedVechileType === 'passenger'}
                                                    />
                                                    <label
                                                        htmlFor='passenger'
                                                        className='text-base max-sm:text-sm font-semibold text-[#130707]'
                                                    >
                                                        Passenger Car
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='flex-1'>
                                                <div className='border border-[#FF0303] rounded-lg p-4  flex gap-2'>
                                                    <RadioButton
                                                        inputId='heavy'
                                                        name='heavy'
                                                        value='heavy'
                                                        disabled={mode === 'edit' ? false : true}
                                                        onChange={(e) => setSelectedVechileType('heavy')}
                                                        checked={selectedVechileType === 'heavy'}
                                                    />
                                                    <label
                                                        htmlFor='heavy'
                                                        className='text-base max-sm:text-sm font-semibold text-[#130707]'
                                                    >
                                                        Heavy Car
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='flex-1'>
                                                <div className='border border-[#FF0303] rounded-lg p-4  flex gap-2'>
                                                    <RadioButton
                                                        inputId='motor'
                                                        name='motor'
                                                        value='motor'
                                                        disabled={mode === 'edit' ? false : true}
                                                        onChange={(e) => setSelectedVechileType('motor')}
                                                        checked={selectedVechileType === 'motor'}
                                                    />
                                                    <label
                                                        htmlFor='motor'
                                                        className='text-base max-sm:text-sm font-semibold text-[#130707]'
                                                    >
                                                        Motor
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {selectedVechileType === 'passenger' && (
                                            <div className='flex gap-4 max-sm:flex-col'>
                                                <div className='flex-1'>
                                                    <p className='text-[#8B6464] text-[13px]'>Brands</p>
                                                    {brands && (
                                                        <ListBox
                                                            options={brands}
                                                            selected={selectedBrand}
                                                            setSelected={setSelectedBrand}
                                                            disabled={mode === 'edit' ? false : true}
                                                            type='brands'
                                                        />
                                                    )}
                                                </div>
                                                <div className='flex-1'>
                                                    <p className='text-[#8B6464] text-[13px]'>
                                                        Style of Car
                                                    </p>
                                                    {carStyles && (
                                                        <ListBox
                                                            options={carStyles}
                                                            selected={selectedStyle}
                                                            setSelected={setSelectedStyle}
                                                            disabled={mode === 'edit' ? false : true}
                                                            type='brands'
                                                        />
                                                    )}
                                                </div>
                                                <div className='flex-1'>
                                                    <p className='text-[#8B6464] text-[13px]'>Model</p>
                                                    {models && (
                                                        <ListBox
                                                            options={models}
                                                            selected={selectedModel}
                                                            setSelected={setSelectedModel}
                                                            disabled={mode === 'edit' ? false : true}
                                                            type='brands'
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='flex gap-4'>
                            <button
                                type='button'
                                onClick={() => {
                                    if (mode === 'edit') {
                                        navigate(`/products/${params.id}`, { replace: true });
                                    } else {
                                        deleteProduct();
                                    }
                                }}
                                className='bg-[#FFE0D0] text-[#FF0303] rounded-lg py-4 px-8 flex-1'
                            >
                                {mode === 'edit' ? 'Cancel' : 'Delete'}
                            </button>
                            <button
                                type='button'
                                onClick={() => {
                                    if (mode === 'edit') {
                                        updateProduct();
                                    } else {
                                        navigate('?mode=edit', { replace: true });
                                    }
                                }}
                                className='text-white bg-[#FF0303] rounded-lg py-4 px-8 flex-[3]'
                            >
                                {mode === 'edit' ? 'Save Changes' : 'Edit'}
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    );
};

export default Product;
