import React, { useState, useEffect } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import reel from '../../assets/images/reel.png';
import play from '../../assets/icons/dash-play.svg';
import show from '../../assets/icons/eye.svg';
import { Paginator } from 'primereact/paginator';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API } from '../../api';
import Cookies from 'js-cookie';

export default function Reels() {
    const [reels, setReels] = useState();
    const [totalRecords, setTotalRecords] = useState();
    const [searchParams] = useSearchParams();
    let page = searchParams.get('page') || 1;
    const [first, setFirst] = useState(page);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        const getReels = async () => {
            const { data } = await API.get(
                `reels?page=${page}`
            );
            setReels(data.data);
            setTotalRecords(data.meta.total);
            console.log(data.data);
        };
        getReels();
    }, [page]);

    const updateReels = async () => {
        const { data } = await API.patch(
            'reels/ad/fetch-new',
            {},
            {
                headers: {
                    Authorization:
                        `Bearer ${Cookies.get('cookies')}`,
                },
            }
        );
        console.log(data);
    };

    const updateReelsView = async () => {
        const { data } = await API.patch(
          "reels/ad/update-views",
          {},
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("cookies")}`,
            },
          }
        );
        console.log(data);
    };
    return (
        <section className='reels'>
            <div className='flex flex-col items-center'>
                <div className='flex items-center justify-center flex-wrap gap-6'>
                    <button
                        className='flex items-center p-3 px-5 rounded-[5px] bg-[#FFE0D0] text-[#B70B28]'
                        onClick={updateReels}
                    >
                        <div className='w-[20px] h-[20px] rounded-full flex items-center justify-center border border-[#B70B28]'>
                            <FontAwesomeIcon icon={faPlus} className='text-[11px]' />
                        </div>
                        <p>Update Reels</p>
                    </button>
                    <button
                        className='flex items-center p-3 px-5 rounded-[5px] bg-[#FFE0D0] text-[#B70B28]'
                        onClick={updateReelsView}
                    >
                        <p>Update Views</p>
                    </button>
                </div>
                <div className='p-6 flex gap-8 flex-wrap items-center justify-center'>
                    {reels?.map((item) => (
                        <a
                            href={item.attributes.url}
                            key={item.id}
                            className='reel relative w-[140px] h-[260px] sm:w-[280px] sm:h-[470px] rounded-lg overflow-hidden'
                            style={{ 'box-shadow': ' 0px 2.555px 11.498px 0px rgba(75, 70, 92, 0.10)' }}
                        >
                            <div>
                                <img
                                    src={reel}
                                    alt='cover'
                                    className='w-[140px] h-[180px] sm:w-[280px] sm:h-[400px] z-10'
                                />
                                <div className='z-20 absolute top-3 left-3 bg-[#130707b9] p-3 rounded-[60px]'>
                                    <p className='text-[#FF1716] text-xs font-bold'>
                                        {item.attributes.views} Views
                                    </p>
                                </div>
                                <div className='z-[5000] absolute bottom-[80px] sm:bottom-[100px] left-[50%] translate-x-[-50%]'>
                                    <img
                                        src={play}
                                        alt='play'
                                        className='max-sm:w-8 max-sm:h-8'
                                    />
                                </div>
                                <div className='w-full flex justify-between items-center px-2 py-3 rounded-[60px]'>
                                    <div>
                                        <p className='text-black text-[10px] sm:text-sm'>
                                            {item.attributes.title}
                                        </p>
                                        <p className='text-[#8B6464] text-[6px] sm:text-xs'>
                                            {item.attributes.date} {item.attributes.time}
                                        </p>
                                    </div>
                                    <div className='bg-[#8B6464] rounded-[50%] w-6 h-6 flex justify-center items-center'>
                                        <img src={show} alt='show' className='w-4 h-4' />
                                    </div>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
                <Paginator
                    rows={15}
                    totalRecords={totalRecords}
                    first={first + 1}
                    onPageChange={(e) => {
                        navigate(`?page=${e.page + 1}`);
                        setFirst(e.first);
                    }}
                />
            </div>
        </section>
    );
}
