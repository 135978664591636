import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dots from "../../assets/icons/dots-vertical.svg";
import { Paginator } from "primereact/paginator";
import ListBox from "./ListBox";
import { API } from "../../api";
import Cookies from "js-cookie";
import { useLanguage } from "../../translations/LanguageContext";
const TABLE_HEADS = [
  "id",
  "product name",
  "author",
  "categories",
  "date",
  "status",
  " ",
];

const Blogs = () => {
  const { language } = useLanguage();
  const [blogs, setBlogs] = useState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let page = searchParams.get("page") || 1;
  // let user = searchParams.get('user') || null
  // let category = searchParams.get('category') || null
  // let query = searchParams.get('query') || null
  const [totalRecords, setTotalRecords] = useState();
  const [first, setFirst] = useState(page);
  const [categories, setCategories] = useState();
  const [users, setUsers] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [search, setSearch] = useState();
  useEffect(() => {
    const getBlogs = async () => {
      const { data } = await API.get(`posts?page=${page}`);
      setBlogs(data.data);
      setTotalRecords(data.meta.total);
    };
    const getCategories = async () => {
      const { data } = await API.get("categories");
      setCategories(data.data);
    };
    const getUsers = async () => {
      const { data } = await API.get("users", {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });
      setUsers(data.data);
    };
    const getFilterdBlogs = async () => {
      const { data } = await API.post(
        `posts/fetch/filter?page=${page}`,
        {
          title: search,
          category_id: parseInt(selectedCategory?.id),
          user_id: parseInt(selectedUser?.id),
        },
        {
          headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        }
      );
      setBlogs(data.data);
      setTotalRecords(data.meta.total);
    };
    getCategories();
    getUsers();
    if (search || selectedCategory || selectedUser) {
      getFilterdBlogs();
    } else {
      getBlogs();
    }
  }, [page, search, selectedCategory, selectedUser]);
  const formatDate = (date) => {
    const originalDate = new Date(date);

    // Format the date to "12/8/2023 12:50 PM"
    const formattedDateTime = `${originalDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    })} ${originalDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    })}`;

    return formattedDateTime;
  };
  let timeout;

  const translatedTableHeads = TABLE_HEADS.map((head) => {
    switch (head.toLowerCase()) {
      case "id":
        return language === "ar" ? "رقم" : "ID";
      case "product name":
        return language === "ar" ? "اسم المنتج" : "Product Name";
      case "author":
        return language === "ar" ? "المؤلف" : "Author";
      case "categories":
        return language === "ar" ? "الفئات" : "Categories";
      case "date":
        return language === "ar" ? "التاريخ" : "Date";
      case "status":
        return language === "ar" ? "الحالة" : "Status";
      case " ":
        return " ";
      default:
        return head;
    }
  });

  return (
    <section className="">
      <div className="flex items-start justify-between max-lg:flex-col max-lg:items-center mb-10 px-4">
        <div className="flex items-center justify-center max-sm:w-full mb-8">
          <Link
            to={"add-blog"}
            className="flex items-center gap-2 text-[#B70B28] bg-[#FFE0D0] rounded-lg px-[20px] py-[10px] "
          >
            <FontAwesomeIcon icon={faPlus} className="text-[11px]" />
            <p>{language === "ar" ? "اضافة مدونة" : "Add Blog"}</p>
          </Link>
        </div>
        <div className="flex items-center max-lg:flex-col max-md:items-center gap-8">
          <div className="flex items-center max-md:flex-col gap-4">
            <p className="text-[15px] text-[#8B6464]">
              {language === "ar" ? "بحث:" : "Search:"}
            </p>
            <input
              type="text"
              placeholder={language === "ar" ? "بحث بالاسم" : "search by title"}
              onChange={(e) => {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                  setSearch(e.target.value);
                }, 1000);
              }}
              className="border border-[#DBDADE] rounded-md  py-[7px] px-[14px]"
            />
          </div>
          <div className="flex gap-4">
            <div className="flex items-center max-md:flex-col gap-4">
              <p className="text-[15px] text-[#8B6464]">
                {language === "ar" ? "المستخدم:" : "Users:"}
              </p>
              {users && (
                <ListBox
                  selected={selectedUser}
                  setSelected={setSelectedUser}
                  options={users}
                  type={"users"}
                />
              )}
            </div>
            <div className="flex items-center max-md:flex-col gap-4">
              <p className="text-[15px] text-[#8B6464]">
                {language === "ar" ? "التصنيفات:" : "Categories:"}
              </p>
              {categories && (
                <ListBox
                  selected={selectedCategory}
                  setSelected={setSelectedCategory}
                  options={categories}
                  type={"categories"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-center">
        <div className="w-full max-sm:max-w-[250px] max-w-[1200px] overflow-x-auto px-4">
          <table className="w-full border-collapse border-spacing-5 border border-[#DBDADE]">
            <thead>
              <tr className="border-bottom border-[#DBDADE]">
                {translatedTableHeads.map((head) => (
                  <td
                    key={head}
                    className={`text-[#4B465C] uppercase p-4 border-0 font-semibold ${language === "ar" ? "text-right" : "text-left"
                      }`}
                  >
                    {head}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody className="bg-[#F9F1F1]">
              {blogs?.map((blog) => (
                <tr key={blog.id} className="border-bottom border-[#DBDADE]">
                  <td className="text-[#4B465C] p-4 text-left border-0">
                    {blog.id}
                  </td>
                  <td className="text-[#4B465C] p-4 text-left border-0">
                    <div className="flex items-center gap-2">
                      <div className="max-sm:hidden">
                        <img
                          className="w-12 h-12 rounded-[50%]"
                          src={
                            blog.attributes.image
                              ? blog.attributes.image
                              : require("../../assets/images/image-placeholder.png")
                          }
                          alt="avatar"
                        />
                      </div>
                      <p>{blog.attributes.title}</p>
                    </div>
                  </td>
                  <td className="text-[#4B465C] p-4 text-left border-0">
                    {blog.user.attributes.name}
                  </td>
                  <td className="text-[#4B465C] p-4 text-left border-0 flex flex-wrap gap-1">
                    {blog.categories.map((cat) => (
                      <div
                        key={cat.id}
                        className="bg-[#B89494] text-[#2A0F0F] font-semibold rounded py-[5px] px-[10px] text-center w-fit first-of-type:w-full"
                      >
                        {cat.attributes.title}
                      </div>
                    ))}
                  </td>
                  <td className="text-[#4B465C] p-4 text-left border-0">
                    {formatDate(blog.attributes["created_at"])}
                  </td>
                  <td className="text-[#4B465C] p-4 text-left border-0">
                    {blog.attributes.status == 1 ? (
                      <div className="text-[#28C76F] bg-[#28C76F29] text-center rounded py-[5px] px-[10px]">
                        Active
                      </div>
                    ) : (
                      <div className="text-[#EA5455] bg-[#EA545529] text-center rounded py-[5px] px-[10px]">
                        Disactive
                      </div>
                    )}
                  </td>
                  <td className="text-[#4B465C] p-4 text-left border-0">
                    <button onClick={() => navigate(`${blog.id}`)}>
                      <img src={dots} alt="actions" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Paginator
          rows={10}
          totalRecords={totalRecords}
          first={first + 1}
          onPageChange={(e) => {
            navigate(`?page=${e.page + 1}`);
            setFirst(e.first);
          }}
        />
      </div>
    </section>
  );
};

export default Blogs;
