import React from "react";
import { useLanguage } from "../../../../translations/LanguageContext";

const brands = [
  { name: "", imageSrc: "one", classNames: "col-span-6" },
  { name: "", imageSrc: "two", classNames: "col-span-6" },
  { name: "", imageSrc: "three", classNames: "col-span-12" },
  { name: "", imageSrc: "four", classNames: "col-span-6" },
  { name: "", imageSrc: "five", classNames: "col-span-6" },
];

const renderBrands = brands.map((item, index) => {
  return (
    <div
      key={index}
      className={`flex items-center justify-center ${item.classNames}`}
    >
      <img src={require(`./images/brand-${item.imageSrc}.png`)} alt="" />
    </div>
  );
});

export default function OurPartners() {
  const { language } = useLanguage();

  return (
    <section className="flex flex-col sm:flex-row items-center gap-5 my-10">
      <div className="flex-1 grid grid-cols-12 gap-3">{renderBrands}</div>

      <div className="flex-1">
        <h1 className="capitalize font-medium text-[60px] text-[#FF1716]">
          {language === "ar" ? "شركاؤنا" : "Our Partners"}
        </h1>

        <p className="mb-3 font-medium">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio
          cing elit. Nunc o cing elit. Nunc ocing elit. Nunc o in et, lectus sit
          lorem id integer.
        </p>

        <p className="mb-3 font-medium">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc odio
          cing elit. Nunc o cing elit. Nunc ocing elit. Nunc o in et, lectus sit
          lorem id integer.
        </p>
      </div>
    </section>
  );
}
