import React, { useState } from "react";
import { LandingPage, ProductsCard } from "../../components";
import { FilterProductPanel } from "./components";
import { useLanguage } from "../../translations/LanguageContext";

export const gridItem = (product) => {
  return (
    <div className="col-span-12 sm:col-span-6 lg:col-span-4 p-2">
      <ProductsCard product={product} />
    </div>
  );
};

export default function Products() {
  const [filters, setFilters] = useState({});
  const [products, setProducts] = useState();
  const { language } = useLanguage();
  console.log(products);

  return (
    <div>
      <LandingPage
        title={language === "ar" ? "منتجاتنا" : "Our Products"}
        pText={
          language === "ar"
            ? "ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي ص تجريبي نص تجريبي"
            : "Omnis in consectetur harum. Reiciendis architecto quia eos. Dolorem dolorum repellat. Molestiae exercitationem amet molestiae minus. Natus qui occaecati dolores perferendis."
        }
        btnText={language === "ar" ? "مشاهدة المزيد" : "See More"}
      />

      <div className="px-8">
        <div className="my-5 flex flex-col sm:flex-row  items-center sm:items-start gap-5">
          <FilterProductPanel
            classNames={"sm:w-[320px] w-full"}
            setProducts={setProducts}
          />

          <div className="flex-1">
            {products &&
              products.map((product) => {
                return <ProductsCard product={product} />;
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
