import React, { useEffect, useState } from "react";
import { ProductFeatures } from "./components";
import RelatedProducts from "./components/RelatedProducts/RelatedProducts";
import { API } from "../../api";
import { useParams } from "react-router-dom";

export default function ProductDetails() {
  const [product, setProduct] = useState();
  const { id } = useParams();

  useEffect(() => {
    window.scroll(0, 0)
    const getProduct = async () => {
      const { data } = await API.get(`products/${id}`);
      console.log(data);
      setProduct(data.data);
    };
    getProduct();
  }, [id]);

  return (
    <div>
      <div className="container !my-10">
        <h1 className="bg-[#FF6050] font-medium mb-5 text-white p-2 w-fit rounded-[5px] capitalize">
          {product && product.attributes.name}
        </h1>

        <img
          src={product?.attributes?.image ? product.attributes.image : ""}
          alt=""
          className="w-full h-[350px] rounded-[20px] mb-10 object-cover"
        />

        <ProductFeatures product={product} />

        <RelatedProducts product={product} />
      </div>
    </div>
  );
}
