import React from "react";
import imgPlaceholder from "../../assets/images/image-placeholder.png";
import { Link, useNavigate } from "react-router-dom";

const DETAILS = [
  { name: "RC:", key: 'rc' },
  { name: "AH:", key: 'ah' },
  { name: "PD:", key: 'physical_dimension' },
  { name: "W/D:", key: 'wet_dry' },
  { name: "V:", key: 'voltage' },
  { name: "TC:", key: 'terminal_code' },
  { name: "EN:", key: 'en' },
  { name: "W*h*W*L:", key: 'dimensions' },
  { name: "CCA:", key: 'cca' },
];

const renderDetails = (product, details) => details.map((detail, index) => {
  return (
    <span className="me-1">
      <span className="text-[#130707] text-[13px] font-medium">
        {detail.name}
      </span>
      <span className="text-[#8B6464] text-[13px] font-medium">
        {product.attributes[detail.key]}
      </span>
      |
    </span>
  );
});

export default function ProductsCard({ product }) {

  console.log(product);
  return (
    <>
      {product && (
        <div className="rounded-[10px] relative shadow-md pb-2 overflow-hidden">
          <div className="h-[200px] relative">
            <img
              src={
                product?.attributes?.image
                  ? product.attributes.image
                  : imgPlaceholder
              }
              alt="images"
              className="object-fill cursor-pointer w-full h-full"
            />

            <p className="absolute left-3 top-2 bg-white text-[#93072A] px-2 font-medium text-[11px] rounded-[3px]">
              {product.attributes.compaar}
            </p>

            {/* <img src={require('./images/user.png')} alt='' className='absolute right-3 top-2 rounded-full w-[54px] h-[54px] object-fill' /> */}

            <div className="bg-white p-2 pb-1 absolute right-3 bottom-0 rounded-t-[15px]">
              <img
                src={
                  product?.attributes?.car_image
                    ? product.attributes.car_image
                    : imgPlaceholder
                }
                alt=""
              />
            </div>
          </div>

          <div className="p-2">
            <h5 className="text-[#8B6464] mb-2 text-[12px] font-medium">
              {product.attributes.brand_name}
            </h5>

            <div className="flex items-center justify-between mb-3">
              <h3 className="text-[#130707] text-[20px] font-medium">
                {product.attributes.name}
              </h3>

              <p className="p-2 bg-[#DCFCE7] text-[13px]">
                {product.attributes.price} EGP
              </p>
            </div>

            <p className="text-[#130707] text-[14px] font-medium mb-3">
              {product.attributes.description}
            </p>

            <div className="flex items-center flex-wrap mb-3">
              {renderDetails(product, DETAILS)}
            </div>

            <div className="flex items-center justify-between !mt-5">
              <button className="min-btn text-[11px] sm:text-[16px] lg:text-[14px] !py-3 !px-0 w-[48%] !rounded-[5px] bg-[var(--primary-color)] text-white border border-[#AA2355] capitalize">
                get it now
              </button>
              <Link
                to={`/products/${product.id}`}
                className="text-center min-btn text-[11px] sm:text-[16px] lg:text-[14px] !py-3 !px-0 w-[48%] !rounded-[5px] !bg-transparent border border-[#26303B] me-2"
              >
                View Battery
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
