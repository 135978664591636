import React from "react";
import car from "../../../assets/images/categ-one.png";
import heavy from "../../../assets/images/havey.png";
import motor from "../../../assets/images/categ-three.png";
import { Link } from "react-router-dom";
import { useLanguage } from "../../../translations/LanguageContext";
const Modal = ({ isOpen, onClose }) => {
  const { language } = useLanguage()
  const handleCloseModal = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    isOpen && (
      <div
        className="absolute top-[70px] left-0 w-full h-screen z-[999] bg-black bg-opacity-50 flex  justify-center max-lg:hidden"
        onClick={handleCloseModal}
      >
        <div className="bg-white h-[200px] rounded-md border border-[#FF1716] flex justify-center items-center">
          <Link
            to="/products"
            className="h-full border border-[#FF1716] flex justify-center items-center relative"
            onClick={onClose}
          >
            <h3 className="bg-[#FF1716] text-[#fff] py-[5px] px-[6px] flex items-center justify-center text-base font-bold capitalize rounded-2xl absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%]">
              {language === 'ar' ? "جميع المنتجات" : " All Products"}
            </h3>
            <div className="flex h-full">
              <div className="w-[113px] h-[200px]">
                <img className="w-full h-full" src={car} alt="car" />
              </div>
              <div className="h-[200px]">
                <img
                  className="w-full h-[50%] border border-[#FF1716]"
                  src={heavy}
                  alt="heavy"
                />
                <img
                  className="w-full h-[50%] border border-[#FF1716]"
                  src={motor}
                  alt="motor"
                />
              </div>
            </div>
          </Link>

          <Link
            to="/products?filter=passenger"
            className="h-full border border-[#FF1716] flex justify-center items-center relative"
            onClick={onClose}
          >
            <h3 className="w-max bg-[#FF1716] text-[#fff] py-[5px] px-[6px] flex items-center justify-center text-base font-bold capitalize rounded-2xl absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%]">
              {language === 'ar' ? "بطاريات سيارات" : "  Cars Batteries"}
            </h3>
            <div className="flex h-full">
              <div className="w-[226px] h-[200px]">
                <img className="w-full h-full" src={car} alt="car" />
              </div>
            </div>
          </Link>

          <Link
            to="/products?filter=heavy"
            className="h-full border border-[#FF1716] flex justify-center items-center relative"
            onClick={onClose}
          >
            <h3 className="w-max bg-[#FF1716] text-[#fff] py-[5px] px-[6px] flex items-center justify-center text-base font-bold capitalize rounded-2xl absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%]">
              {language === 'ar' ? "بطاريات نقل ثقيل" : "Heavy Vehicles Batteries"}
            </h3>
            <div className="flex h-full">
              <div className="w-[226px] h-[200px]">
                <img className="w-full h-full" src={heavy} alt="heavy" />
              </div>
            </div>
          </Link>
          <Link
            to="/products?filter=motor"
            className="h-full border border-[#FF1716] flex justify-center items-center relative"
            onClick={onClose}
          >
            <h3 className="w-max bg-[#FF1716] text-[#fff] py-[5px] px-[6px] flex items-center justify-center text-base font-bold capitalize rounded-2xl absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%]">
              
              {language === 'ar' ? "بطاريات موتسيكلات" : "Motorcycles Batteries"}
            </h3>
            <div className="flex h-full">
              <div className="w-[226px] h-[200px]">
                <img className="w-full h-full" src={motor} alt="motor" />
              </div>
            </div>
          </Link>
        </div>
      </div>
    )
  );
};

export default Modal;
