const countries = [
    { name: 'USA', code: 6 },
    { name: 'USA', code: 7 },
    { name: 'USA', code: 8 },
    { name: 'USA', code: 9 },
    { name: 'USA', code: 10 }
];
const cities = [
    { name: 'USA', code: 6 },
    { name: 'USA', code: 7 },
    { name: 'USA', code: 8 },
    { name: 'USA', code: 9 },
    { name: 'USA', code: 10 }
];
const lang = [
    { name: 'USA', code: 6 },
    { name: 'USA', code: 7 },
    { name: 'USA', code: 8 },
    { name: 'USA', code: 9 },
    { name: 'USA', code: 10 }
];

export {
    countries,
    cities,
    lang
}