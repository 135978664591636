import React, { useState } from 'react'
import MainPanel from '../MainPanel/MainPanel'
import { RouterProvider, createBrowserRouter, Outlet } from 'react-router-dom';
import BreadcrumbNav from '../BreadcrumbNav/BreadcrumbNav';
////////////////////////////////////////////////////////////
import Home from "../../pages/Home"
import OurNews from "../../pages/OurNews"
import Services from "../../pages/Services"
import Products from "../../pages/Products"
import ProductDetails from "../../pages/ProductDetails"
import OurScoop from "../../pages/OurScoop"
import Login from "../../components/Login"
import ForgotPassword from "../../components/Login/ForgotPassword"
import ResetPassword from "../../components/Login/ResetPassword"
import ProductView from "../../pages/ProductView"
import ServicesView from "../../pages/ServicesView"
import Reels from "../../pages/Reels"
////////////////////////////////////////////////////////////
import AdminServices from "../../pages/Dashboard/Services";
import Banners from "../../pages/Dashboard/Banners";
import Teams from "../../pages/Teams";
import TeamMember from "../../pages/Dashboard/TeamMember";
import AddTeamMember from "../../pages/Dashboard/AddTeamMember";
import Profile from "../../pages/Profile";
import Blogs from "../../pages/Dashboard/Blogs";
import AddBlog from "../../pages/Dashboard/AddBlog";
import SingleBlog from "../../pages/Dashboard/SingleBlog";
import BlogsCategories from "../../pages/Dashboard/BlogsCategories";
import ProductsCategory from "../../pages/Dashboard/ProductsCategory";
import AdminProducts from "../../pages/Dashboard/Products";
import AddProduct from "../../pages/Dashboard/AddProduct";
import Product from "../../pages/Dashboard/Product";

import AdminProductView from "../../pages/ProductView";
import AdminServicesView from "../../pages/ServicesView";
import ReelsView from "../../pages/ReelsView";
import TeamViews from "../../pages/TeamViews";
import Navbar from '../Navbar';
import Header from '../Header';
import WhatsAppButton from '../../components/WhatsAppButton';
import Footer from '../Footer';


const Dashboard = () => {
    const [isExpanded, setIsExpanded] = useState(true);
    const router = createBrowserRouter([
        {
            path: '/',
            element: (
                <>
                    <Navbar />
                    <Header />
                    <Outlet />
                    <WhatsAppButton />
                    <Footer />
                </>
            ),
            children: [
                { path: '', element: <Home /> },
                { path: 'admin/login', element: <Login /> },
                { path: 'forgot-password', element: <ForgotPassword /> },
                { path: 'reset-password', element: <ResetPassword /> },
                { path: 'our-news', element: <OurNews /> },
                { path: 'services', element: <Services /> },
                { path: 'reels', element: <Reels /> },
                {
                    path: 'services/services-view',
                    element: (
                        <ServicesView
                            isDisabled={true}
                            data={{ btns: { btn1: 'Delete', btn2: 'Edit' }, type: 'view' }}
                        />
                    ),
                },
                {
                    path: 'services/edit-service',
                    element: (
                        <ServicesView
                            isDisabled={false}
                            data={{
                                btns: { btn1: 'dismiss', btn2: 'save changes' },
                                type: 'edit',
                            }}
                        />
                    ),
                },
                {
                    path: 'services/add-service',
                    element: (
                        <ServicesView
                            isDisabled={false}
                            data={{ btns: { btn1: 'Cancel', btn2: 'submit' }, type: 'add' }}
                        />
                    ),
                },
                { path: 'products', element: <Products /> },
                { path: 'products/:id', element: <ProductDetails /> },
                { path: 'products/view-product', element: <ProductView /> },
                { path: 'products/product-details', element: <ProductDetails /> },
                { path: 'about', element: <OurScoop /> },
            ]
        },
        {
            path: '/admin',
            element: (
                <div className='flex'>
                    <MainPanel isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
                    <div className='flex-1 bg-white'>
                        <BreadcrumbNav />
                        <Outlet />
                    </div>
                </div>
            ),
            children: [
                { path: 'dashboard', element: <div>Dashboard</div> },
                { path: 'products', element: <AdminProducts /> },
                {
                    path: 'products/products-view',
                    element: (
                        <AdminProductView
                            isDisabled={true}
                            data={{
                                btns: { btn1: 'Delete', btn2: 'Edit' },
                                type: 'view',
                            }}
                        />
                    ),
                },
                {
                    path: 'products/products-edit',
                    element: (
                        <AdminProductView
                            isDisabled={false}
                            data={{
                                btns: { btn1: 'dismiss', btn2: 'save changes' },
                                type: 'edit',
                            }}
                        />
                    ),
                },
                { path: 'products/add-product', element: <AddProduct /> },
                { path: 'products/:id', element: <Product /> },
                { path: 'banners', element: <Banners /> },
                { path: 'services', element: <AdminServices /> },
                {
                    path: 'services/services-view/:id',
                    element: (
                        <AdminServicesView
                            isDisabled={true}
                            data={{
                                btns: { btn1: 'Delete', btn2: 'Edit' },
                                type: 'view',
                            }}
                        />
                    ),
                },
                {
                    path: 'services/services-edit/:id',
                    element: (
                        <AdminServicesView
                            isDisabled={false}
                            data={{
                                btns: { btn1: 'dismiss', btn2: 'save changes' },
                                type: 'edit',
                            }}
                        />
                    ),
                },
                {
                    path: 'services/services-add',
                    element: (
                        <AdminServicesView
                            isDisabled={false}
                            data={{
                                btns: { btn1: 'Cancel', btn2: 'submit' },
                                type: 'add',
                            }}
                        />
                    ),
                },
                {
                    path: 'reels',
                    element: (
                        <ReelsView
                            isDisabled={true}
                            data={{
                                btns: { btn1: 'Delete', btn2: 'Edit' },
                                type: 'view',
                            }}
                        />
                    ),
                },
                { path: 'blogs', element: <Blogs /> },
                { path: 'blogs/add-blog', element: <AddBlog /> },
                { path: 'blogs/:id', element: <SingleBlog /> },
                { path: 'blogs-categories', element: <BlogsCategories /> },
                { path: 'products-category', element: <ProductsCategory /> },
                { path: 'teams', element: <Teams /> },
                { path: 'teams/add-team-member', element: <AddTeamMember /> },
                { path: 'teams/:id', element: <TeamMember /> },
                {
                    path: 'teams/teams-view',
                    element: (
                        <TeamViews
                            isDisabled={true}
                            data={{
                                btns: { btn1: 'Delete', btn2: 'Edit' },
                                type: 'view',
                            }}
                        />
                    ),
                },
                {
                    path: 'teams/teams-edit',
                    element: (
                        <TeamViews
                            isDisabled={false}
                            data={{
                                btns: { btn1: 'dismiss', btn2: 'save changes' },
                                type: 'edit',
                            }}
                        />
                    ),
                },
                {
                    path: 'teams/teams-add',
                    element: (
                        <TeamViews
                            isDisabled={false}
                            data={{
                                btns: { btn1: 'Cancel', btn2: 'submit' },
                                type: 'add',
                            }}
                        />
                    ),
                },
                { path: 'profile', element: <Profile /> },
            ],
        },
    ]);

    return (
        <RouterProvider router={router} />
    );
}

export default Dashboard