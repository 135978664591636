import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCustomEffect } from "../../hooks";
import deleteIcon from "../../assets/icons/delete.svg";
import pencilIcon from "../../assets/icons/pencil.svg";
import { API } from "../../api";
import Cookies from "js-cookie";
import { useLanguage } from "../../translations/LanguageContext";

const TABLE_HEADS = [
  "id",
  "full name",
  "email",
  "created date",
  "job description",
  "actions",
];

export default function Reels() {
  const { language } = useLanguage();
  const [users, setUsers] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const getUsers = async () => {
      const { data } = await API.get("users", {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });
      // console.log(data.data);
      setUsers(data.data);
    };
    getUsers();
  }, []);

  const translatedTableHeads = TABLE_HEADS.map((head) => {
    switch (head.toLowerCase()) {
      case "id":
        return language === "ar" ? "رقم" : "ID";
      case "full name":
        return language === "ar" ? "الاسم الكامل" : "Full Name";
      case "email":
        return language === "ar" ? "البريد الإلكتروني" : "Email";
      case "created date":
        return language === "ar" ? "تاريخ الإنشاء" : "Created Date";
      case "job description":
        return language === "ar" ? "وصف الوظيفة" : "Job Description";
      case "actions":
        return language === "ar" ? "الإجراءات" : "Actions";
      default:
        return head; // Return the original label for aar unrecognized header
    }
  });

  const deleteUserHandler = async (id) => {
    const { data } = await API.delete(`users/${id}`, {
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    });
    let updatedUsers = users.filter((user) => user.id != id);
    setUsers(updatedUsers);
    // console.log(data);
  };

  const { useReplacePagnitToText, useFetchData } = useCustomEffect();

  useReplacePagnitToText();

  const formattedDate = (date) => {
    const originalDate = new Date(date);

    const formattedDate = originalDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });

    return formattedDate;
  };
  return (
    <section className="">
      <div className="flex items-center justify-between mb-10">
        <Link
          to={"add-team-member"}
          className="flex items-center p-3 px-5 rounded-[5px] bg-[#FFE0D0] text-[#B70B28]"
        >
          <div className="w-[20px] h-[20px] rounded-full flex items-center justify-center border border-[#B70B28] me-2">
            <FontAwesomeIcon icon={faPlus} className="text-[11px]" />
          </div>

          <span>{language === "ar" ? "اضافة عضو" : "Add Member"}</span>
        </Link>
      </div>
      <div className="w-full flex flex-col items-center">
        <div className="w-full max-sm:max-w-[250px] max-w-[1200px] overflow-x-auto px-4">
          <table className="w-full border-collapse border-spacing-5">
            <thead>
              <tr>
                {translatedTableHeads.map((head) => (
                  <td
                    key={head}
                    className={`text-[#4B465C] uppercase p-4  border-0 font-semibold ${language === "ar" ? "text-right" : "text-left"
                      }`}
                  >
                    {head}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody className="bg-[#F9F1F1]">
              {users?.map((user) => (
                <tr key={user.id} className="border">
                  <td
                    className={`text-[#4B465C] p-4  border-0 font-semibold ${language === "ar" ? "text-right" : "text-left"
                      }`}
                  >
                    {user.id}
                  </td>
                  <td
                    className={`text-[#4B465C] p-4 border-0 ${language === "ar" ? "text-right" : "text-left"
                      }`}
                  >
                    <div className="flex items-center gap-2">
                      <div className="max-sm:hidden">
                        <img
                          className="w-12 h-12 rounded-[50%]"
                          src={
                            user.attributes.image
                              ? user.attributes.image
                              : require("../../assets/images/image-placeholder.png")
                          }
                          alt="avatar"
                        />
                      </div>
                      <p className="font-semibold">{user.attributes.name}</p>
                    </div>
                  </td>
                  <td
                    className={`text-[#4B465C] p-4 border-0 ${language === "ar" ? "text-right" : "text-left"
                      }`}
                  >
                    {user.attributes.email}
                  </td>
                  <td
                    className={`text-[#4B465C] p-4 border-0 ${language === "ar" ? "text-right" : "text-left"
                      }`}
                  >
                    {formattedDate(user.attributes["created_at"])}
                  </td>
                  <td
                    className={`text-[#4B465C] p-4 border-0 ${language === "ar" ? "text-right" : "text-left"
                      }`}
                  >
                    {user.attributes.role}
                  </td>
                  <td
                    className={`text-[#4B465C] p-4 border-0 flex gap-4 ${language === "ar" ? "text-right" : "text-left"
                      }`}
                  >
                    <button
                      onClick={() => {
                        deleteUserHandler(user.id);
                      }}
                    >
                      <img src={deleteIcon} alt="delete" />
                    </button>
                    <button
                      onClick={() => {
                        navigate(`/teams/${user.id}`);
                      }}
                    >
                      <img src={pencilIcon} alt="edit" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}
