import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dots from "../../assets/icons/dots-vertical.svg";
import { Paginator } from "primereact/paginator";
import ListBox from "./ListBox";
import { API } from "../../api";
import Cookies from "js-cookie";
import { useLanguage } from "../../translations/LanguageContext";
const TABLE_HEADS = [
  "id",
  "product name",
  "brand",
  "batt-compaar",
  "car style",
  "status",
  "price",
  " ",
];

const Products = () => {
  const { language } = useLanguage();
  const [products, setProducts] = useState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let page = searchParams.get("page") || 1;
  const [totalRecords, setTotalRecords] = useState();
  const [first, setFirst] = useState(page);

  const [brands, setBrands] = useState();
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [search, setSearch] = useState();
  let timeout;
  useEffect(() => {
    const getProducts = async () => {
      const { data } = await API.get("products/fetch/dashboard", {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });
      console.log(data);
      setProducts(data.data);
      setTotalRecords(data.meta.total);
    };
    const getBrands = async () => {
      const { data } = await API.get("products/fetch/brands");
      console.log(data);
      setBrands(data.data);
    };
    const getFilterdProducts = async () => {
      const { data } = await API.get(`products/${selectedBrand.id}/filter`, {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      });
      console.log(data);
      setProducts(data.data);
      setTotalRecords(data.meta.total);
    };
    getBrands();
    if (selectedBrand) {
      getFilterdProducts();
    } else {
      getProducts();
    }
  }, [selectedBrand, page]);

  const translatedTableHeads = TABLE_HEADS.map((head) => {
    switch (head.toLowerCase()) {
      case "id":
        return language === "ar" ? "رقم" : "ID";
      case "product name":
        return language === "ar" ? "اسم المنتج" : "Product Name";
      case "brand":
        return language === "ar" ? "العلامة التجارية" : "Brand";
      case "batt-compaar":
        return language === "ar" ? "شركة البطارية" : "Battery Compaar";
      case "car style":
        return language === "ar" ? "شكل السيارة" : "Car Style";
      case "status":
        return language === "ar" ? "الحالة" : "Status";
      case "price":
        return language === "ar" ? "السعر" : "Price";
      case " ":
        return " ";
      default:
        return head;
    }
  });

  return (
    <section className="">
      <div className="flex items-start justify-between max-lg:flex-col max-lg:items-center mb-10 px-4">
        <div className="flex items-center justify-center max-sm:w-full mb-8">
          <Link
            to={"add-product"}
            className="flex items-center gap-2 text-[#B70B28] bg-[#FFE0D0] rounded-lg px-[20px] py-[10px] "
          >
            <FontAwesomeIcon icon={faPlus} className="text-[11px]" />
            <p>{language === "ar" ? "اضافة منتج" : "Add Product"}</p>
          </Link>
        </div>
        <div className="flex items-center max-lg:flex-col max-md:items-center gap-8">
          <div className="flex items-center max-md:flex-col gap-4">
            <p className="text-[15px] text-[#8B6464]">
              {language === "ar" ? "بحث:" : "Search:"}
            </p>
            <input
              type="text"
              placeholder={language === "ar" ? "بحث بالاسم" : "search by title"}
              onChange={(e) => {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                  setSearch(e.target.value);
                }, 1000);
              }}
              className="border border-[#DBDADE] rounded-md  py-[7px] px-[14px]"
            />
          </div>
          <div className="flex gap-4">
            <div className="flex items-center max-md:flex-col gap-4">
              <p className="text-[15px] text-[#8B6464]">
                {language === "ar" ? "الماركة:" : "Brand:"}
              </p>
              {brands && (
                <ListBox
                  selected={selectedBrand}
                  setSelected={setSelectedBrand}
                  options={brands}
                  type={"brands"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-center">
        <div className="w-full max-sm:max-w-[250px] max-w-[1200px] overflow-x-auto px-4">
          <table className="w-full border-collapse border-spacing-5 border border-[#DBDADE]">
            <thead>
              <tr className="border-bottom border-[#DBDADE]">
                {translatedTableHeads.map((head) => (
                  <td
                    key={head}
                    className={`text-[#4B465C] uppercase p-4 border-0 font-semibold ${language === 'ar' ? 'text-right' : 'text-left'}`}
                  >
                    {head}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody className="bg-[#F9F1F1]">
              {products?.map((product) => (
                <tr key={product.id} className="border">
                  <td className={`text-[#4B465C] p-4 border-0 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                    {product.id}
                  </td>
                  <td className={`text-[#4B465C] p-4 border-0 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                    <div className="flex items-center gap-2">
                      <div className="max-sm:hidden">
                        <img
                          className="w-12 h-12 rounded-[50%]"
                          src={
                            product.attributes.image
                              ? product.attributes.image
                              : require("../../assets/images/image-placeholder.png")
                          }
                          alt="avatar"
                        />
                      </div>
                      <p>{product.attributes.name}</p>
                    </div>
                  </td>
                  <td className={`text-[#4B465C] p-4 border-0 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                    {product.attributes["brand_name"]}
                  </td>
                  <td className={`text-[#4B465C] p-4 border-0 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                    {product.attributes.compaar}
                  </td>
                  <td className={`text-[#4B465C] p-4 border-0 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                    <img
                      src={product.attributes["car_type_image"]}
                      alt="style"
                    />
                  </td>
                  {/* <td className='text-[#4B465C] p-4 text-left border-0'>
                                        {product.attributes.status == 1 ? (
                                            <div className='text-[#28C76F] bg-[#28C76F29] text-center rounded py-[5px] px-[10px]'>
                                                Active
                                            </div>
                                        ) : (
                                            <div className='text-[#EA5455] bg-[#EA545529] text-center rounded py-[5px] px-[10px]'>
                                                Disactive
                                            </div>
                                        )}
                                    </td> */}
                  <td className={`text-[#4B465C] p-4  border-0 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                    <div className="text-[#28C76F] bg-[#28C76F29] text-center rounded py-[5px] px-[10px]">
                      Active
                    </div>
                  </td>
                  <td className={`text-[#4B465C] p-4  border-0 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                    {product.attributes.price}
                  </td>
                  <td className={`text-[#4B465C] p-4  border-0 ${language === 'ar' ? 'text-right' : 'text-left'}`}>
                    <button onClick={() => navigate(`${product.id}`)}>
                      <img src={dots} alt="actions" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Paginator
          rows={12}
          totalRecords={totalRecords}
          first={first + 1}
          onPageChange={(e) => {
            navigate(`?page=${e.page + 1}`);
            setFirst(e.first);
          }}
        />
      </div>
    </section>
  );
};

export default Products;
