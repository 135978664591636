import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { API } from '../../api';
import Cookies from 'js-cookie';

export default function ServicesView({ isDisabled, data }) {
    const [currnetService, setCurrentService] = useState();
    const params = useParams();

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState();

    useEffect(() => {
        const getService = async () => {
            if (data.type === 'view' || data.type === 'edit') {
                const { data } = await API.get(
                    `services/${params.id}`
                );
                setCurrentService(data.data);
                setTitle(data.data.attributes.title);
                setDescription(data.data.attributes.description);
                // setImage(data.data.attributes.image)
            }
        };
        getService();
        console.log(title, description, image);
    }, []);

    const editService = async () => {
        //     const formData = new FormData()
        //     formData.append("title", title);
        //     formData.append("description", description);
        //     formData.append("image", image);
        //     const { data } = await axios.put(`https://api-auto.codaa.agency/api/services/${params.id}`, formData, { headers: { Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwaS1hdXRvLmNvZGFhLmFnZW5jeS9hcGkvYXV0aC9sb2dpbiIsImlhdCI6MTcwMTkzNjg4NSwiZXhwIjoxNzAxOTQwNDg1LCJuYmYiOjE3MDE5MzY4ODUsImp0aSI6InBDbVRuYUxrZTVHZlpoUVAiLCJzdWIiOiI2IiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmarJ9.1z61sni7k_8YoKtbrPCUHECZyhZUrOUy68NJzCDpC3g' } })
        //     console.log(data);
        //
        const { data } = await API.put(
            `services/${params.id}`,
            { title: title, description: description },
            {
                headers: {
                    Authorization:
                        `Bearer ${Cookies.get('token')}`,
                },
            }
        );
        console.log(data);
    };
    const addService = async () => {
        console.log(title, description, image);
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('image', image);
        const { data } = await API.post(
            'services',
            formData,
            {
                headers: {
                    Authorization:
                        `Bearer ${Cookies.get('token')}`,
                },
            }
        );
        console.log(data);
    };
    // const description = 'Our Electricity Leakage Measurement service is designed to help battery website customers identify and prevent potential safety hazards caused by electricity leaks in their batteries. Our team of experienced technicians uses specialized equipment to measure the amount of electricity that may be leaking from a battery, and provides recommendations on how to address aar issues that could be found';

    const toast = useRef(null);

    const [toggleShowBtn, setToggleShowBtn] = useState(false);

    const accept = () => {
        toast.current.show({
            severity: 'success',
            summary: 'Confirmed',
            detail: 'You have accepted',
            life: 3000,
        });
        setToggleShowBtn(false);
    };

    const reject = () => {
        toast.current.show({
            severity: 'warn',
            summary: 'Rejected',
            detail: 'You have rejected',
            life: 3000,
        });
        setToggleShowBtn(false);
    };

    const confirm = () => {
        confirmDialog({
            message: 'Are you sure you want to delete this item',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept,
            reject,
        });
    };

    return (
        <div className='flex flex-col items-center'>
            <section className='flex gap-4 max-sm:w-[260px] max-sm:flex-col w-full'>
                <div className='col-span-4 flex items-center justify-center flex-col rounded-[6px] bg-white'>
                    {data.type === 'view' && (
                        <img
                            src={currnetService?.attributes.image}
                            alt={currnetService?.attributes.title}
                            className='object-fill max-w-[326px] max-md:w-full max-md:max-w-full'
                        />
                    )}
                    {data.type === 'edit' && (
                        <Fragment>
                            <img
                                src={currnetService?.attributes.image}
                                alt={currnetService?.attributes.title}
                                className='object-fill max-w-[326px] max-md:w-full max-md:max-w-full'
                            />

                            <div className='mt-10 flex items-center justify-center'>
                                <div className='flex items-center justify-center me-5'>
                                    <label
                                        htmlFor='uploade-product-image'
                                        className='w-[50px] h-[50px] rounded-full bg-[#93072A] cursor-pointer  flex items-center justify-center text-white'
                                    >
                                        <FontAwesomeIcon icon={faUpload}></FontAwesomeIcon>
                                    </label>

                                    <input
                                        type='file'
                                        id='uploade-product-image'
                                        className='w-0 opacity-0 invisible'
                                    />
                                </div>

                                <div
                                    htmlFor='uploade-product-image'
                                    className='w-[50px] h-[50px] rounded-full bg-[#93072A] cursor-pointer  flex items-center justify-center text-white'
                                >
                                    <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                                </div>
                            </div>
                        </Fragment>
                    )}
                    {data.type === 'add' && (
                        <Fragment>
                            <img
                                src={require('./default-add-img.png')}
                                className='object-fill'
                            />

                            <h1 className='font-medium text-center text-[25px] my-5'>
                                upload images here{' '}
                            </h1>

                            <div>
                                <div className='flex items-center justify-center'>
                                    <label
                                        htmlFor='uploade-product-image'
                                        className='w-[50px] h-[50px] rounded-full bg-[#93072A] cursor-pointer  flex items-center justify-center text-white'
                                    >
                                        <FontAwesomeIcon icon={faUpload}></FontAwesomeIcon>
                                    </label>

                                    <input
                                        type='file'
                                        onChange={(e) => setImage(e.target.files[0])}
                                        id='uploade-product-image'
                                        className='w-0 opacity-0 invisible'
                                    />
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>

                <div className='flex-1 relative bg-white rounded-[10px] shadow p-5'>
                    <h2 className='text-[20px] text-[#8B6464] font-medium mb-5'>
                        view service information{' '}
                    </h2>

                    <div className='mb-8'>
                        <label className='text-[#8B6464] block'>Name</label>

                        <input
                            type='text'
                            value={data.type === 'add' ? '' : title}
                            onChange={(e) => setTitle(e.target.value)}
                            disabled={isDisabled}
                            className='p-2 border border-[#8B6464] rounded-[5px] w-full'
                        />
                    </div>

                    <div className='mb-8'>
                        <label className='text-[#8B6464] block'>description</label>

                        <textarea
                            rows={7}
                            value={data.type === 'add' ? '' : description}
                            onChange={(e) => setDescription(e.target.value)}
                            disabled={isDisabled}
                            className='p-2 border border-[#8B6464] rounded-[5px] w-full'
                        />
                    </div>

                    <div className='flex gap-4 max-sm:flex-col'>
                        <button
                            className='bg-[#FFE0D0] text-[#FF0303] rounded-lg py-4 px-8 flex-1' onClick={() => {
                                if (data?.type === 'view') {
                                    confirm();
                                }
                            }}
                        >
                            {data.btns.btn1}
                        </button>

                        <Link
                            className='text-white bg-[#FF0303] rounded-lg py-4 px-8 flex-[3] text-center'
                            to={
                                data?.type === 'view'
                                    ? `/services/services-edit/${params.id}`
                                    : ''
                            }
                        >
                            <button
                                onClick={() => {
                                    if (data.type === 'edit') {
                                        editService();
                                    }
                                }}
                            >
                                {data?.btns?.btn2}
                            </button>
                        </Link>
                    </div>
                </div>

                <Toast ref={toast} />

                <ConfirmDialog />
            </section>
        </div>

    );
}
