import { Popover } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { Dropdown } from "primereact/dropdown";
import React, { useState } from "react";
import { RelatedLinks } from "../../../components";
import HeaderLogo from "../HeaderLogo/HeaderLogo";
import { useLanguage } from "../../../translations/LanguageContext";
import { Link, useLocation } from "react-router-dom";
import ar from "../../../assets/icons/ar-icon.svg";
import en from "../../../assets/images/en-logo.png";
import Modal from "./Modal";

export default function DesktopHeader({ setMobileMenuOpen, desktopLinks }) {
  const { language, changeLanguage } = useLanguage();
  const location = useLocation();
  const [isProductsModalOpen, setProductsModalOpen] = useState(false);

  const [selectedCity, setSelectedCity] = useState({
    name: "English",
    code: "en",
  });
  const cities = [
    { name: "English", code: "en" },
    { name: "Arabic", code: "ar" },
  ];

  const handleLanguageChange = (event) => {
    setSelectedCity(event.value);
    changeLanguage(event.value.code);
    localStorage.setItem("lang", event.value.code);
    // window.location.reload();
    console.log(event.value);
  };

  const languageItemTemplate = (option) => {
    return (
      <div className="flex items-center">
        <span className="mr-2 w-[20px] h-[20px]">
          {option.name === "Arabic" ? (
            <img src={ar} alt="arabic" />
          ) : (
            <img className="w-full h-full" src={en} alt="english" />
          )}
        </span>
        {option.name}
      </div>
    );
  };

  const handleCloseModal = () => {
    setProductsModalOpen(false);
  };

  return (
    <>
      <nav
        className="flex items-center justify-between h-full"
        aria-label="Global"
      >
        <div className="hidden lg:flex">
          <HeaderLogo />
        </div>

        <div className="flex justify-between items-center w-full lg:hidden">
          <HeaderLogo />

          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>

            <Bars3Icon className="h-8 w-8" aria-hidden="true" />
          </button>
        </div>

        <Popover.Group className="hidden lg:flex lg:gap-x-6 xl:gap-x-12 lg:justify-center lg:items-center col-span-6 ">
          {desktopLinks.map((link, index) => (
            <Popover
              key={index}
              className="relative flex items-center justify-center"
            >
              {({ open }) => (
                <>
                  <Popover.Button className="group ">
                    {link.submenu ? (
                      <span
                        className={`text-sm font-semibold xl:leading-6 text-[#8B6464] hover:text-[#DB1020]" flex items-center justify-center gap-2 ${
                          location.pathname === link.href
                            ? "text-[#DB1020]"
                            : ""
                        }`}
                      >
                        {language === "ar" ? link.text.ar : link.text.en}
                        {location.pathname === link.href && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="20"
                            viewBox="0 0 21 20"
                            fill="none"
                            className={`text-[#DB1020]`}
                          >
                            <circle cx="10.5" cy="10" r="4.5" fill="#DB1020" />
                          </svg>
                        )}
                      </span>
                    ) : (
                      <Link
                        to={link.href}
                        className={`flex items-center justify-center ${
                          link.classes
                        } ${
                          location.pathname === link.href
                            ? "text-[#DB1020]"
                            : ""
                        }
                        ${
                          link.href === "#" &&
                          location.pathname.includes("products")
                            ? "text-[#DB1020]"
                            : ""
                        }`}
                        onClick={() => {
                          console.log(link.text.en);
                          if (link.text.en === "Products") {
                            setProductsModalOpen(true);
                          } else {
                            setProductsModalOpen(false);
                          }
                        }}
                      >
                        {location.pathname === link.href && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="20"
                            viewBox="0 0 21 20"
                            fill="none"
                          >
                            <circle cx="10.5" cy="10" r="4.5" fill="#DB1020" />
                          </svg>
                        )}

                        {link.href === "#" &&
                          location.pathname.includes("products") && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                            >
                              <circle
                                cx="10.5"
                                cy="10"
                                r="4.5"
                                fill="#DB1020"
                              />
                            </svg>
                          )}
                        {language === "ar" ? link.text.ar : link.text.en}
                      </Link>
                    )}
                  </Popover.Button>

                  {link.submenu && (
                    <Popover.Panel className="w-[120px] absolute z-[500] right-[-28px] top-5 mt-2 space-y-2 bg-[#fff] flex flex-col items-start justify-center py-5 px-3 border rounded-md">
                      {link.submenu.map((sublink, subIndex) => (
                        <Link
                          key={subIndex}
                          to={sublink.href}
                          className={`flex items-center justify-center ${
                            sublink.classes
                          } ${
                            location.pathname === sublink.href
                              ? "text-[#DB1020]"
                              : ""
                          }`}
                        >
                          {location.pathname === sublink.href && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                            >
                              <circle
                                cx="10.5"
                                cy="10"
                                r="4.5"
                                fill="#DB1020"
                              />
                            </svg>
                          )}
                          {language === "ar"
                            ? sublink.text.ar
                            : sublink.text.en}
                        </Link>
                      ))}
                    </Popover.Panel>
                  )}
                </>
              )}
            </Popover>
          ))}
        </Popover.Group>

        <div className="relative hidden lg:flex lg:gap-x-6 xl:gap-x-12 lg:justify-end lg:items-center">
          <div className="relative">
            <Dropdown
              value={language}
              onChange={handleLanguageChange}
              options={cities}
              optionLabel="name"
              placeholder={language === "ar" ? "العربية" : "English"}
              className="!bg-[#FFE0D0] !text-black !p-0"
              itemTemplate={languageItemTemplate}
            />
          </div>
        </div>
      </nav>
      {isProductsModalOpen && (
        <Modal isOpen={isProductsModalOpen} onClose={handleCloseModal} />
      )}
    </>
  );
}
