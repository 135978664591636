import React from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../translations/LanguageContext';
import Cookies from 'js-cookie';

const handelMnueScroll = (e) => {
  const mnue = document.querySelector('.main-menu-content');
  console.log(localStorage.getItem('image'));
  if (mnue.scrollTop >= mnue.scrollHeight - mnue.clientHeight) {
    mnue.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
  } else {
    mnue.scrollBy({
      left: 0,

      top: 100,

      behavior: 'smooth',
    });
  }
};
export default function MnueMainContent({ isExpanded }) {
  const navigate = useNavigate();

  const logoutHandler = () => {
    window.location.reload();
    Cookies.remove('token');
    window.localStorage.removeItem('id');
    window.localStorage.removeItem('name');
    window.localStorage.removeItem('email');
    window.localStorage.removeItem('image');
  };
  const { language } = useLanguage();
  const linkStyle = `w-full rounded-[6px] flex items-center ${isExpanded ? 'justify-between' : 'justify-center'
    } p-3`;
  const liStyle = 'px-2';

  return (
    <div className='main-menu-content relative overflow-y-auto'>
      <ul>
        {isExpanded && (
          <li className='px-3 mb-6 cursor-pointer text-[#a2a0a9]'>
            <div className='bg-[#FF9571] px-3 py-2 border-4 border-[#FCF8F8] text-center !rounded-[100px] text-white w-full'>
              {language === 'ar' ? 'مشرف' : 'Admin'}
            </div>
          </li>
        )}
        <div className='flex flex-col'>
          <div>
            <li className={liStyle}>
              <NavLink to='/admin/dashboard' className={linkStyle}>
                <div className={`rounded-[6px] text-black flex items-center`}>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6.50059 14.0002L10.7006 9.8002L13.7006 12.8002L17.3006 9.2002M4.4 21.2C3.07451 21.2 2 20.1255 2 18.8V4.4C2 3.07452 3.07452 2 4.4 2H18.8C20.1255 2 21.2 3.07452 21.2 4.4V18.8C21.2 20.1255 20.1255 21.2 18.8 21.2H4.4Z'
                      stroke='black'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>

                  {isExpanded && (
                    <span className='ms-3'>
                      {language === 'ar' ? 'لوحة التحكم' : 'Dashboard'}
                    </span>
                  )}
                </div>
              </NavLink>
            </li>

            <li className={liStyle}>
              <NavLink to='/admin/products' className={linkStyle}>
                <div className={`rounded-[6px] text-black flex items-center`}>
                  <svg
                    width='22'
                    height='22'
                    viewBox='0 0 22 22'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4.39986 4.32947V2.52947C4.39986 1.86673 4.93712 1.32947 5.59986 1.32947H16.9999C17.6626 1.32947 18.1999 1.86673 18.1999 2.52947V7.32947M1.4008 18.2705L1.40089 9.14615C1.4009 8.23223 1.40056 6.9305 1.40027 5.98795C1.40006 5.32503 1.9374 4.78826 2.60032 4.78826H8.31853L11.0836 7.74189H19.3998C20.0626 7.74189 20.5999 8.27917 20.5998 8.94193L20.5995 18.2706C20.5995 19.5961 19.525 20.6705 18.1995 20.6705L3.8008 20.6705C2.47531 20.6705 1.40079 19.596 1.4008 18.2705Z'
                      stroke='black'
                      stroke-width='1.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>

                  {isExpanded && (
                    <span className='ms-3'>
                      {language === 'ar' ? 'المنتجات' : 'Products'}
                    </span>
                  )}
                </div>
              </NavLink>
            </li>

            <li className={liStyle}>
              <NavLink to={'/admin/products-category'} className={linkStyle}>
                <div
                  className={`rounded-[6px] text-black flex items-center ${isExpanded ? '' : 'justify-center'
                    }`}
                >
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g id='Left Icon'>
                      <path
                        id='Vector'
                        d='M6.00029 9.87443C3.56603 10.639 1.80029 12.9132 1.80029 15.5997C1.80029 18.9135 4.48658 21.5997 7.80029 21.5997C9.47873 21.5997 10.9962 20.9106 12.0852 19.7997C13.1461 18.7175 13.8003 17.235 13.8003 15.5997M18.0003 9.87443C20.4348 10.6388 22.2008 12.9131 22.2008 15.5999C22.2008 18.9136 19.5145 21.5999 16.2008 21.5999C14.8498 21.5999 13.6032 21.1534 12.6003 20.4M18.0003 8.39963C18.0003 11.7133 15.314 14.3996 12.0003 14.3996C8.68658 14.3996 6.00029 11.7133 6.00029 8.39963C6.00029 5.08592 8.68658 2.39963 12.0003 2.39963C15.314 2.39963 18.0003 5.08592 18.0003 8.39963Z'
                        stroke='black'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </g>
                  </svg>

                  {isExpanded && (
                    <span className='ms-3'>
                      {language === 'ar'
                        ? 'تصنيفات المنتجات'
                        : 'Products Categories'}
                    </span>
                  )}
                </div>
              </NavLink>
            </li>

            <li className={liStyle}>
              <NavLink to={'/admin/services'} className={linkStyle}>
                <div
                  className={`rounded-[6px] text-black flex items-center ${isExpanded ? '' : 'justify-center'
                    }`}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      d='M21.6004 11.975L12.0004 16.8958L2.40039 11.975M21.6004 16.6792L12.0004 21.5999L2.40039 16.6792M12.0004 2.3999L21.6004 7.32065L12.0004 12.2414L2.40039 7.32065L12.0004 2.3999Z'
                      stroke='black'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>

                  {isExpanded && (
                    <span className='ms-3'>
                      {language === 'ar' ? 'الخدمات' : 'Services'}
                    </span>
                  )}
                </div>
              </NavLink>
            </li>
            <li className={liStyle}>
              <NavLink to={'/admin/banners'} className={linkStyle}>
                <div
                  className={`rounded-[6px] text-black flex items-center ${isExpanded ? '' : 'justify-center'
                    }`}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='22'
                    height='18'
                    viewBox='0 0 22 18'
                    fill='none'
                  >
                    <path
                      d='M7.16039 12.6L11.0004 8.99995L13.5604 10.8L17.1871 7.19995L20.3871 10.2M1.40039 5.99995V16.8H14.2004M9.08039 4.79995V4.70972M20.6004 12.2V2.19995C20.6004 1.64767 20.1527 1.19995 19.6004 1.19995H6.24039C5.68811 1.19995 5.24039 1.64767 5.24039 2.19995V12.2C5.24039 12.7522 5.68811 13.2 6.24039 13.2H19.6004C20.1527 13.2 20.6004 12.7522 20.6004 12.2Z'
                      stroke='black'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>

                  {isExpanded && (
                    <span className='ms-3'>
                      {language === 'ar' ? 'اللافتات' : 'Banners'}
                    </span>
                  )}
                </div>
              </NavLink>
            </li>
            <li className={liStyle}>
              <NavLink to={'/admin/blogs'} className={linkStyle}>
                <div
                  className={`rounded-[6px] text-black flex items-center ${isExpanded ? '' : 'justify-center'
                    }`}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='22'
                    height='22'
                    viewBox='0 0 22 22'
                    fill='none'
                  >
                    <path
                      d='M5.00039 20.5999L15.2004 10.9999L20.0004 15.7999M5.00039 20.5999H17.0004C18.9886 20.5999 20.6004 18.9881 20.6004 16.9999V10.9999M5.00039 20.5999C3.01217 20.5999 1.40039 18.9881 1.40039 16.9999V4.9999C1.40039 3.01168 3.01217 1.3999 5.00039 1.3999H12.8004M15.8004 3.84248L18.2547 1.3999M18.2547 1.3999L20.6004 3.73207M18.2547 1.3999V7.3999M8.60039 6.7999C8.60039 7.79401 7.7945 8.5999 6.80039 8.5999C5.80628 8.5999 5.00039 7.79401 5.00039 6.7999C5.00039 5.80579 5.80628 4.9999 6.80039 4.9999C7.7945 4.9999 8.60039 5.80579 8.60039 6.7999Z'
                      stroke='black'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>

                  {isExpanded && (
                    <span className='ms-3'>
                      {language === 'ar' ? 'المدونات' : 'Blogs'}
                    </span>
                  )}
                </div>
              </NavLink>
            </li>
            <li className={liStyle}>
              <NavLink to={'/admin/blogs-categories'} className={linkStyle}>
                <div
                  className={`rounded-[6px] text-black flex items-center ${isExpanded ? '' : 'justify-center'
                    }`}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='22'
                    height='22'
                    viewBox='0 0 22 22'
                    fill='none'
                  >
                    <path
                      d='M16.3337 20.6C15.2021 20.6 14.1169 20.1505 13.3167 19.3503C12.5166 18.5502 12.0671 17.4649 12.0671 16.3333V3.53333C12.0671 2.96754 12.2918 2.42492 12.6919 2.02484C13.092 1.62476 13.6346 1.4 14.2004 1.4H18.4671C19.0328 1.4 19.5755 1.62476 19.9755 2.02484C20.3756 2.42492 20.6004 2.96754 20.6004 3.53333V16.3333C20.6004 17.4649 20.1509 18.5502 19.3507 19.3503C18.5506 20.1505 17.4653 20.6 16.3337 20.6ZM16.3337 20.6H3.53372C2.96793 20.6 2.42531 20.3752 2.02523 19.9752C1.62515 19.5751 1.40039 19.0325 1.40039 18.4667V14.2C1.40039 13.6342 1.62515 13.0916 2.02523 12.6915C2.42531 12.2914 2.96793 12.0667 3.53372 12.0667H5.98706M12.0671 6.04L9.93372 3.90667C9.53366 3.50673 8.99114 3.28205 8.42546 3.28205C7.85977 3.28205 7.31725 3.50673 6.91719 3.90667L3.90066 6.9232C3.50072 7.32326 3.27605 7.86578 3.27605 8.43147C3.27605 8.99715 3.50072 9.53967 3.90066 9.93973L13.5007 19.5397M16.3337 16.3333V16.344'
                      stroke='black'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>

                  {isExpanded && (
                    <span className='ms-3'>
                      {language === 'ar'
                        ? 'تصنيفات المدونات'
                        : 'Blogs Categories'}
                    </span>
                  )}
                </div>
              </NavLink>
            </li>

            <li className={liStyle}>
              <NavLink to={'/admin/teams'} className={linkStyle}>
                <div
                  className={`rounded-[6px] text-black flex items-center ${isExpanded ? '' : 'justify-center'
                    }`}
                >
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M19.1999 13.6855C20.4672 14.6324 21.5999 17.0165 21.5999 18.4855C21.5999 18.9431 21.266 19.3141 20.8541 19.3141H20.3999M15.5999 9.80432C16.4197 9.33006 16.9713 8.44365 16.9713 7.4284C16.9713 6.41316 16.4197 5.52675 15.5999 5.05249M3.14567 19.3141H16.2827C16.6946 19.3141 17.0285 18.9431 17.0285 18.4855C17.0285 15.6089 14.6252 13.2769 9.71419 13.2769C4.80317 13.2769 2.3999 15.6089 2.3999 18.4855C2.3999 18.9431 2.7338 19.3141 3.14567 19.3141ZM12.457 7.4284C12.457 8.94324 11.229 10.1713 9.71419 10.1713C8.19935 10.1713 6.97133 8.94324 6.97133 7.4284C6.97133 5.91357 8.19935 4.68555 9.71419 4.68555C11.229 4.68555 12.457 5.91357 12.457 7.4284Z'
                      stroke='black'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                    />
                  </svg>

                  {isExpanded && (
                    <span className='ms-3'>
                      {language === 'ar' ? 'الفرق' : 'Teams'}
                    </span>
                  )}
                </div>
              </NavLink>
            </li>
          </div>

          <div>
            <li
              onClick={() => {
                navigate('/');
                logoutHandler();
              }}
              className={`cursor-pointer w-full rounded-[6px] flex items-center ${isExpanded ? 'justify-between' : 'justify-center'
                } py-3 px-[20px]`}
            >
              <div
                className={`rounded-[6px] text-black flex items-center ${!isExpanded && 'justify-center'
                  } `}
              >
                {language === 'ar' ? <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <path
                    d='M8.78019 3.59998H5.07431C4.51268 3.59998 3.97405 3.82122 3.57691 4.21505C3.17977 4.60888 2.95667 5.14302 2.95667 5.69998V18.3C2.95667 18.8569 3.17977 19.3911 3.57691 19.7849C3.97405 20.1787 4.51268 20.4 5.07431 20.4H8.78019M9.04338 12H21.0434M21.0434 12L16.4582 7.19998M21.0434 12L16.4582 16.8'
                    stroke='black'
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M15.2198 20.4L18.9257 20.4C19.4873 20.4 20.026 20.1788 20.4231 19.7849C20.8202 19.3911 21.0433 18.857 21.0433 18.3L21.0433 5.70003C21.0433 5.14307 20.8202 4.60893 20.4231 4.2151C20.026 3.82128 19.4873 3.60003 18.9257 3.60003L15.2198 3.60002M14.9566 12L2.95662 12M2.95662 12L7.54177 16.8M2.95662 12L7.54177 7.20002" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>}

                {isExpanded && (
                  <span className='ms-3'>
                    {language === 'ar' ? 'تسجيل خروج' : 'Logout'}
                  </span>
                )}
              </div>
            </li>
            <li
              className={`flex items-center ${isExpanded ? 'my-5' : ''
                } px-3 cursor-pointer text-[#a2a0a9] w-full`}
            >
              <div className='flex items-center w-full'>
                <div
                  className={`rounded-[6px] text-black gap-4 w-full flex p-3 items-center ${isExpanded ? 'bg-[#FCF8F8]' : 'justify-center'
                    }`}
                >
                  <Link to='/admin/profile' className='relative'>
                    <img
                      src={
                        localStorage.getItem('image') != 'null'
                          ? localStorage.getItem('image')
                          : require('../../../assets/images/image-placeholder.png')
                      }
                      className={`${isExpanded
                        ? 'w-[40px] h-[40px] rounded-full'
                        : 'w-[30px] h-[30px] rounded-full'
                        }`}
                      alt=''
                    />

                    <div className='absolute right-0 bottom-0 w-[10px] h-[10px] bg-[#14A34D] rounded-full'></div>
                  </Link>

                  {isExpanded && (
                    <Link to='/admin/profile' className='text-[12px] flex-1'>
                      <h2 className='text-black font-medium mb-1'>
                        {localStorage.getItem('name')}
                      </h2>

                      <p className='text-[#CFCFCF] max-w-[150px] truncate'>
                        {localStorage.getItem('email')}
                      </p>
                    </Link>
                  )}
                </div>
              </div>
            </li>
          </div>
        </div>
      </ul>
    </div>
  );
}
