import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SwiperSlide } from "swiper/react";
import Card from "./Card";
import { SwiperWrapper } from "../../../../components";
import { API } from "../../../../api";
import { useLanguage } from "../../../../translations/LanguageContext";

const items = (posts) =>
  posts.map((item) => {
    return (
      <SwiperSlide key={item.id}>
        <Card
          content={item.attributes.content}
          categories={item.categories}
          image={item.attributes.image}
          date={item.attributes["created_at"]}
          username={item.user.attributes.name}
          userImage={item.user.attributes.image}
        />
      </SwiperSlide>
    );
  });

export default function TopBlog() {
  const { language } = useLanguage();
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    const getPosts = async () => {
      const { data } = await API.get(`posts`);
      setPosts(data.data);
      console.log(data.data);
    };
    getPosts();
  }, []);
  return (
    <section className="my-10 relative">
      <div className="px-3">
        <div className="flex items-center justify-between mb-5">
          <h1 className="text-[20px] font-medium">
            <span className="p-1 text-[18px] rounded-[5px] bg-[#ff6050] text-white me-1">
              {language === "ar" ? "أفضل" : "Top"}
            </span>{" "}
            {language === "ar" ? "مدونات" : "Blog"}
          </h1>

          <Link
            to={""}
            className="text-[#8B6464] font-medium flex items-center"
          >
            <p>{language === "ar" ? "المزيد" : "See All Article"} </p>

            <FontAwesomeIcon
              icon={faAngleRight}
              className={`ms-2 ${language === "ar" ? "rotate-180" : ""}`}
            />
          </Link>
        </div>

        <SwiperWrapper
          items={items(posts)}
          slidesPerViewCount={[2, 3, 3, 4]}
          autoplayDelay={50000}
          isLooped={true}
          includeNavigation={true}
        />
      </div>
    </section>
  );
}
