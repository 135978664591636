import React from 'react'
import { Hero, Categories, ProductDetails, Help, Videos, TopBlog } from './components'
import { Newsletter } from '../../components'

export default function Home() {
    return (
        <div>

            <Hero />

            <Categories />

            <ProductDetails />

            <Help />

            <Videos />

            <TopBlog />

            <Newsletter />

        </div>
    )
}
