import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import loginImage from '../../assets/images/login.svg';
import logo from '../../assets/images/logo.png';
import './login.css';
import { API } from '../../api';

const ResetPassword = () => {
    const [email, setEmail] = useState(localStorage.getItem('email'));
    const [verficationCode, setVerficationCode] = useState();
    const [newPassword, setNewPassword] = useState();
    const [message, setMessage] = useState();
    const navigate = useNavigate();
    const submit = async (e) => {
        e.preventDefault();
        const { data } = await API.post(
            'reset-password',
            { email: email, verficationCode: verficationCode, password: newPassword }
        );
        setMessage(data.message);
        navigate('/admin/login');
    };
    return (
        <div className='login-page'>
            <div className='box'>
                <div className='login-form'>
                    <div className='container box-from'>
                        <div className='logo'>
                            <img src={logo} alt='Logo Image' />
                        </div>
                        <div className='form-head'>
                            <h1>Reset Password</h1>
                            <p>Please enter verfication code you received</p>
                        </div>
                        <div className='form-fileds'>
                            <form onSubmit={submit}>
                                <input
                                    type='email'
                                    name='email'
                                    id='email'
                                    placeholder='example@mail.com'
                                    disabled
                                    readOnly
                                    value={email}
                                />
                                <input
                                    type='password'
                                    name='password'
                                    id='password'
                                    placeholder='enter you new password'
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                                <input
                                    type='text'
                                    name='code'
                                    id='code'
                                    placeholder='000000'
                                    value={verficationCode}
                                    onChange={(e) => setVerficationCode(e.target.value)}
                                />
                                <input type='submit' value='submit' />
                            </form>
                        </div>
                    </div>
                </div>
                <div className='logo-image'>
                    <img src={loginImage} alt='Login' />
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
